import { setOrganization } from "../../redux/slice"
import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import { Invite } from "../Question"
import { User } from "../User"
import {
  createOrganization,
  InvitesByOrganization,
  OrganizationByUserid,
  OrgByName,
  UserOrganizations,
  ValidateInvitation,
} from "./Organization.documents"
import { Organization } from "./Organization.interfaces"

export const organizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    organization: builder.query<Organization, void>({
      query: () => ({ document: OrgByName, variables: { name: getOrganizationNameFromRoute()! } }),
      extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Read } },
      providesTags: ["Organization"],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(
          api.util.invalidateTags([
            "Subscription",
            "ActionPlan",
            "Audit",
            "OrganizationUsers",
            "Answer",
            "Permission",
            "Products",
            "Template",
          ])
        )
        queryFulfilled.then((response) => {
          dispatch(setOrganization(response.data.name))
        })
      },
    }),

    listOrganization: builder.query<Organization[], { id: User["id"] }>({
      query: (variables) => ({
        document: OrganizationByUserid,
        variables,
      }),
      extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Read } },
      providesTags: ["User", "Organization"],
    }),

    createOrganization: builder.mutation<
      Organization,
      { name: string; businessName: string; industry: string; domains: string[]; uid: any }
    >({
      query: (variables) => ({ document: createOrganization, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Create } },
      invalidatesTags: ["User", "Organization"],
    }),

    validateInvitation: builder.mutation<{ validateInvitation: { adId?: string; invite?: Invite } }, { token: string }>(
      {
        query: (variables) => ({ document: ValidateInvitation, variables }),
        extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Create } },
        invalidatesTags: ["User", "Organization"],
      }
    ),

    invitesByOrganization: builder.query<Invite[], { organization: string }>({
      query: (variables) => ({ document: InvitesByOrganization, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Create } },
      providesTags: ["Organization"],
    }),

    userOrganizations: builder.query<Organization["name"][], { email: string }>({
      query: (variables) => ({ document: UserOrganizations, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Organization, operation: PermissionEnum.Create } },
      providesTags: ["Organization"],
    }),
  }),
})

export const {
  useOrganizationQuery,
  useListOrganizationQuery,
  useCreateOrganizationMutation,
  useValidateInvitationMutation,
  useInvitesByOrganizationQuery,
  useLazyUserOrganizationsQuery,
  useUserOrganizationsQuery,
} = organizationApi
