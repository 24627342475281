import { BaseQueryFn } from "@reduxjs/toolkit/query"
import { DocumentNode } from "graphql"
import { ClientError, GraphQLClient } from "graphql-request"
import { store } from "../redux"
import { reset } from "../redux/slice"
import { GraphResponse } from "../types/types"
import { getOrganizationNameFromRoute } from "../utils/getOrganizationNameFromRoute"

export const graphClient = new GraphQLClient(process.env.REACT_APP_SERVICE_URL ?? "", {
  headers: { "Content-Type": "application/json" },
})

export interface Query {
  document: string | DocumentNode
  variables?: any

  params?: never
  url?: never
  method?: never
  formData?: never
  data?: never
}

type extraoptions = Partial<{ headers: Record<string, string> }>

type GraphBaseQuery = BaseQueryFn<
  Query,
  GraphResponse,
  Pick<ClientError, "name" | "message" | "stack">,
  extraoptions | ((value: Query) => extraoptions)
>

export const graphBaseQuery = (): GraphBaseQuery => async (query, basequery, extraOptions) => {
  let { variables = undefined, document } = query

  const adid = store.getState().auth.user?.adId
  const org = getOrganizationNameFromRoute()

  if (adid) graphClient.setHeader("adid", adid)
  if (org) graphClient.setHeader("organizationname", org)

  const extra = typeof extraOptions === "function" ? extraOptions(query) : extraOptions

  const extraHeaders = Object.entries(extra?.headers ?? {})
  if (!!extraHeaders.length) extraHeaders?.forEach(([name, value]) => graphClient.setHeader(name, value))

  const response = await graphClient.request(document, variables).catch((error) => {
    //
    // handle Authorization error
    //
    if (
      Number(error.status) === 401 ||
      error.response?.errors?.at(0)?.extensions?.code === "AUTHENTICATIONERROR" ||
      error.response?.errors?.at(0)?.extensions?.code === "INVALID_AUTHORIZATION_HEADER"
    ) {
      store.dispatch(reset())
    }

    throw error.response?.errors.at(0).message ?? error?.message ?? "Some Error Occured"
  })

  //
  // if error got into response throw it into error
  //
  if (response.error) throw response.error?.message.split(":")[0]
  if (response.data?.errors?.length) throw response.data.errors[0]

  return { ...response, data: Object.values(response ?? {})[0] }
}
