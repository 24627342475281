import { Button } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import { MessageProps } from "./Message.interface"

export const Message: FC<MessageProps> = (props) => {
  const { title, description, className, actionButton, error } = props

  const code = (error?.networkError as any)?.result?.errors?.[0].extensions.code
  const codemessage =
    code === "FORBIDDEN"
      ? "You dont have permission to perform this action"
      : code === "UNAUTHENTICATED"
      ? "You are not authenticated"
      : undefined

  return (
    <section className={`m-auto flex h-full w-full flex-col items-center justify-center p-4 text-center ${className}`}>
      <div className="mb-2 text-4xl font-light leading-tight">
        {codemessage ?? title ?? error?.message ?? "Something went wrong"}
      </div>
      <div className="col-9 col-md-6 mx-auto mb-4 text-xl font-light">{description}</div>
      {code === "FORBIDDEN" ? (
        <Link to="/">
          <Button variant="contained">Back Home</Button>
        </Link>
      ) : (
        actionButton
      )}
    </section>
  )
}
