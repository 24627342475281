import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import { CreateAssessmentPayment, CreateSubscription, RetrieveSubscription } from "./Subscription.documents"
import { PaymentIntent, Subscription } from "./Subscription.interfaces"

export const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    subscription: builder.query<Subscription, void>({
      query: () => ({
        document: RetrieveSubscription,
        variables: { organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Payment, operation: PermissionEnum.Read } },
      providesTags: ["Subscription"],
    }),

    createSubscription: builder.mutation<PaymentIntent, { product: { id: string; quantity: number }[] }>({
      query: (variables) => ({
        document: CreateSubscription,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Payment, operation: PermissionEnum.Create } },
      invalidatesTags: ["Subscription"],
    }),

    createAssessmentPayment: builder.mutation<PaymentIntent, void>({
      query: () => ({
        document: CreateAssessmentPayment,
        variables: { organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Payment, operation: PermissionEnum.Create } },
      invalidatesTags: ["Subscription"],
    }),
  }),
})

export const { useSubscriptionQuery, useCreateSubscriptionMutation, useCreateAssessmentPaymentMutation } =
  subscriptionApi
