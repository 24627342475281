import { gql } from "graphql-request"

export const UserByAdid = gql`
  query UserByAdid($adid: String!) {
    userByAdid(adid: $adid) {
      id
      name
      email
      adId
      status
      Access {
        id
        Organization {
          id
          name
          industry
          domains
        }
        Role {
          id
          type
          Permissions {
            id
            type
            Resource {
              id
              name
            }
          }
        }
        organizationName
        roleId
      }
      ActionPlans {
        id
      }
    }
  }
`
export const UserByOrganization = gql`
  query UserByOrganization($name: String!) {
    userByOrganization(name: $name) {
      id
      name
      email
      adId
      status
      inviteToken
      newInvitedUser
      Access {
        id
        organizationName
        Role {
          id
          type
          Permissions {
            id
            type
            Resource {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const CreateUser = gql`
  mutation CreateUser(
    $adId: String!
    $name: String!
    $email: String!
    $status: String
    $campaignsId: [Int!]
    $actionPlansId: [Int!]
    $roleId: Int
    $organizationName: String
  ) {
    createUser(
      adId: $adId
      name: $name
      email: $email
      status: $status
      campaignsId: $campaignsId
      actionPlansId: $actionPlansId
      roleId: $roleId
      organizationName: $organizationName
    ) {
      id
    }
  }
`

export const CreateManyUser = gql`
  mutation CreateManyUser($data: [ManyUserCreate!]!) {
    createManyUser(data: $data) {
      id
    }
  }
`

export const InviteUser = gql`
  mutation InviteUser($name: String!, $email: String!, $roleId: Int!, $organizationName: String!, $actionPlanId: Int) {
    inviteUser(
      name: $name
      email: $email
      roleId: $roleId
      organizationName: $organizationName
      actionPlanId: $actionPlanId
    ) {
      id
    }
  }
`

export const InviteManyUser = gql`
  mutation InviteManyUser($data: [ManyInviteUser]) {
    inviteManyUser(data: $data) {
      id
    }
  }
`

export const UpdateUserRole = gql`
  mutation UpdateUserRole($userId: Int!, $roleid: Int!, $organizationName: String!) {
    updateUserRole(userId: $userId, roleid: $roleid, organizationName: $organizationName) {
      id
    }
  }
`

export const UpdateUser = gql`
  mutation UpdateUser($id: Int!, $name: String, $roleid: Int, $organizationName: String) {
    updateUser(id: $id, name: $name, roleid: $roleid, organizationName: $organizationName) {
      id
    }
  }
`
