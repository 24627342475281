import { Button } from "@mui/material"
import { FC, useState } from "react"
import { Link, Navigate, useParams } from "react-router-dom"
import { CheckoutContainer } from "../../components/CheckoutContainer"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { generateRoute, URL_QUESTIONNAIRE_DETAIL, URL_QUESTIONNAIRE_NEW } from "../../router/routes"
import { useCreateAuditMutation } from "../../services/Audit"
import { useCreateAssessmentPaymentMutation } from "../../services/Subscription"
import { QuestionnaireNewProps } from "./QuestionnaireNew.interfaces"

export const QuestionnaireNew: FC<QuestionnaireNewProps> = () => {
  const query = new URLSearchParams(window.location.search)
  const status = query.get("redirect_status")
  const { compliance } = useParams()

  const [checkout, setCheckout] = useState(false)

  const [createAssessmentPayment] = useCreateAssessmentPaymentMutation()
  const [createAudit, createAuditResp] = useCreateAuditMutation()

  if (createAuditResp.isLoading) return <Loader />
  if (createAuditResp.isError) return <Message error={createAuditResp.error} />

  if (createAuditResp.data)
    return <Navigate to={generateRoute(URL_QUESTIONNAIRE_DETAIL, { id: createAuditResp.data.id.toString() })} />

  return (
    <CheckoutContainer
      price="$249"
      clientSecretFn={async () => {
        const intent = await createAssessmentPayment().unwrap()
        return intent?.clientSecret
      }}
      errorComponent={() => (
        <Message
          title="Payment Canceled"
          actionButton={
            <Link to={generateRoute(URL_QUESTIONNAIRE_NEW, { compliance: compliance })}>
              <Button variant="contained">Retry</Button>
            </Link>
          }
        />
      )}
      successComponent={() => {
        createAudit({
          name: new Date().toString(),
          templateId: Number(compliance),
          year: new Date().getFullYear(),
        }).unwrap()

        return <></>
      }}
      redirect_url={generateRoute(URL_QUESTIONNAIRE_NEW, { compliance: compliance })}
    />
  )
}
