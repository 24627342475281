import { SxProps, Theme } from "@mui/material"
import { DataGridPro, GridColumns, GridToolbar, GridValidRowModel } from "@mui/x-data-grid-pro"
import { useMemo } from "react"
import { ActionKey } from "../EditTable/EditTable.interfaces"
import { TableProps } from "./Table.interface"

const DataGridStyle: SxProps<Theme> = {
  border: "0 !important",
  ".MuiDataGrid-toolbarContainer": { flexDirection: "row-reverse" },
  ".MuiDataGrid-columnSeparator": { visibility: "hidden" },
}

export const Table = <T extends GridValidRowModel = any>(props: TableProps<T>) => {
  const { className = "h-full", data, actionButtons, columns, toolbar = false, ...rest } = props

  const col: GridColumns<T> = useMemo(
    () =>
      [
        ...columns.map((col) => ({ flex: 1, ...col })),
        ...(actionButtons
          ? [
              {
                field: ActionKey,
                headerName: " ",
                disableColumnMenu: true,
                sortable: false,
                ...actionButtons,
              },
            ]
          : []),
      ] as GridColumns<T>,
    [columns, actionButtons]
  )

  return (
    <DataGridPro<T>
      disableSelectionOnClick
      className={className}
      sx={DataGridStyle}
      getRowId={(row) => row.id ?? row.key}
      columns={col}
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      autoHeight
      components={{
        ...(toolbar && { Toolbar: GridToolbar }),
      }}
      componentsProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } } }}
      pageSize={10}
      {...rest}
      rows={data ?? []}
    />
  )
}
