import React, { FC } from "react"
import { matchRoutes, RouteObject, useLocation } from "react-router-dom"
import { AUTHENTICATED_ROUTES, PathRoute } from "../../router/routes"
import { PageTitleHeaderProps } from "./PageTitleHeader.interfaces"

export const PageTitleHeader: FC<PageTitleHeaderProps> = () => {
  const location = useLocation()

  const routes = matchRoutes(AUTHENTICATED_ROUTES as RouteObject[], location.pathname)?.map((route) => ({
    ...route,
    route: route.route as PathRoute,
  }))

  return (
    <div className="mb-3 flex">
      <div>
        <h5 className="font-bold">{routes?.at(-1)?.route.name}</h5>
        <h5 className="fa-1x fw-normal mb-0 text-sm">{routes?.at(-1)?.route.description}</h5>
      </div>
    </div>
  )
}
