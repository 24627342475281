import { FC, useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ReactSelect from "react-select/creatable"
// import Creatable from "react-select/dist/declarations/src/Creatable"
import { EditTable } from "../../components/EditTable"
import { Column, Datum, EditTableProps } from "../../components/EditTable/EditTable.interfaces"
import { PRICEID } from "../../helpers/contants"
import { useAppSelector } from "../../redux/store/hooks"
import { RoutePram } from "../../router/routes"
import { useActionplanQuery, useRemoveActionPlanUserMutation } from "../../services/ActionPlan"
import { useListRoleQuery } from "../../services/Role"
import { useSubscriptionQuery } from "../../services/Subscription"
import { useInviteUserMutation, useListUserQuery, useUpdateUserRoleMutation } from "../../services/User"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { Loader } from "../Loader"
import { Message } from "../Message"
import { ActionPlanCollaboratorProps, FormState } from "./ActionPlanCollaborator.interfaces"

export const ActionPlanCollaborator: FC<ActionPlanCollaboratorProps> = (props) => {
  const { className } = props
  const pram = useParams<RoutePram>()

  const [data, setData] = useState<FormState[]>([])

  const actionplan = useActionplanQuery({ id: Number(pram.id) })
  const retrieveSubscription = useSubscriptionQuery()
  const [inviteUser] = useInviteUserMutation()
  const [removeActionPlanUser] = useRemoveActionPlanUserMutation()
  const [updateUserRole] = useUpdateUserRoleMutation()

  useEffect(() => {
    if (actionplan.data?.Collaborators) setData(actionplan.data?.Collaborators)
  }, [actionplan.data])

  const actionPlanSubscription = useMemo(
    () =>
      retrieveSubscription.data?.items.data.find(
        (subscriptionItem) => subscriptionItem.price.id === PRICEID.actionPlan[process.env.NODE_ENV]
      ),
    [retrieveSubscription.data]
  )

  if (retrieveSubscription.isLoading || actionplan.isLoading) return <Loader />
  if (retrieveSubscription.isError || actionplan.isError || !pram.id)
    return <Message error={retrieveSubscription.isError ?? actionplan.error} />

  const onAdd = async ({ name, email, roleId }: Datum<FormState>) => {
    if (data.length > (actionPlanSubscription?.quantity ?? 0)) {
      setTimeout(() => {
        setData((prev) => prev.slice(0, prev.length - 1))
      })
      return toast.error("Subscription seats Limit Reached\n Cannot add more Users")
    }

    return toast.promise(
      inviteUser({
        name,
        email,
        roleId,
        actionPlanId: Number(pram.id),
      }).unwrap(),
      {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully invited",
      }
    )
  }

  const onRemove = async ({ id }: Datum<FormState>) => {
    return toast.promise(removeActionPlanUser({ id: Number(pram.id), userId: id }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Successfully removed",
    })
  }

  const onUpdate = async ({ ActionPlans, Campaigns, Access, ...data }: Datum<FormState>) => {
    return toast.promise(updateUserRole({ userId: data.id!, roleid: data.roleId }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Successfully updated",
    })
  }

  return (
    <div className={className}>
      <ActionPlanCollaboratorForm
        defaultValue={actionplan.data?.Collaborators}
        onAdd={onAdd}
        onRemove={onRemove}
        onUpdate={onUpdate}
        className="h-auto"
      />
    </div>
  )
}

export const ActionPlanCollaboratorForm: FC<
  {
    defaultValue?: FormState[]
    className?: string
  } & Pick<EditTableProps<FormState>, "onAdd" | "onUpdate" | "onRemove">
> = (props) => {
  const { defaultValue, ...rest } = props

  const user = useAppSelector((store) => store.auth.user)

  const userByOrganization = useListUserQuery()
  const roleByOrganization = useListRoleQuery()

  if (roleByOrganization.isLoading || userByOrganization.isLoading) return <Loader />
  if (roleByOrganization.isError) return <Message error={roleByOrganization.isError} />

  const columns: Column<FormState>[] = [
    {
      flex: 1,
      headerName: "Email",
      field: "email",
      editable: true,
      renderEditCell: ({ value, row, api }) => (
        <ReactSelect
          menuPortalTarget={document.getElementById("root")}
          className="w-full"
          noOptionsMessage={() => <>No user Found</>}
          components={{ IndicatorSeparator: () => <></> }}
          onCreateOption={(option) => api.setEditCellValue({ field: "email", id: row.id, value: option })}
          onChange={(option) => {
            const user = userByOrganization.data?.find((user) => user.email === option?.value)
            if (user) api.setEditCellValue({ field: "email", id: row.id, value: user.email })
          }}
          value={{ label: value, value }}
          options={userByOrganization.data
            ?.filter((option) => option.email !== user?.email)
            .map((user) => ({ value: user.email, label: user.email }))}
        />
      ),
      renderCell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
    {
      flex: 1,
      headerName: "Name",
      field: "name",
      editable: true,
      renderCell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
    {
      flex: 1,
      headerName: "Role",
      field: "roleId",
      editable: true,
      renderEditCell: ({ value, row, api }) => {
        const organizationName = getOrganizationNameFromRoute()!
        const roleid = (row as Datum<FormState>).Access?.find((access) => access.organizationName === organizationName)
          ?.Role.id

        const role = roleByOrganization.data?.find((role) => role.id === (value ?? roleid))

        return (
          <ReactSelect
            menuPortalTarget={document.getElementById("root")}
            className="w-full"
            components={{ IndicatorSeparator: () => <></> }}
            defaultValue={{ label: role?.type, value: role?.id }}
            onChange={(option) => {
              const value = option?.value
              if (value) api.setEditCellValue({ field: "roleId", id: row.id, value: Number(value) })
            }}
            options={roleByOrganization.data?.map(({ type, id }) => ({ label: type ?? "", value: id })) ?? []}
          />
        )
      },
      renderCell: ({ value, row }) => {
        const organizationName = getOrganizationNameFromRoute()!
        const roleid = row.Access?.find((access) => access.organizationName === organizationName)?.Role.id
        const role = roleByOrganization.data?.find((role) => role.id === (value ?? roleid))
        return <div className="text-truncate">{role?.type ?? "Owner"}</div>
      },
    },
    {
      flex: 1,
      headerName: "status",
      field: "status",
      ignoreValidation: true,
      renderCell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
  ]

  return (
    <EditTable<FormState> {...rest} columns={columns} data={defaultValue ?? []} addnewtext="Add Another Collaborator" />
  )
}
