import { MoreVert } from "@mui/icons-material"
import { Button, Menu } from "@mui/material"
import { FC, MouseEvent, useState } from "react"
import { DropdownProps } from "./Dropdown.interfaces"

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    trigger = (
      <Button color="secondary" className="rounded p-[5.224px]">
        <MoreVert />
      </Button>
    ),
    children,
    open = false,
    ...rest
  } = props

  const [anchor, setAnchorEl] = useState<null | Element>(null)

  return (
    <>
      {{
        ...trigger,
        props: {
          ...trigger.props,
          onClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAnchorEl(e.currentTarget)
            trigger.props?.onClick?.(e)
          },
        },
      }}
      <Menu
        anchorEl={anchor}
        open={open || !!anchor}
        onClose={() => setAnchorEl(null)}
        onClickCapture={() => setAnchorEl(null)}
        {...rest}
      >
        {children}
      </Menu>
    </>
  )
}
