import { useEffect } from "react"

export const useConfirmLeaving = () => {
  useEffect(() => {
    window.onbeforeunload = () => ""
    return () => {
      window.onbeforeunload = () => {}
    }
  }, [])
}
