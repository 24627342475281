import { gql } from "graphql-request"

export const CreateAudit = gql`
  mutation CreateAudit($name: String!, $year: Int!, $organizationName: String!, $templateId: Int!) {
    createAudit(name: $name, year: $year, organizationName: $organizationName, templateId: $templateId) {
      id
    }
  }
`

export const UpdateAudit = gql`
  mutation UpdateAudit($id: Int!, $name: String, $type: String, $year: Int, $score: Int, $status: String) {
    updateAudit(id: $id, name: $name, type: $type, year: $year, score: $score, status: $status) {
      id
      name
      type
      year
      score
      status
    }
  }
`

export const AuditByOrganization = gql`
  query AuditByOrganization($name: String!) {
    auditByOrganization(name: $name) {
      id
      name
      type
      year
      score
      Organization {
        name
      }
      status
    }
  }
`
export const AuditById = gql`
  query AuditById($id: Int!) {
    auditById(id: $id) {
      id
      name
      type
      year
      score
      status
      Organization {
        name
      }
    }
  }
`

export const AuditAnswers = gql`
  query AuditById($id: Int!) {
    auditById(id: $id) {
      Answers {
        id
        attachmentUrls
        Question {
          id
          label
          description
          score
          sectionId
        }
        Option {
          id
          label
          questionId
        }
        auditId
        questionId
        optionId
      }
    }
  }
`

export const BulkInvite = gql`
  mutation BulkInvite($data: [BulkInvite!]!) {
    bulkInvite(data: $data) {
      id
      name
      phone
      email
      allowedInvites
      isValid
    }
  }
`
