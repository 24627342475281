import { PermissionEnum } from "../../types/PermissionEnum"
import { ActionPlan } from "../ActionPlan"
import { Emission } from "../Emission"
import { ResourceEnum } from "../Permission"
import { api } from "../rtk"
import {
  CreateInventoryInput,
  CreateManyInventoryInput,
  DeleteInventoryInputById,
  InventoryInputByActionplanId,
  UpdateInventoryInputById,
} from "./InventoryInput.documents"
import { InventoryInput } from "./InventoryInput.interfaces"

export const inventoryInputApi = api.injectEndpoints({
  endpoints: (builder) => ({
    InventoryInputByActionplanId: builder.query<InventoryInput[], { actionplanId: number }>({
      query: (variables) => ({ document: InventoryInputByActionplanId, variables }),
      extraOptions: { headers: { resource: ResourceEnum.InventoryInput, operation: PermissionEnum.Read } },
      providesTags: ["Audit"],
    }),
    createInventoryInput: builder.mutation<
      any,
      {
        actionPlanId: number
        coefficient: number
        name: string
        emissionId: number
        Emission: Emission | null
        unit?: string
      }
    >({
      query: (variables) => ({ document: CreateInventoryInput, variables }),
      extraOptions: { headers: { resource: ResourceEnum.InventoryInput, operation: PermissionEnum.Create } },
      invalidatesTags: ["Audit"],
    }),
    deleteInventoryInput: builder.mutation<any, { id: number }>({
      query: (variables) => ({ document: DeleteInventoryInputById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.InventoryInput, operation: PermissionEnum.Delete } },
      invalidatesTags: ["Audit"],
    }),
    updateInventoryInput: builder.mutation<any, InventoryInput>({
      query: (variables) => ({ document: UpdateInventoryInputById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.InventoryInput, operation: PermissionEnum.Update } },
      invalidatesTags: ["Audit"],
    }),
    createInventoryInputs: builder.mutation<
      any,
      {
        data: {
          actionPlanId: any
          name: string
          ActionPlan?: ActionPlan
          emissionId: number
          Emission: Emission | null
          coefficient: number
          unit?: string
          id?: number
        }[]
      }
    >({
      query: (variables) => ({ document: CreateManyInventoryInput, variables }),
      extraOptions: { headers: { resource: ResourceEnum.InventoryInput, operation: PermissionEnum.Create } },
      invalidatesTags: ["Audit"],
    }),
  }),
})

export const {
  useInventoryInputByActionplanIdQuery,
  useLazyInventoryInputByActionplanIdQuery,
  useCreateInventoryInputMutation,
  useDeleteInventoryInputMutation,
  useUpdateInventoryInputMutation,
  useCreateInventoryInputsMutation,
} = inventoryInputApi
