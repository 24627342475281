import { PermissionEnum } from "../../types/PermissionEnum"
import { Role } from "../Role"

export interface Permission {
  id: number
  type?: PermissionEnum
  Resource: Resource
  Role: Role

  roleId?: number
  resourceId: number
}

export interface Resource {
  id: number
  name: ResourceEnum
  Permissions: Permission[]
}

export enum ResourceEnum {
  Section = "section",
  Question = "question",
  Option = "option",
  Answer = "answer",
  Template = "template",
  Organization = "organization",
  Role = "role",
  User = "user",
  Audit = "audit",
  ActionPlan = "actionPlan",
  Campaign = "campaign",
  Inventory = "inventory",
  InventoryInput = "inventoryInput",
  Payment = "payment",
  Emission = "emission",
  OrganizationProduct = "organizationProduct",
}
