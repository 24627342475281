import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { FC } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { OrganizatoinCard } from "../../pages/OrganizationList"
import { setInvite } from "../../redux/slice"
import { useAppSelector } from "../../redux/store/hooks"
import { generateRoute, URL_ORGANIZATION_NEW } from "../../router/routes"
import { useCreateAuditMutation } from "../../services/Audit"
import { useListOrganizationQuery } from "../../services/Organization"

import { AuditConnectModalProps } from "./AuditConnectModal.interface"

export const AuditConnectModal: FC<AuditConnectModalProps> = (props) => {
  const { className, invite } = props
  const user = useAppSelector((store) => store.auth.user)
  const dispatch = useDispatch()

  const organizationByUserid = useListOrganizationQuery({ id: user?.id! }, { skip: !user?.id })
  const [createAudit, createAuditResp] = useCreateAuditMutation()

  return (
    <Dialog className={className} fullWidth maxWidth="xs" open onClose={() => dispatch(setInvite(undefined))}>
      <DialogTitle>
        <div className="text-xl font-bold">Choose Organization</div>
        <div>Select an organization to access your supplier compliance </div>
      </DialogTitle>
      <DialogContent className="flex max-h-[90vh] min-h-[40vh] flex-wrap">
        <div className="w-full">
          {organizationByUserid.data?.map((organization) => (
            <OrganizatoinCard
              onClick={() => {
                createAudit({
                  name: new Date().toString(),
                  templateId: invite.templateId,
                  year: new Date().getFullYear(),
                }).unwrap()
              }}
              organization={organization}
            />
          ))}
        </div>
      </DialogContent>
      <DialogTitle>
        <Link className="w-full" to={generateRoute(URL_ORGANIZATION_NEW)}>
          <Button variant="contained" fullWidth>
            New organization
          </Button>
        </Link>
      </DialogTitle>
    </Dialog>
  )
}
