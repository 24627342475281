import { createApi } from "@reduxjs/toolkit/query/react"
import { graphBaseQuery } from "./graphBaseQuery"

export const api = createApi({
  reducerPath: "api",
  baseQuery: graphBaseQuery(),
  tagTypes: [
    "Access",
    "ActionPlan",
    "Answer",
    "Audit",
    "Subscription",
    "Role",
    "Organization",
    "OrganizationUsers",
    "Permission",
    "Template",
    "User",
    "Emission",
    "Products",
  ],
  endpoints: (builder) => ({}),
})
