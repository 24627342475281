import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { FC, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { passwordResetRequest } from "../authConfig"
import { Loader } from "../containers/Loader"
import { Message } from "../containers/Message"
import { setOrganization, setRole, setUser } from "../redux/slice"
import { useAppDispatch } from "../redux/store/hooks"
import {
  generateRoute,
  URL_HOME,
  URL_LOGIN,
  URL_ORGANIZATION_NEW,
  URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT,
  URL_QUESTIONNAIRE_NEW,
  URL_SIGNUP,
} from "../router/routes"
import { useUserQuery } from "../services/User"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { LayoutProps } from "./Layout.interfaces"

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation()
  const isAuthenticated = useIsAuthenticated()
  const dispatch = useAppDispatch()
  const { accounts, instance } = useMsal()

  const organizationSelected = pathname.split("/")[1]
  const lastpath = pathname.split("/").at(-1)
  const fullPage =
    pathname === URL_SIGNUP ||
    pathname === URL_LOGIN ||
    pathname === URL_HOME ||
    pathname === generateRoute(URL_ORGANIZATION_NEW) ||
    pathname === generateRoute(URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT) ||
    pathname === generateRoute(URL_QUESTIONNAIRE_NEW, { compliance: lastpath })

  const user = useUserQuery({ adid: accounts[0]?.localAccountId }, { skip: !accounts[0]?.localAccountId })

  useEffect(() => {
    const userdata = user.data
    if (userdata) {
      dispatch(setUser(userdata))
      if (userdata.newInvitedUser) instance.loginRedirect(passwordResetRequest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data])

  useEffect(() => {
    const role = user.data?.Access.find((access) => access.Organization.name === organizationSelected)?.Role
    if (role) dispatch(setRole(role))
  }, [organizationSelected, user.data, dispatch])

  useEffect(() => {
    if (organizationSelected !== "setting" && organizationSelected !== "organization") {
      dispatch(setOrganization(organizationSelected))
    }
  }, [organizationSelected])

  if (user.isLoading)
    return (
      <div className="flex h-full min-h-screen flex-1 flex-grow flex-col justify-between">
        <Loader />
      </div>
    )

  if (user.isError)
    return (
      <div className="flex h-full min-h-screen flex-1 flex-grow flex-col justify-between">
        <Message error={user.error} />
      </div>
    )

  return (
    <>
      <div className="flex h-full min-h-screen flex-1 flex-grow flex-col justify-between">
        {!fullPage && isAuthenticated && <Header />}
        {children}
        {!fullPage && isAuthenticated && <Footer />}
      </div>
    </>
  )
}
