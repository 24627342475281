import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { MultiStats } from "../Organization"
import { ResourceEnum } from "../Permission"
import { api } from "../rtk"
import { User } from "../User"
import {
  ActionplanById,
  ActionplanByOrganization,
  CreateActionPlan,
  createWastage,
  DeleteActionPlanById,
  RemoveActionPlanUser,
  UpdateActionPlanById,
  wastageByActionPlanId,
} from "./Actionplan.documents"
import { ActionPlan, WasteAndCircularity } from "./ActionPlan.interfaces"

export const actionPlanApi = api.injectEndpoints({
  endpoints: (builder) => ({
    actionplan: builder.query<ActionPlan, { id: number }>({
      query: (variables) => ({ document: ActionplanById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Read } },
      providesTags: ["ActionPlan"],
    }),

    Stats: builder.query<
      { id: number; data: { emission: number; date: string }[] }[],
      { actionPlanId: number; emissionIds: number[] }
    >({
      query: (variables) => ({
        document: MultiStats,
        variables,
      }),
      extraOptions: { headers: { resource: ResourceEnum.Emission, operation: PermissionEnum.Read } },
      providesTags: ["ActionPlan"],
    }),

    deleteActionPlanById: builder.mutation<ActionPlan, { id: number }>({
      query: (variables) => ({ document: DeleteActionPlanById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Read } },
      invalidatesTags: ["ActionPlan"],
    }),

    createWastage: builder.mutation<{ id: number }, WasteAndCircularity>({
      query: (variables) => ({ document: createWastage, variables: { data: variables } }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Create } },
      invalidatesTags: ["ActionPlan"],
    }),

    listWastage: builder.query<WasteAndCircularity[], { actionPlanId: number }>({
      query: (variables) => ({ document: wastageByActionPlanId, variables: variables }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Create } },
      providesTags: ["ActionPlan"],
    }),

    createActionPlan: builder.mutation<
      any,
      Omit<ActionPlan, "id" | "InventoryInputs" | "Collaborators" | "Organization" | "organizationName">
    >({
      query: (variables) => ({
        document: CreateActionPlan,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Create } },
      invalidatesTags: ["ActionPlan"],
    }),

    listActionplan: builder.query<ActionPlan[], { uid: User["id"] }>({
      query: (variables) => ({
        document: ActionplanByOrganization,
        variables: { ...variables, name: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Read } },
      providesTags: ["ActionPlan"],
    }),

    removeActionPlanUser: builder.mutation<void, { id?: number; userId?: number }>({
      query: (variables) => ({
        document: RemoveActionPlanUser,
        variables,
      }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Update } },
      invalidatesTags: ["ActionPlan"],
    }),

    updateActionPlan: builder.mutation<
      void,
      Omit<ActionPlan, "id" | "InventoryInputs" | "Collaborators" | "Organization">
    >({
      query: (variables) => ({ document: UpdateActionPlanById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.ActionPlan, operation: PermissionEnum.Update } },
      invalidatesTags: ["ActionPlan"],
    }),
  }),
})

export const {
  useRemoveActionPlanUserMutation,
  useActionplanQuery,
  useCreateWastageMutation,
  useLazyActionplanQuery,
  useCreateActionPlanMutation,
  useListActionplanQuery,
  useLazyStatsQuery,
  useStatsQuery,
  useLazyListActionplanQuery,
  useUpdateActionPlanMutation,
  useDeleteActionPlanByIdMutation,
  useListWastageQuery,
} = actionPlanApi
