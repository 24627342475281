import { gql } from "graphql-request"

export const OrganizationProductByOrg = gql`
  query OrganizationProductByOrg($name: String!) {
    organizationProductByOrg(name: $name) {
      id
      name
      SKU
      substance
      CAS
      classification
      reason
    }
  }
`
export const OrganizationProductById = gql`
  query OrganizationproductById($organizationproductById: Int!) {
    organizationproductById(id: $organizationproductById) {
      id
      name
      SKU
      substance
      CAS
      classification
      reason
      firstAdded
      exmaple
      lastRevised
      reportingThreshold
      SubProduct {
        id
        name
        SKU
        substance
        CAS
        classification
        reason
        firstAdded
        exmaple
        lastRevised
        reportingThreshold
        threshold
      }
      threshold
    }
  }
`

export const addProduct = gql`
  mutation AddProduct($data: String!) {
    addProduct(data: $data) {
      id
    }
  }
`

export const updateProduct = gql`
  mutation UpdateProduct($data: String!) {
    updateProduct(data: $data) {
      id
    }
  }
`

export const deleteProductById = gql`
  mutation DeleteProductById($id: Int!) {
    deleteProductById(id: $id) {
      id
    }
  }
`

export const getGADSLs = gql`
  query GetGADSLs($substance: String, $cas: String) {
    getGADSLs(substance: $substance, cas: $cas) {
      cas
      classification
      substance
    }
  }
`
