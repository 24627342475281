import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Layout } from "../layouts"
import { RecursiveRouteMapper } from "./RecursiveRouteMapper"
import { AUTHENTICATED_ROUTES, OPEN_ROUTES, UNAUTHENTICATED_ROUTES, URL_HOME, URL_LOGIN } from "./routes"

export const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <AuthenticatedTemplate>
          <Routes>
            {RecursiveRouteMapper(OPEN_ROUTES)}
            {RecursiveRouteMapper(AUTHENTICATED_ROUTES)}
            <Route path={URL_LOGIN} element={<Navigate to={URL_HOME} />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            {RecursiveRouteMapper(OPEN_ROUTES)}
            {RecursiveRouteMapper(UNAUTHENTICATED_ROUTES)}
            <Route path={URL_HOME} element={<Navigate to={URL_LOGIN} />} />
          </Routes>
        </UnauthenticatedTemplate>
      </Layout>
    </BrowserRouter>
  )
}
