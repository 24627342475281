import { useMsal } from "@azure/msal-react"
import { FC, useEffect } from "react"
import { loginRequest } from "../../authConfig"
import { Loader } from "../../containers/Loader"
import { LoginProps } from "./Login.interfaces"

export const Login: FC<LoginProps> = () => {
  const { instance } = useMsal()

  useEffect(() => {
    instance.loginRedirect(loginRequest)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance])

  return <Loader />
}
