import { gql } from "graphql-request"

export const getTemplateById = gql`
  query TemplateById($id: Int!) {
    templateById(id: $id) {
      id
      name
      category
      industry
      Sections {
        name
        id
        Questions {
          id
          label
          description
          sectionId
          Options {
            id
            label
          }
        }
        SubSection {
          name
          id
          Questions {
            id
            label
            description
            sectionId
            Options {
              id
              label
            }
          }
        }
      }
      icon
      description
      tag
    }
  }
`

export const templateAll = gql`
  query TemplateAll {
    templateAll {
      id
      name
      industry
      category
      description
      icon
      tag
    }
  }
`
