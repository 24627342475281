import { Card, LinearProgress } from "@mui/material"
import { FC } from "react"
import { QuestionnaireProgressProps } from "./QuestionnaireProgress.interface"

export const QuestionnaireProgress: FC<QuestionnaireProgressProps> = (props) => {
  const { className, flatSections, activeSections } = props

  const total = flatSections.reduce((acc, item) => acc + item.Questions.length, 0)
  const answered = flatSections.reduce(
    (acc, item) => (item.index < activeSections.index ? acc : acc + item.Questions.length),
    0
  )

  return (
    <Card className={className}>
      <div className="mb-2 text-lg font-semibold">Progress</div>
      <LinearProgress
        variant="buffer"
        value={100 - (answered / total) * 100}
        valueBuffer={100 - ((answered - activeSections.Questions.length) / total) * 100}
      />
    </Card>
  )
}
