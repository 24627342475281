import { FC } from "react"

import { ActionPlanAnalyticsProps, Stat } from "./ActionPlanAnalytics.interface"

import { Delete } from "@mui/icons-material"
import { Button, Checkbox } from "@mui/material"
import { GridRowParams } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { omit } from "lodash"
import { useMemo, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import {
  Bar,
  BarChart as ReBarChart,
  Brush,
  CartesianGrid,
  Legend,
  Pie,
  PieChart as RePieChart,
  ReferenceLine,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { Column } from "../../components/EditTable/EditTable.interfaces"
import { Table } from "../../components/Table"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { generateRoute, RoutePram, URL_ACTIONPLAN_INVENTORY } from "../../router/routes"
import { useActionplanQuery, useListWastageQuery, useStatsQuery, WasteAndCircularity } from "../../services/ActionPlan"
import { Inventory, useDeleteInventoryMutation, useInventoryByActionplanIdQuery } from "../../services/Inventory"
import { useInventoryInputByActionplanIdQuery } from "../../services/InventoryInput"

export const ActionPlanAnalytics: FC<ActionPlanAnalyticsProps> = (props) => {
  const { className } = props
  // const componentRef = useRef(null)

  // const handlePrint = useReactToPrint({ content: () => componentRef.current })
  const { id } = useParams<RoutePram & { tab: "dashboard" | "detail" | "inventoryinputs" | "collaborator" }>()
  const inventoryInputByActionplanId = useInventoryInputByActionplanIdQuery({ actionplanId: Number(id) }, { skip: !id })
  const actionplan = useActionplanQuery({ id: Number(id) }, { skip: !id })
  const inventoryByActionplanId = useInventoryByActionplanIdQuery({ actionplanId: Number(id) })
  const listWastage = useListWastageQuery({ actionPlanId: Number(id) })

  const [deleteInventory] = useDeleteInventoryMutation()

  const multiStats = useStatsQuery(
    {
      actionPlanId: Number(id),
      emissionIds: inventoryInputByActionplanId.data?.map((inventory) => inventory.Emission?.id!)!,
    },
    {
      skip:
        inventoryInputByActionplanId.isLoading ||
        !inventoryInputByActionplanId.data ||
        !inventoryInputByActionplanId.data?.length,
    }
  )

  const emissions = useMemo(
    () => inventoryInputByActionplanId.data?.map((inventory) => inventory.Emission),
    [inventoryInputByActionplanId.data]
  )

  const stats: Stat[] | undefined = useMemo(
    () => multiStats.data?.map((stats) => ({ ...stats, ...emissions?.find((em) => em?.id === stats.id) })),
    [multiStats.data, emissions]
  )

  if (inventoryByActionplanId.isLoading || actionplan.isLoading || multiStats.isLoading) return <Loader />

  if (inventoryByActionplanId.isError || (actionplan.isError && inventoryInputByActionplanId.data))
    return <Navigate to={generateRoute(URL_ACTIONPLAN_INVENTORY, { id: id })} />
  if (actionplan.isError || !id) return <Message error={actionplan.error} />

  const inventorycolumns: Column<Inventory>[] = [
    {
      field: "date",
      headerName: "Date",
      renderCell: ({ value }) => new Date(Number(value)).toLocaleDateString(),
    },
    {
      field: "InventoryInput",
      valueGetter: ({ value }) => value?.name ?? value?.Emission?.source,
      headerName: "Source",
    },
    {
      field: "unit" as keyof Inventory,
      valueGetter: ({ row }) => row.InventoryInput.unit ?? row.InventoryInput.Emission?.unit,
      headerName: "Unit",
    },
    {
      field: "emission",
      headerName: "Emissions (MTCO2e)",
      renderCell: ({ value }) => value?.toFixed(2),
    },
  ]

  const subInventorycolumns: Column<Inventory>[] = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "Emission",
      valueGetter: ({ value }) => value?.name ?? value?.Emission?.source ?? value?.source,
      headerName: "Source",
    },
    {
      field: "unit" as keyof Inventory,
      valueGetter: ({ row }) => row.Emission?.unit,
      headerName: "Unit",
    },
    {
      field: "usage",
      headerName: "Emissions (MTCO2e)",
      renderCell: ({ value }) => value?.toFixed(2),
    },
  ]

  const wastecolumns: Column<WasteAndCircularity>[] = [
    {
      field: "date",
      headerName: "Date",
      renderCell: ({ value }) => new Date(Number(value)).toLocaleDateString(),
    },
    {
      field: "material",
      headerName: "Material",
    },
    {
      field: "resource",
      headerName: "Resource",
    },
    {
      field: "quantityRecovered",
      headerName: "Recovered",
    },
    {
      field: "recycledYield",
      headerName: "Recycled",
    },
    {
      field: "wastage",
      headerName: "Wastage",
    },
    {
      field: "unit",
      headerName: "Unit",
    },
    {
      field: "eolProcess",
      headerName: "Eol Process",
    },
    {
      field: "dfd",
      headerName: "DFD",
      renderCell: ({ value }) => (
        <Checkbox checked={value} className="-mb-2" sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
      ),
    },
    {
      field: "dfa",
      headerName: "DFA",
      renderCell: ({ value }) => (
        <Checkbox checked={value} className="-mb-2" sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
      ),
    },
  ]

  return (
    <div className={`-mx-2 flex h-full flex-wrap ${className}`}>
      <div className="flex-1 px-2">
        <Table<Inventory>
          columns={inventorycolumns}
          pageSize={5}
          density="compact"
          className="h-1/2"
          hideFooter
          data={inventoryByActionplanId.data}
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }: GridRowParams<Inventory>) => {
            console.log("row.SubInventory :: ", row.SubInventory)

            return !!row.SubInventory?.length ? (
              <Table<Inventory>
                // headerHeight={0}
                density="compact"
                className="ml-14 rounded-none bg-gray-200"
                columns={subInventorycolumns}
                data={row.SubInventory}
                hideFooter
                actionButtons={{
                  renderCell: ({ row }) => <></>,
                }}
              />
            ) : undefined
          }}
          actionButtons={{
            renderCell: ({ row }) => (
              <Button
                size="small"
                variant="contained"
                // className="print:hidden"
                onClick={() => row.id && deleteInventory({ id: row.id }).unwrap()}
              >
                <Delete />
              </Button>
            ),
          }}
        />
        <Table<WasteAndCircularity>
          columns={wastecolumns}
          pageSize={5}
          className="h-1/2"
          hideFooter
          density="compact"
          data={listWastage.data}
          // actionButtons={{
          //   renderCell: ({ row }) => (
          //     <Button
          //       size="small"
          //       variant="contained"
          //       // className="print:hidden"
          //       onClick={() => row.id && deleteInventory({ id: row.id }).unwrap()}
          //     >
          //       <Delete />
          //     </Button>
          //   ),
          // }}
        />
      </div>
      <div className="max-w-[50%] space-y-4 px-2 md:w-[550px]">
        <div>{stats && <BarChart stats={stats ?? []} />}</div>
        <div>{stats && <PieChart stats={stats ?? []} />}</div>
      </div>
    </div>
  )
}

const renderActiveShape = (props: {
  cx: any
  cy: any
  midAngle: any
  innerRadius: any
  outerRadius: any
  startAngle: any
  endAngle: any
  fill: any
  payload: any
  percent: any
  value: any
}) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? "start" : "end"

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${Number(value).toFixed(
        2
      )}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const PieChart = (props: { stats: Stat[] }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <ResponsiveContainer className="min-h-[250px]" width="100%">
      <RePieChart width={450} height={250}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={props.stats.map((item) => ({
            name: item.source,
            value: item.data.reduce((acc, emission) => acc + emission.emission, 0),
          }))}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={(_, index) => setActiveIndex(index)}
        />
      </RePieChart>
    </ResponsiveContainer>
  )
}

function BarChart(props: { stats: Stat[] }) {
  // const colors = distinctColors({ count: props.stats.length })
  type Data = Record<string, number | string>

  const val = props.stats.reduce(
    (incacc, inventory) => ({
      ...incacc,
      ...inventory.data.reduce(
        (emiacc, emission) => ({
          ...emiacc,
          [emission.date]: {
            ...incacc[emission.date],
            date: format(new Date(Number(emission.date)), "MMM yyyy"),
            [inventory.source!]: emission.emission,
          } as Data,
        }),
        {} as Record<string, Data>
      ),
    }),
    {} as Record<string, Data>
  )

  return (
    <ResponsiveContainer className="min-h-[250px]" width="100%">
      <ReBarChart width={450} height={250} data={Object.values(val)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis fontSize={10} dataKey="date" />
        {Object.keys(omit(Object.values(val)[0], "date")).map((item, index) => (
          <YAxis key={index} fontSize={10} dataKey={item} />
        ))}
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
        <ReferenceLine y={0} stroke="#000" />
        <Brush dataKey="date" height={30} stroke="#8884d8" />
        {props.stats.map((record, index) => (
          <Bar dataKey={record.source as string} fill="#8884d8" />
        ))}
      </ReBarChart>
    </ResponsiveContainer>
  )
}
