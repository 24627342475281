import { gql } from "graphql-request"

export const CreateAssessmentPayment = gql`
  mutation CreateAssessmentPayment($organizationName: String) {
    createAssessmentPayment(organizationName: $organizationName) {
      status
      id
      clientSecret
    }
  }
`

export const CreateSubscription = gql`
  mutation CreateSubscription(
    $organizationName: String!
    $product: [SubscriptionProduct!]!
    $startDate: Int
    $subscriptionLength: Int
  ) {
    createSubscription(
      organizationName: $organizationName
      product: $product
      startDate: $startDate
      subscriptionLength: $subscriptionLength
    ) {
      status
      id
      clientSecret
    }
  }
`

export const RetrieveSubscription = gql`
  query RetrieveSubscription($organizationName: String!) {
    retrieveSubscription(organizationName: $organizationName) {
      status
      items {
        data {
          id
          price {
            id
          }
          quantity
        }
      }
    }
  }
`
