import { MsalProvider } from "@azure/msal-react"
import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LicenseInfo } from "@mui/x-license-pro"
import { createRoot } from "react-dom/client"
import { Toaster, ToastOptions } from "react-hot-toast"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { MsalInstance } from "./authConfig"
import { Loader } from "./containers/Loader"
import { persistor, store } from "./redux"
import { Router } from "./router"
import * as serviceWorker from "./serviceWorker"
import "./styles/index.scss"
import { theme } from "./styles/MuiTheme"

const root = createRoot(document.getElementById("root")!)

const toastOption: ToastOptions = {
  position: "top-right",
  duration: 3000,
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY ?? "")

root.render(
  <MsalProvider instance={MsalInstance}>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router />
            <Toaster {...toastOption} />
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  </MsalProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
