import { FC } from "react"
import { toast } from "react-hot-toast"
import { useParams } from "react-router-dom"
import ReactSelect from "react-select/creatable"
import { EditTable } from "../../components/EditTable"
import { Column, EditTableProps } from "../../components/EditTable/EditTable.interfaces"
import { RoutePram } from "../../router/routes"
import { useListEmissionQuery } from "../../services/Emission"
import {
  InventoryInput,
  useCreateInventoryInputMutation,
  useDeleteInventoryInputMutation,
  useInventoryInputByActionplanIdQuery,
  useUpdateInventoryInputMutation,
} from "../../services/InventoryInput"
import { Loader } from "../Loader/index"
import { Message } from "../Message/Message"
import { ActionPlanInventoryInputsProps } from "./ActionPlanInventoryInputs.interfaces"

export const ActionPlanInventoryInputs: FC<ActionPlanInventoryInputsProps> = (props) => {
  const { className } = props
  const pram = useParams<RoutePram>()

  const inventoryInputByActionplanId = useInventoryInputByActionplanIdQuery({ actionplanId: Number(pram.id) })
  const [createInventoryInput] = useCreateInventoryInputMutation()
  const [deleteInventoryInput] = useDeleteInventoryInputMutation()
  const [updateInventoryInput] = useUpdateInventoryInputMutation()

  const onAdd: EditTableProps<InventoryInput>["onAdd"] = async ({ id, ActionPlan, ...data }) => {
    return toast.promise(
      createInventoryInput({
        ...data,
        actionPlanId: Number(pram.id),
        coefficient: Number(data.coefficient),
        ...(typeof data.emissionId === "string" && { name: data.emissionId, emissionId: undefined }),
        ...(typeof data.emissionId === "number" && { emissionId: data.emissionId, name: undefined }),
      }).unwrap(),
      {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully added",
      }
    )
  }

  const onRemove: EditTableProps<InventoryInput>["onRemove"] = async ({ id }) => {
    return toast.promise(deleteInventoryInput({ id }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Successfully removed",
    })
  }

  const onUpdate: EditTableProps<InventoryInput>["onUpdate"] = async ({ ActionPlan, coefficient, ...data }) => {
    return toast.promise(
      updateInventoryInput({
        ...data,
        ...(coefficient !== null && { coefficient: Number(coefficient) }),
      } as InventoryInput).unwrap(),
      {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully updated",
      }
    )
  }

  if (inventoryInputByActionplanId.isLoading) return <Loader />
  if (inventoryInputByActionplanId.isError || !pram.id) return <Message error={inventoryInputByActionplanId.error} />

  return (
    <div className={className}>
      <ActionPlanInventoryInputsForm
        defaultValue={inventoryInputByActionplanId.data}
        onAdd={onAdd}
        onRemove={onRemove}
        onUpdate={onUpdate}
      />
    </div>
  )
}

export const ActionPlanInventoryInputsForm: FC<
  {
    defaultValue?: InventoryInput[]
    className?: string
  } & Pick<EditTableProps<InventoryInput>, "onAdd" | "onUpdate" | "onRemove">
> = (props) => {
  const { defaultValue, ...rest } = props

  const getEmissions = useListEmissionQuery()

  if (getEmissions.isLoading) return <Loader />
  if (getEmissions.isError) return <Message error={getEmissions.error} />

  const columns: Column<InventoryInput>[] = [
    {
      flex: 2,
      headerName: "Item",
      field: "emissionId",
      renderEditCell: ({ value, row, api }) => {
        const emission = getEmissions.data?.find((emisison) => emisison.id === value)
        const label = typeof value === "string" ? value : emission?.source ?? row.Emission?.source ?? row.name

        const data = Object.values(api.state.rows.idRowsLookup) as InventoryInput[]

        return (
          <ReactSelect
            value={{ label, value }}
            className="w-full"
            components={{ IndicatorSeparator: () => <></> }}
            onCreateOption={(value) => api.setEditCellValue({ field: "emissionId", id: row.id, value: value })}
            onChange={(option) => api.setEditCellValue({ field: "emissionId", id: row.id, value: option?.value })}
            menuPortalTarget={document.getElementById("root")}
            options={
              getEmissions.data
                ?.filter((emission) => !data.some((record) => record.emissionId === emission.id))
                ?.map(({ source, id }) => ({ label: source, value: id })) ?? ([] as any[])
            }
          />
        )
      },
      renderCell: ({ value, row }) => {
        const emission = getEmissions.data?.find((emisison) => emisison.id === value)
        const label = typeof value === "string" ? value : emission?.source ?? row.Emission?.source ?? row.name

        return <div className="text-truncate">{label}</div>
      },
      ignoreValidation: (row) => !!(row.name || row.emissionId),
      editable: true,
    },

    {
      flex: 1,
      headerName: "Unit",
      field: "unit",
      renderEditCell: ({ value, row, api }) =>
        !row.emissionId ? (
          <div className="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiDataGrid-editInputCell">
            <input
              type="text"
              className="MuiInputBase-input"
              onChange={({ target: { value } }) => api.setEditCellValue({ field: "unit", id: row.id, value: value })}
            />
          </div>
        ) : (
          <></>
        ),
      renderCell: ({ value, row }) => (!row.emissionId ? <>{value ?? row.Emission?.unit}</> : <></>),
      ignoreValidation: (row) => !!row.emissionId,
      editable: true,
    },
    {
      flex: 1,
      headerName: "Coefficient",
      field: "coefficient",
      renderEditCell: ({ value, row, api }) =>
        !row.emissionId ? (
          <div className="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiDataGrid-editInputCell">
            <input
              type="text"
              className="MuiInputBase-input"
              onChange={({ target: { value } }) =>
                api.setEditCellValue({ field: "coefficient", id: row.id, value: value })
              }
            />
          </div>
        ) : (
          <></>
        ),
      renderCell: ({ value, row }) => (!row.emissionId ? <>{value}</> : <></>),
      ignoreValidation: (row) => !!row.emissionId,
      editable: true,
    },
  ]

  return (
    <EditTable<InventoryInput>
      {...rest}
      columns={columns}
      data={defaultValue ?? []}
      newRecord={{
        coefficient: undefined,
        emissionId: undefined,
        ActionPlan: undefined,
        actionPlanId: undefined,
        Emission: undefined,
        id: undefined,
        name: undefined,
        unit: undefined,
      }}
      addnewtext="Add New Inventory Item"
    />
  )
}
