import { gql } from "graphql-request"

export const CreateInventory = gql`
  mutation createInventory(
    $date: String!
    $usage: Float!
    $inventoryInputId: Int!
    $subInventory: [SubInventoryInput]
  ) {
    createInventory(date: $date, usage: $usage, inventoryInputId: $inventoryInputId, SubInventory: $subInventory) {
      id
    }
  }
`

export const DeleteInventoryById = gql`
  mutation DeleteInventoryById($id: Int!) {
    deleteInventoryById(id: $id) {
      id
    }
  }
`

export const InventoryByActionplanId = gql`
  query InventoryByActionplanId($actionplanId: Int!) {
    inventoryByActionplanId(id: $actionplanId) {
      id
      date
      emission
      usage
      InventoryInput {
        name
        unit
        Emission {
          unit
          source
        }
      }
      SubInventory {
        id
        date
        emission
        usage
        name
        Emission {
          unit
          source
          id
        }
      }
    }
  }
`
