import { Add, RemoveCircleOutline } from "@mui/icons-material"
import UpgradeIcon from "@mui/icons-material/Upgrade"
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { FormField } from "../../components/FormField"
import {
  ACTIONCHAPTER,
  COBENEFITS,
  PROGRAMLEAD,
  SCOPES,
  sectors,
  STATUSOPTIONS,
  SUSTAINABILITYTYPE,
  TERMS,
} from "../../helpers/contants"
import { generateRoute, RoutePram, URL_ACTIONPLAN_DASHBOARD } from "../../router/routes"
import { ActionPlan, useActionplanQuery, useUpdateActionPlanMutation } from "../../services/ActionPlan"
import { useListUserQuery } from "../../services/User"
import { Loader } from "../Loader"
import { Message } from "../Message"
import { ActionPlanDetailProps, FormState } from "./ActionPlanDetail.interfaces"

export const ActionPlanDetail: FC<ActionPlanDetailProps> = (props) => {
  const { className, editMode } = props
  const pram = useParams<RoutePram>()
  const navigate = useNavigate()
  const [formRef, setFormRef] = useState<UseFormReturn<FormState> | null>(null)

  const actionplan = useActionplanQuery({ id: Number(pram.id) })

  const [updateActionPlan] = useUpdateActionPlanMutation()
  const userByOrganization = useListUserQuery()

  if (userByOrganization.isLoading || actionplan.isLoading) return <Loader />
  if (userByOrganization.isError || actionplan.isError) return <Message error={userByOrganization.error} />

  const onSubmit = async (value: FormState | ActionPlan) => {
    const { Collaborators, InventoryInputs, Organization, ...data } = value as unknown as ActionPlan

    toast
      .promise(
        updateActionPlan({
          ...data,
          benefits: data.benefits?.replace(/,/g, " "),
          cost: data.cost?.replace(/,/g, ""),
        }).unwrap(),
        {
          error: (error) => error?.message ?? "Something went wrong",
          loading: "Please Wait",
          success: "Successfully updated",
        }
      )
      .then(() => navigate(generateRoute(URL_ACTIONPLAN_DASHBOARD, { id: pram.id, tab: "" })))
  }

  return (
    <div key={pram.id} className={className}>
      <ActionPlanDetailForm
        editMode={editMode}
        defaultValue={actionplan.data}
        onSubmit={onSubmit}
        formInstance={setFormRef}
      />
      {editMode && (
        <div className="text-right">
          <Button variant="contained" onClick={formRef?.handleSubmit(onSubmit)} endIcon={<UpgradeIcon />}>
            Update
          </Button>
        </div>
      )}
    </div>
  )
}

export const ActionPlanDetailForm = (props: {
  onSubmit: (value: FormState | ActionPlan) => void
  defaultValue?: ActionPlan | FormState
  editMode?: boolean
  formInstance?: (instance: UseFormReturn<FormState>) => void
}) => {
  const { onSubmit, defaultValue, formInstance, editMode } = props

  const form = useForm<FormState>({
    defaultValues: {
      actionChapter: undefined,
      coBenefits: [],
      actionItems: [{ description: "", status: "" }],
      leadId: undefined,
      scope: undefined,
      sector: undefined,
      sustainabilityType: undefined,
      terms: undefined,
      title: undefined,
      ...defaultValue,
      benefits: defaultValue ? Number(defaultValue?.benefits).toLocaleString("en-US") : undefined,
      cost: defaultValue ? Number(defaultValue?.cost).toLocaleString("en-US") : undefined,
    },
  })
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: form.control,
    name: "actionItems",
  })

  useEffect(() => {
    if (formInstance) formInstance(form)
  }, [formInstance, form])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="-mx-2 flex flex-wrap">
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="title"
            label={<b>Title</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <TextField InputProps={{ readOnly: !editMode }} size="small" variant="outlined" fullWidth />
          </FormField>
        </div>

        <div className="mb-2 mt-2 w-full pl-2">
          <h6 className="text-lg font-bold">Categorization</h6>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="sustainabilityType"
            label={<b>Sustainability Type</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {SUSTAINABILITYTYPE.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="leadId"
            label={<b>Program Lead</b>}
            valueAsNumber
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {PROGRAMLEAD.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="coBenefits"
            label={<b>Co-Benefits</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            {({ value, error, ...rest }) => (
              <Select
                size="small"
                variant="outlined"
                fullWidth
                multiple
                readOnly={!editMode}
                error={!!error}
                value={(value as string[]) ?? []}
                renderValue={(selected: string[]) =>
                  COBENEFITS.filter((b) => selected?.includes(b.value))
                    .map((b) => b.text)
                    .join(", ")
                }
                {...rest}
              >
                {COBENEFITS.map(({ text, value: val }) => (
                  <MenuItem className="py-0 pl-2" key={val} value={val}>
                    <Checkbox checked={(value as string[])?.includes(val)} />
                    {text}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="terms"
            label={<b>Terms</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {TERMS.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="sector"
            label={<b>Sector</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {sectors.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="scope"
            label={<b>Scope</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {SCOPES.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            name="actionChapter"
            label={<b>Action Chapter</b>}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
              {ACTIONCHAPTER.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormField>
        </div>
        <div className="w-full px-2 sm:w-6/12 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            label={<b>Cost</b>}
            name="cost"
            transform={(value) => {
              const newval = Number(value.replace(/,/g, ""))
              return !Number.isNaN(newval) ? newval.toLocaleString("en-US") : form.getValues("cost") ?? ""
            }}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <TextField
              className="min-w-[auto]"
              size="small"
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: !editMode,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </FormField>
        </div>
        <div className="w-full px-2 md:w-4/12">
          <FormField
            control={form.control}
            className="mb-3"
            label={<b>Benefit</b>}
            name="benefits"
            transform={(value) => {
              const newval = Number(value.replace(/,/g, ""))
              return !Number.isNaN(newval) ? newval.toLocaleString("en-US") : form.getValues("benefits") ?? ""
            }}
            rules={{
              required: { message: "Value is Required", value: true },
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              className="min-w-[auto]"
              InputProps={{
                readOnly: !editMode,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </FormField>
        </div>

        <div className="mb-2 mt-2 w-full pl-2">
          <h6 className="text-lg font-bold">Action Items</h6>
        </div>

        {fields.map((field, index) => (
          <div className="flex w-full flex-wrap" key={field.id}>
            <div className="w-full px-2 sm:w-8/12">
              <FormField
                control={form.control}
                className="mb-3"
                label={<b>Description</b>}
                name={`actionItems.${index}.description`}
                rules={{
                  required: { message: "Value is Required", value: true },
                }}
              >
                <TextField
                  size="small"
                  InputProps={{
                    readOnly: !editMode,
                    inputComponent: TextareaAutosize,
                  }}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                />
              </FormField>
            </div>
            <div className="flex w-full items-start space-x-2 px-2 sm:w-4/12">
              <FormField
                control={form.control}
                className="mb-3 flex-1"
                name={`actionItems.${index}.status`}
                label={<b>Status</b>}
                rules={{
                  required: { message: "Value is Required", value: true },
                }}
              >
                <Select size="small" variant="outlined" fullWidth readOnly={!editMode}>
                  {STATUSOPTIONS.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <div className="w-9">
                {fields.length > 1 && (
                  <IconButton onClick={() => remove(index)} className="mt-7">
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="w-full text-right">
          <Button variant="contained" onClick={() => append({ description: "", status: "" })} className="mt-7">
            <Add /> Add More
          </Button>
        </div>
      </div>
    </form>
  )
}
