import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import {
  InviteManyUser,
  InviteUser,
  UpdateUser,
  UpdateUserRole,
  UserByAdid,
  UserByOrganization,
} from "./User.documents"
import { User } from "./User.interfaces"

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<User, { adid: string }>({
      query: (variables) => ({ document: UserByAdid, variables }),
      extraOptions: ({ variables }) => ({
        headers: { adid: variables.adid, resource: ResourceEnum.User, operation: PermissionEnum.Read },
      }),
      providesTags: ["User"],
    }),
    listUser: builder.query<User[], void>({
      query: () => ({ document: UserByOrganization, variables: { name: getOrganizationNameFromRoute()! } }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Read } },
      providesTags: ["OrganizationUsers", "ActionPlan"],
    }),
    inviteUser: builder.mutation<any, { name: string; email: string; roleId?: number; actionPlanId?: number }>({
      query: (variables) => ({
        document: InviteUser,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Create } },
      invalidatesTags: ["OrganizationUsers", "ActionPlan"],
    }),
    inviteManyUser: builder.mutation<
      any,
      { data: { name: string; email: string; roleId?: number; actionPlanId?: number }[] }
    >({
      query: (variables) => ({
        document: InviteManyUser,
        variables: {
          data: variables.data.map((item) => ({ ...item, organizationName: getOrganizationNameFromRoute()! })),
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Create } },
      invalidatesTags: ["OrganizationUsers", "ActionPlan"],
    }),
    updateUser: builder.mutation<any, { name: string; id: number; roleid?: number }>({
      query: (variables) => ({
        document: UpdateUser,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Update } },
      invalidatesTags: ["User"],
    }),
    updateUserRole: builder.mutation<User, { userId: number; roleid?: number }>({
      query: (variables) => ({
        document: UpdateUserRole,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.User, operation: PermissionEnum.Update } },
      invalidatesTags: ["OrganizationUsers", "ActionPlan"],
    }),
  }),
})

export const {
  useUserQuery,
  useLazyUserQuery,
  useLazyListUserQuery,
  useListUserQuery,
  useInviteUserMutation,
  useInviteManyUserMutation,
  useUpdateUserMutation,
  useUpdateUserRoleMutation,
} = userApi
