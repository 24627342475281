import { ArrowBack } from "@mui/icons-material"
import { Button, IconButton, Tab, Tabs } from "@mui/material"
import { FC, useState } from "react"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { ActionPlanWasteAndCircularity } from "../../components/ActionPlanWasteAndCircularity"
import { TabExtra } from "../../components/TabExtra"
import { ActionPlanAnalytics } from "../../containers/ActionPlanAnalytics"
import { ActionPlanCollaborator } from "../../containers/ActionPlanCollaborator"
import { ActionPlanDetail } from "../../containers/ActionPlanDetail"
import { ActionPlanInventoryDetail } from "../../containers/ActionPlanInventoryDetail"
import { ActionPlanInventoryInputs } from "../../containers/ActionPlanInventoryInputs"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { STATUSOPTIONS } from "../../helpers/contants"
import {
  generateRoute,
  RoutePram,
  URL_ACTIONPLAN_DASHBOARD,
  URL_ACTIONPLAN_INVENTORY,
  URL_ACTIONPLAN_LIST,
} from "../../router/routes"
import { useActionplanQuery } from "../../services/ActionPlan"
import { useInventoryByActionplanIdQuery } from "../../services/Inventory"
import { useInventoryInputByActionplanIdQuery } from "../../services/InventoryInput"
import { ActionPlanDashboardProps } from "./ActionPlanDashboard.interfaces"

export const ActionPlanDashboard: FC<ActionPlanDashboardProps> = (props) => {
  const { className } = props
  // const componentRef = useRef(null)

  // const handlePrint = useReactToPrint({ content: () => componentRef.current })
  const navigator = useNavigate()
  const [editMode, setEditMode] = useState(false)
  const { id, tab = "" } = useParams<
    RoutePram & { tab: "" | "detail" | "collaborator" | "inventory" | "inputs" | "wasteandcircularity" }
  >()

  const inventoryInputByActionplanId = useInventoryInputByActionplanIdQuery({ actionplanId: Number(id) }, { skip: !id })
  const actionplan = useActionplanQuery({ id: Number(id) }, { skip: !id })
  const inventoryByActionplanId = useInventoryByActionplanIdQuery({ actionplanId: Number(id) })

  if (inventoryByActionplanId.isLoading || actionplan.isLoading) return <Loader />
  // todo: improve this check by using role
  if (inventoryByActionplanId.isError || (actionplan.isError && inventoryInputByActionplanId.data))
    return <Navigate to={generateRoute(URL_ACTIONPLAN_INVENTORY, { id: id })} />

  if (actionplan.isError || !id) return <Message error={actionplan.error} />

  return (
    <div className={className}>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Link className="mr-2" to={generateRoute(URL_ACTIONPLAN_LIST)}>
            <IconButton className="rounded-full bg-primary text-white">
              <ArrowBack />
            </IconButton>
          </Link>
          <div>
            <div className="before-seperator flex items-center space-x-2">
              <div className="text-xl font-semibold">{actionplan.data?.title}</div>
              <div>{STATUSOPTIONS.find((item) => item.value === actionplan.data?.actionItems[0].status)?.text}</div>
              {/* <Chip
                color={
                  STATUSOPTIONS.find((item) => item.value === actionplan.data?.status)?.text === "Completed"
                    ? "success"
                    : STATUSOPTIONS.find((item) => item.value === actionplan.data?.status)?.text === "Started"
                    ? "primary"
                    : undefined
                }
                size="small"
                label={STATUSOPTIONS.find((item) => item.value === actionplan.data?.status)?.text}
              /> */}
            </div>
            <div className="before-seperator flex">
              {/* <div>{STATUSOPTIONS.find((item) => item.value === actionplan.data?.status)?.text}</div> */}
              {/* <div>{actionplan.data?.description}</div> */}
            </div>
          </div>
        </div>

        {/* {role?.type === "admin" && (
          <Link to={generateRoute(URL_PRODUCT_ADD)}>
            <Button variant="contained">Add New product</Button>
          </Link>
        )} */}
      </div>

      {/* <ActionPanel
        rightElement={
          <>
            <Link to={generateRoute(URL_ACTIONPLAN_DETAIL, { id: id })}>
              <Button>Details</Button>
            </Link>
            <Link to={generateRoute(URL_ACTIONPLAN_COLLABORATOR, { id: id })}>
              <Button>Collaborator</Button>
            </Link>
            <Link to={generateRoute(URL_ACTIONPLAN_INVENTORY, { id: id })}>
              <Button>Inventory </Button>
            </Link>
            <Button onClick={handlePrint}>Export</Button>
          </>
        }
      /> */}

      <Tabs
        value={tab}
        onChange={(_, tab) => navigator(generateRoute(`${URL_ACTIONPLAN_DASHBOARD}/${tab}`, { id: id?.toString() }))}
      >
        <Tab value="" label="Analytics" />
        <Tab value="detail" label="Details" />
        <Tab value="inventory" label="Inventory" />
        <Tab value="inputs" label="Inventory Input" />
        <Tab value="collaborator" label="Collaborators" />
        <Tab value="wasteandcircularity" label="Waste & Circularity" />

        {tab === "detail" && (
          <TabExtra>
            <Button variant="contained" onClick={() => setEditMode(!editMode)}>
              {editMode ? "Cancel" : "Edit"}
            </Button>
          </TabExtra>
        )}
      </Tabs>

      <div className="flex flex-grow flex-col">
        {tab === "" && <ActionPlanAnalytics className="mt-4" />}
        {tab === "detail" && <ActionPlanDetail editMode={editMode} className="mt-4" />}
        {tab === "inventory" && <ActionPlanInventoryDetail className="mt-4" />}
        {tab === "inputs" && <ActionPlanInventoryInputs className="mt-4" />}
        {tab === "collaborator" && <ActionPlanCollaborator className="mt-4" />}
        {tab === "wasteandcircularity" && <ActionPlanWasteAndCircularity className="mt-4" />}
      </div>
    </div>
  )
}
