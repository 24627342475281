import { Configuration, PublicClientApplication, RedirectRequest } from "@azure/msal-browser"

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    knownAuthorities: [process.env.REACT_AUTH_AUTHORITY || "", process.env.REACT_APP_PASSWORD_AUTHORITY || ""],
    redirectUri: process.env.REACT_APP_REDIRECT_URI || "",
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI || "",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
}

export const loginRequest: RedirectRequest = {
  scopes: [],
}

export const passwordResetRequest: RedirectRequest = {
  authority: process.env.REACT_APP_PASSWORD_AUTHORITY || "",
  scopes: [],
}

export const MsalInstance = new PublicClientApplication(msalConfig)
