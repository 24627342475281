import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { FC, forwardRef } from "react"
import { useForm } from "react-hook-form"
import { FileUploader } from "../FileUploader"
import { FormField } from "../FormField"
import { FormAnswer, QuestionProps } from "./Question.interface"

const initial: FormAnswer = {
  optionId: -1,
  attachmentUrls: [],
}

export const Question: FC<QuestionProps> = forwardRef((props, ref) => {
  const { className, question, value: defaultValue = initial, error, onChange, disabled, index } = props
  const { label, description, Options } = question

  const form = useForm<FormAnswer>({
    defaultValues: defaultValue,
  })

  return (
    <div className={className}>
      <div className="text-xl font-bold">
        {index && `${index}.`} {label}
      </div>
      <div>{description}</div>
      <div className="my-4">
        <FormField
          name="optionId"
          control={form.control}
          onChange={(value) => onChange?.({ ...form.getValues(), optionId: value })}
        >
          <RadioGroup className="space-y-1" ref={ref}>
            {Options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={<Radio disabled={disabled} className="py-0" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormField>
        <div
          className={`h-4 text-red-600 transition-[height] ${
            (form.formState.errors.attachmentUrls || form.formState.errors.optionId || error)?.message ? "mt-1" : "h-0"
          }`}
        >
          <span>{(form.formState.errors.attachmentUrls || form.formState.errors.optionId || error)?.message}</span>
        </div>
      </div>

      <div>
        <div className="mb-2">Add supporting documents (optional)</div>
        <FormField
          control={form.control}
          name="attachmentUrls"
          onChange={(value) => onChange?.({ ...form.getValues(), attachmentUrls: value })}
        >
          {(props) => (
            <FileUploader
              {...props}
              disabled={disabled}
              placeholder={
                <>
                  <i className="fa fa-plus mr-2" />
                  Add supporting documents
                </>
              }
            />
          )}
        </FormField>
      </div>
    </div>
  )
})
