import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { RoutePram } from "../../router/routes"
import { Product, useProductQuery } from "../../services/Products"
import { ProductDetailProps } from "./ProductDetail.interface"

export const ProductDetail: FC<ProductDetailProps> = (props) => {
  const { className } = props
  const pram = useParams<RoutePram>()

  const response = useProductQuery({ id: Number(pram.id) })

  if (response.isLoading) return <Loader />
  if (response.isError) return <Message error={response.error} />

  return (
    <div className={className}>
      <TableContainer className="table-condence">
        <CreateTable product={response.data!} />
      </TableContainer>
    </div>
  )
}

const CreateTable = (props: { product: Product; depth?: number }) => {
  const { product, depth = 0 } = props
  const [open, setOpen] = useState(depth === 0)

  return (
    <>
      {depth !== 0 && (
        <Table>
          <TableRow className="w-full">
            <TableCell className="w-2/12 border-transparent p-[9px] font-bold">
              <IconButton size="small" className="" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
              Substance
            </TableCell>
            <TableCell colSpan={3} className={!open ? "border-transparent" : ""}>
              {product?.substance}
            </TableCell>
          </TableRow>
        </Table>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table className={`h-full ${depth !== 0 ? `ml-[16.666667%] w-10/12` : ""}`}>
          <TableBody>
            <TableRow>
              <TableCell className="font-bold">
                {!!product.SubProduct?.length && depth !== 0 && (
                  <IconButton size="small" className="" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                )}
                NAME
              </TableCell>
              <TableCell>{product?.name}</TableCell>

              <TableCell className="border-l font-bold">SUBSTANCE</TableCell>
              <TableCell>{product?.substance}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="font-bold">SKU</TableCell>
              <TableCell>{product?.SKU}</TableCell>

              <TableCell className="border-l font-bold">CAS</TableCell>
              <TableCell>{product?.CAS}</TableCell>
            </TableRow>

            {(product?.classification || product?.reportingThreshold) && (
              <TableRow>
                <TableCell className="font-bold">CLASSIFICATION</TableCell>
                <TableCell>{product?.classification}</TableCell>

                <TableCell className="border-l font-bold">Reporting Threshold</TableCell>
                <TableCell>{product?.reportingThreshold}</TableCell>
              </TableRow>
            )}

            {(product?.firstAdded || product?.lastRevised) && (
              <TableRow>
                <TableCell className="font-bold">First Added</TableCell>
                <TableCell>
                  {!!product?.firstAdded && new Date(Number(product?.firstAdded)).toLocaleDateString()}
                </TableCell>

                <TableCell className="border-l font-bold">Last Revised</TableCell>
                <TableCell>
                  {!!product?.lastRevised && new Date(Number(product?.lastRevised)).toLocaleDateString()}
                </TableCell>
              </TableRow>
            )}

            {product?.exmaple && (
              <TableRow>
                <TableCell className="font-bold">Exmaple</TableCell>
                <TableCell colSpan={3}>{product?.exmaple}</TableCell>
              </TableRow>
            )}

            {!!product?.reason.length && (
              <TableRow>
                <TableCell className="font-bold">Reasons</TableCell>
                <TableCell colSpan={3}>{product?.reason.join(", ")}</TableCell>
              </TableRow>
            )}

            {!!product?.SubProduct?.length && depth === 0 && (
              <TableRow>
                <TableCell colSpan={4} className="font-bold">
                  Bill of materials
                </TableCell>
              </TableRow>
            )}
            {product?.SubProduct?.map((product) => (
              <>
                <TableRow key={product.id}>
                  <TableCell className="p-0" colSpan={4}>
                    <CreateTable product={product} depth={depth + 1} />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </>
  )
}
