import { ArrowBack } from "@mui/icons-material"
import { Button, Card, Chip, IconButton, MenuItem } from "@mui/material"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { Dropdown } from "../../components/Dropdown"
import { Column } from "../../components/EditTable/EditTable.interfaces"
import { Table } from "../../components/Table"
import { TableProps } from "../../components/Table/Table.interface"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { useAppSelector } from "../../redux/store/hooks"
import { generateRoute, URL_DASHBOARD, URL_PRODUCT_DASHBOARD, URL_PRODUCT_NEW } from "../../router/routes"
import { Product, useDeleteProductByIdMutation, useListProductQuery } from "../../services/Products"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ProductListProps } from "./ProductList.interfaces"

export const ProductList: React.FC<ProductListProps> = (props) => {
  const { className } = props
  const products = useListProductQuery({ name: getOrganizationNameFromRoute() })
  const role = useAppSelector((store) => store.auth.role)
  const [deleteProductById] = useDeleteProductByIdMutation()

  if (products.isLoading) return <Loader />
  if (products.isError) return <Message error={products.error} />

  const columns: Column<Product & { id: number }>[] = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
      renderCell: ({ value, row }) => (
        <Link className="font-bold text-primary" to={generateRoute(URL_PRODUCT_DASHBOARD, { id: row.id.toString() })}>
          {value || "Open Detail"}
        </Link>
      ),
    },
    {
      flex: 1,
      field: "SKU",
      headerName: "SKU",
      renderCell: ({ value }) => value && <Chip className="rounded" size="small" label={value} />,
    },
    { flex: 1, field: "substance", headerName: "Substance" },
    { flex: 1, field: "CAS", headerName: "CAS" },
    // todo: color pills for ui
    { flex: 1, field: "classification", headerName: "Classification" },
    { flex: 1, field: "reason", headerName: "Reason", sortable: false, renderCell: ({ value }) => value.join(", ") },
  ]

  const actionButtons: TableProps<Product>["actionButtons"] = {
    width: 55,
    renderCell: ({ row }) => (
      <Dropdown>
        <MenuItem
          onClick={() =>
            toast.promise(deleteProductById({ id: row.id }).unwrap(), {
              error: (error) => error?.message ?? "Something went wrong",
              loading: "Please Wait",
              success: "Successfully removed",
            })
          }
        >
          Delete
        </MenuItem>
      </Dropdown>
    ),
  }
  return (
    <div className={className}>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Link className="mr-2" to={generateRoute(URL_DASHBOARD)}>
            <IconButton className="rounded-full bg-primary text-white">
              <ArrowBack />
            </IconButton>
          </Link>
          <div className="text-xl font-semibold">Product Compliance</div>
        </div>

        {role?.type === "admin" && (
          <Link to={generateRoute(URL_PRODUCT_NEW)}>
            <Button variant="contained">Add New product</Button>
          </Link>
        )}
      </div>

      {products.data ? (
        <>
          <div className="mb-3 font-bold text-body">All Products</div>
          <Card>
            <Table<Product> columns={columns} data={products.data} actionButtons={actionButtons} />
          </Card>
        </>
      ) : (
        <div className="flex h-full flex-grow flex-col justify-between">
          <Message
            title="No Product Compliance"
            description="Click Add New Product above to start your journey today."
          />
        </div>
      )}
    </div>
  )
}
