import { RemoveCircleOutline } from "@mui/icons-material"
import { Button, Checkbox, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { FC } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { EOL, UNITS } from "../../helpers/contants"
import { generateRoute, RoutePram, URL_ACTIONPLAN_DASHBOARD } from "../../router/routes"
import { useCreateWastageMutation } from "../../services/ActionPlan"
import { FormField } from "../FormField"
import {
  ActionPlanWasteAndCircularityFormProps,
  ActionPlanWasteAndCircularityProps,
  FormState,
} from "./ActionPlanWasteAndCircularity.interface"

export const ActionPlanWasteAndCircularity: FC<ActionPlanWasteAndCircularityProps> = (props) => {
  const { className } = props
  const navigate = useNavigate()
  const { id } = useParams<RoutePram>()

  const [createWastage] = useCreateWastageMutation()

  const onSubmit = async (values: FormState) => {
    const promises = values.data.map((data) =>
      createWastage({ ...data, date: values.date, actionPlanId: Number(id) }).unwrap()
    )

    await toast
      .promise(Promise.all(promises), {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully Created",
      })
      .then(() => navigate(generateRoute(URL_ACTIONPLAN_DASHBOARD, { id: id, tab: "" })))
  }

  return (
    <div className={className}>
      <ActionPlanWasteAndCircularityForm onSubmit={onSubmit} />
    </div>
  )
}

export const ActionPlanWasteAndCircularityForm: FC<ActionPlanWasteAndCircularityFormProps> = (props) => {
  const { className, defaultValue, onSubmit } = props

  const form = useForm<FormState>({
    defaultValues: defaultValue ?? {
      date: new Date().toString(),
      data: [
        {
          dfa: false,
          dfd: false,
          eolProcess: "",
          material: "",
          quantityRecovered: undefined,
          recycledYield: undefined,
          resource: "",
          unit: "",
          wastage: undefined,
          date: "",
        },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({ control: form.control, name: "data" } as any)

  return (
    <form className={className} onSubmit={form.handleSubmit((value) => onSubmit?.(value))}>
      <FormField
        keepErrorSpace
        className="mb-4"
        label="Date"
        control={form.control}
        name="date"
        rules={{ required: { message: "value is required", value: true } }}
      >
        {(props) => (
          <DatePicker
            showDaysOutsideCurrentMonth
            renderInput={({ ...params }) => <TextField size="small" {...params} />}
            {...props}
          />
        )}
      </FormField>

      <div>
        {fields.map((field, index) => (
          <div key={field.id} className="relative my-3 flex flex-wrap items-end">
            <FormField
              keepErrorSpace
              className="mr-4"
              label="Material"
              control={form.control}
              name={`data.${index}.material`}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <TextField fullWidth size="small" variant="outlined" />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Resource"
              control={form.control}
              name={`data.${index}.resource`}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <TextField fullWidth size="small" variant="outlined" />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Quantity Recovered"
              control={form.control}
              transform={(value) => Number(value)}
              name={`data.${index}.quantityRecovered`}
              rules={{
                required: { message: "value is required", value: true },
                validate: (value) => value !== 0 || "value is required",
              }}
            >
              <TextField className="w-36 min-w-0" size="small" variant="outlined" type="number" />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Unit"
              control={form.control}
              name={`data.${index}.unit`}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <Select size="small" variant="outlined" className="w-[193px]">
                {UNITS.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Recycled Yield"
              control={form.control}
              transform={(value) => Number(value)}
              name={`data.${index}.recycledYield`}
              rules={{
                required: { message: "value is required", value: true },
                validate: (value) => !!value || "value is required",
              }}
            >
              <TextField className="w-28 min-w-0" size="small" variant="outlined" type="number" />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Wastage"
              control={form.control}
              transform={(value) => Number(value)}
              name={`data.${index}.wastage`}
              rules={{
                required: { message: "value is required", value: true },
                validate: (value) => !!value || "value is required",
              }}
            >
              <TextField className="w-24 min-w-0" size="small" variant="outlined" type="number" />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Eol Process"
              control={form.control}
              name={`data.${index}.eolProcess`}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <Select size="small" variant="outlined" className="w-[193px]">
                {EOL.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormField>

            <FormField
              keepErrorSpace
              className="text-center"
              label="DFD"
              control={form.control}
              name={`data.${index}.dfd`}
            >
              <Checkbox className="-mb-2" sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
            </FormField>

            <FormField
              keepErrorSpace
              className="text-center"
              label="DFA"
              control={form.control}
              name={`data.${index}.dfa`}
            >
              <Checkbox className="-mb-2" sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
            </FormField>

            {fields.length > 1 && (
              <IconButton className="mb-4" type="button" onClick={() => remove(index)}>
                <RemoveCircleOutline />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      <div>
        <Button
          type="button"
          className="mt-4"
          variant="contained"
          onClick={() =>
            append({
              material: "",
              resource: "",
              quantityRecovered: 0,
              unit: "",
              recycledYield: 0,
              wastage: 0,
              eolProcess: "",
              dfd: false,
              dfa: false,
            })
          }
        >
          Add
        </Button>
      </div>

      <div className="text-right">
        <Button type="submit" variant="contained">
          {defaultValue ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  )
}
