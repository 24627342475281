import { gql } from "graphql-request"

export const PermissionAll = gql`
  query PermissionAll {
    permissionAll {
      id
      type
      Resource {
        name
        id
      }
      roleId
      resourceId
    }
  }
`
