import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { FC } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { FormField } from "../../../components/FormField"
import { useAppSelector } from "../../../redux/store/hooks"
import { generateRoute, URL_DASHBOARD, URL_HOME } from "../../../router/routes"
import { useCreateOrganizationMutation } from "../../../services/Organization"
import { CreateOrganizationProps, FormState } from "./CreateOrganization.interfaces"

const industry = [
  { text: "Manufacturing", value: "Manufacturing" },
  { text: "Transportation", value: "Transportation" },
  { text: "Technology", value: "Technology" },
  { text: "Agriculture", value: "Agriculture" },
  { text: "Healthcare", value: "Healthcare" },
  { text: "Waste Management", value: "Waste" },
  { text: "Production", value: "Production" },
  { text: "Mining", value: "Mining" },
  { text: "Entertainment", value: "Entertainment" },
  { text: "Distribution", value: "Distribution" },
  { text: "Construction", value: "Construction" },
  { text: "Food", value: "Food" },
  { text: "Telecommunications", value: "Telecommunications" },
  { text: "Government Services", value: "Government" },
  { text: "Retail", value: "Retail" },
  { text: "Real Estate", value: "Real" },
  { text: "Insurance", value: "Insurance" },
  { text: "Other", value: "Other" },
  { text: "Finance", value: "Finance" },
  { text: "Robotics", value: "Robotics" },
  { text: "Hospitality", value: "Hospitality" },
]
export const CreateOrganization: FC<CreateOrganizationProps> = () => {
  const user = useAppSelector((store) => store.auth.user)
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<FormState>({
    defaultValues: {
      actionPlanSubscriptionId: "",
      businessName: "",
      defaultRoleId: undefined,
      domains: "",
      id: undefined,
      industry: "",
      name: "",
      stripeCustomerId: "",
      templateId: undefined,
    },
  })

  const [CreateOrganization] = useCreateOrganizationMutation()

  const onSubmit = async (value: FormState) => {
    toast
      .promise(
        CreateOrganization({
          name: value.name,
          businessName: value.businessName,
          industry: value.industry,
          domains: value.domains.toLowerCase().split(","),
          uid: user?.id,
        }).unwrap(),
        {
          error: (error) => error?.message ?? "Something went wrong",
          loading: "Creating",
          success: "Create Organization Successfully",
        }
      )
      .then(() => navigate(generateRoute(URL_DASHBOARD, { orgName: value.name })))
  }

  // todo: fix padding
  return (
    <div className="flex w-full flex-grow">
      <div className="flex w-full flex-wrap">
        <div className="relative flex justify-center px-2 sm:w-1/2">
          <Link to={URL_HOME}>
            <img
              className="mw-15rem absolute left-0 top-0 ml-5 mt-5"
              src="/assets/logo.svg"
              alt="logo"
              style={{ height: "50px" }}
            />
          </Link>
          <div style={{ maxWidth: "30rem" }}>
            <img className="h-full w-full" src="/assets/login.svg" alt="login" />
          </div>
        </div>
        <div className="relative flex flex-col justify-center bg-white px-2 sm:w-1/2">
          <Link to={URL_HOME} className="absolute left-0 top-0 mt-5" style={{ marginLeft: "5%" }}>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div className="mx-auto w-3/4">
            <div className="mb-4">
              <h4 className="text-4xl font-bold">Create Organization</h4>
              <span className="text-muted">Fill out the information below to create your organization.</span>
            </div>
            <div>
              <form onSubmit={handleSubmit((formState) => onSubmit(formState))}>
                <FormField
                  label="Username"
                  name="name"
                  control={control}
                  rules={{
                    required: { message: "value is required", value: true },
                    pattern: { value: /^[A-Za-z1-9]+$/g, message: "Should only contain Alphabet and number" },
                    validate: (value) => !String(value).includes(" ") || "Name should not contain space",
                  }}
                >
                  <TextField size="small" variant="outlined" className="w-full" />
                </FormField>
                <FormField
                  className="mt-3"
                  label="Business Name"
                  name="businessName"
                  control={control}
                  rules={{ required: { message: "value is required", value: true } }}
                >
                  <TextField size="small" variant="outlined" className="w-full" />
                </FormField>
                <FormField
                  className="mt-3"
                  control={control}
                  name="industry"
                  label="Industry"
                  rules={{
                    required: { message: "Value is Required", value: true },
                  }}
                >
                  <Select size="small" variant="outlined" className="w-full">
                    {industry.map(({ text, value }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormField>

                <FormField
                  control={control}
                  className="mt-3"
                  name="domains"
                  label="Domains"
                  infoText='Seperate each domain by ","'
                  rules={{
                    required: { message: "Value is Required", value: true },
                  }}
                >
                  <TextField size="small" variant="outlined" className="w-full" multiline rows={2} maxRows={4} />
                </FormField>
                <Button variant="contained" size="large" fullWidth type="submit" className="mt-3">
                  Create
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
