import { FormState } from "../../containers/ActionPlanInventoryDetail/ActionPlanInventoryDetail.interfaces"
import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { ResourceEnum } from "../Permission"
import { CreateInventory, DeleteInventoryById, InventoryByActionplanId } from "./Inventory.documents"
import { Inventory } from "./Inventory.interfaces"

export const inventoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    inventoryByActionplanId: builder.query<Inventory[], { actionplanId: number }>({
      query: (variables) => ({ document: InventoryByActionplanId, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Inventory, operation: PermissionEnum.Read } },
      providesTags: ["ActionPlan"],
    }),
    deleteInventory: builder.mutation<Inventory[], { id: number }>({
      query: (variables) => ({ document: DeleteInventoryById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Inventory, operation: PermissionEnum.Delete } },
      invalidatesTags: ["ActionPlan"],
    }),
    createInventory: builder.mutation<{ id: number }, FormState["data"][0]>({
      query: (variables) => ({ document: CreateInventory, variables }),
      invalidatesTags: ["ActionPlan"],
      extraOptions: { headers: { resource: ResourceEnum.Inventory, operation: PermissionEnum.Create } },
    }),
  }),
})

export const {
  useInventoryByActionplanIdQuery,
  useLazyInventoryByActionplanIdQuery,
  useDeleteInventoryMutation,
  useCreateInventoryMutation,
} = inventoryApi
