import { FC, useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import ReactSelect from "react-select/creatable"
import { ActionPanel } from "../../components/ActionPanel"
import { EditTable } from "../../components/EditTable"
import { Column, Datum } from "../../components/EditTable/EditTable.interfaces"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { PRICEID } from "../../helpers/contants"
import { useRemoveActionPlanUserMutation } from "../../services/ActionPlan"
import { useListRoleQuery } from "../../services/Role"
import { useSubscriptionQuery } from "../../services/Subscription"
import { useInviteUserMutation, useListUserQuery, useUpdateUserMutation } from "../../services/User"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { FormState, UserManagementProps } from "./UserManagement.interfaces"

export const UserManagement: FC<UserManagementProps> = () => {
  const userByOrganization = useListUserQuery()
  const roleByOrganization = useListRoleQuery()
  const retrieveSubscription = useSubscriptionQuery()
  const [inviteUser] = useInviteUserMutation()
  const [removeActionPlanUser] = useRemoveActionPlanUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const [data, setData] = useState<FormState[]>([])

  const onAdd = async ({ name, email, roleId }: Datum<FormState>) => {
    if (data.length > (actionPlanSubscription?.quantity ?? 0)) {
      setTimeout(() => setData((prev) => prev.slice(0, prev.length - 1)))
      return toast.error("Subscription seats Limit Reached\n Cannot add more Users")
    }

    toast.promise(inviteUser({ name, email, roleId }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Invited successfully",
    })
  }

  const onRemove = async ({ id, ...data }: Datum<FormState>) => {
    toast.promise(removeActionPlanUser({ id: id }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Successfully removed",
    })
  }

  const onUpdate = async ({ ActionPlans, Campaigns, Access, ...data }: Datum<FormState>) => {
    toast.promise(updateUser({ name: data.name, id: data.id, roleid: data.roleId }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Please Wait",
      success: "Successfully updated",
    })
  }

  useEffect(() => {
    if (!userByOrganization.isLoading && !userByOrganization.isError && userByOrganization.data)
      setData(userByOrganization.data)
  }, [userByOrganization.isLoading, userByOrganization.isError, userByOrganization.data])

  const actionPlanSubscription = useMemo(
    () =>
      retrieveSubscription.data?.items.data.find(
        (subscriptionItem) => subscriptionItem.price.id === PRICEID.actionPlan[process.env.NODE_ENV]
      ),
    [retrieveSubscription.data]
  )

  if (retrieveSubscription.isLoading || roleByOrganization.isLoading || userByOrganization.isLoading) return <Loader />
  if (roleByOrganization.isError || userByOrganization.isError)
    return <Message error={roleByOrganization.isError ?? userByOrganization.error} />

  const columns: Column<FormState>[] = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Email",
      field: "email",
      renderCell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
    {
      headerName: "Role",
      field: "roleId",
      renderEditCell: ({ value, row }) => {
        // Fetch Role from access of users for Current organization
        const organizationName = getOrganizationNameFromRoute()!
        const roleid = row.Access?.find((access) => access.organizationName === organizationName)?.Role.id

        const role = roleByOrganization.data?.find((role) => role.id === (value ?? roleid))

        return (
          <ReactSelect
            components={{
              IndicatorSeparator: () => <></>,
            }}
            defaultValue={{ label: role?.type, value: role?.id }}
            // onChange={(option) => {
            //   const newValue = editRow(data, row._id!, { roleId: option?.value })
            //   setData(newValue)
            // }}
            options={roleByOrganization.data?.map(({ type, id }) => ({ label: type ?? "", value: id })) ?? []}
          />
        )
      },
      renderCell: ({ value, row }) => {
        // Fetch Role from access of users for Current organization
        const organizationName = getOrganizationNameFromRoute()!
        const roleid = row.Access?.find((access) => access.organizationName === organizationName)?.Role.id

        const role = roleByOrganization.data?.find((role) => role.id === (value ?? roleid))

        return <div className="text-truncate">{role?.type ?? "No Access"}</div>
      },
    },
    {
      headerName: "status",
      field: "status",
      ignoreValidation: true,
      renderCell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
  ]

  return (
    <div>
      <ActionPanel />
      <div className="rounded-lg bg-white shadow-sm">
        <EditTable<FormState>
          columns={columns}
          canAdd={{
            value: !retrieveSubscription.isError && data.length <= (actionPlanSubscription?.quantity ?? 0),
            message: "You dont have any subscription to add User",
          }}
          data={data}
          // onChange={(CollaboratorList) => setData(CollaboratorList)}
          onAdd={onAdd}
          onRemove={onRemove}
          onUpdate={onUpdate}
          addnewtext="Add New User"
        />
      </div>
    </div>
  )
}
