import { ArrowBack } from "@mui/icons-material"
import { Button, IconButton, Tab, Tabs } from "@mui/material"
import { FC, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { generateRoute, RoutePram, URL_PRODUCT_DASHBOARD, URL_PRODUCT_LIST } from "../../router/routes"
import { Product, useProductQuery, useUpdateProductMutation } from "../../services/Products"
import { ProductDetail } from "../ProductDetail"
import { ProductForm } from "../ProductForm"
import { FormState } from "../ProductForm/ProductForm.interface"
import { TabExtra } from "../TabExtra"

import { ProductDashboardProps } from "./ProductDashboard.interface"

export const ProductDashboard: FC<ProductDashboardProps> = (props) => {
  const { className } = props

  const { id, tab = "" } = useParams<RoutePram & { tab: "" | "detail" | "collaborator" | "inventory" | "inputs" }>()
  const navigator = useNavigate()

  const product = useProductQuery({ id: Number(id) }, { skip: !id })
  const [updateProduct] = useUpdateProductMutation()

  const [editMode, setEditMode] = useState(false)

  if (product.isLoading) return <Loader />
  if (product.isError) return <Message error={product.error} />

  const onSubmit = (value: Product | FormState) => {
    toast
      .promise(updateProduct(value as Product).unwrap(), {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully updated",
      })
      .then(() => setEditMode(false))
  }

  return (
    <div className={className}>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Link className="mr-2" to={generateRoute(URL_PRODUCT_LIST)}>
            <IconButton className="rounded-full bg-primary text-white">
              <ArrowBack />
            </IconButton>
          </Link>
          <div>
            <div className="before-seperator flex items-center space-x-2">
              <div className="text-xl font-semibold">{product.data?.name ?? <>&nbsp;</>}</div>
              {!!product.data?.SKU && <div>{product.data?.SKU}</div>}
            </div>
            <div className="before-seperator flex">
              {!!product.data?.substance && <div>{product.data?.substance}</div>}
              {!!product.data?.classification && <div>{product.data?.classification}</div>}
            </div>
          </div>
        </div>
      </div>

      <Tabs
        value={tab}
        onChange={(_, tab) => navigator(generateRoute(`${URL_PRODUCT_DASHBOARD}/${tab}`, { id: id?.toString() }))}
      >
        <Tab value="" label="Details" />
        {tab === "" && (
          <TabExtra>
            <Button variant="contained" onClick={() => setEditMode(!editMode)}>
              {editMode ? "Cancel" : "Edit"}
            </Button>
          </TabExtra>
        )}
      </Tabs>

      <div className="flex flex-grow flex-col">
        {tab === "" &&
          (editMode ? (
            <ProductForm className="mt-4" defaultValue={product.data} onSubmit={onSubmit} />
          ) : (
            <ProductDetail className="mt-4" />
          ))}
      </div>
    </div>
  )
}
