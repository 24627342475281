import { gql } from "graphql-request"

export const GetEmissions = gql`
  query GetEmissions($source: String) {
    getEmissions(source: $source) {
      id
      source
      unit
    }
  }
`
