import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { Dictionary } from "lodash"
import { MutableRefObject } from "react"
import { TableProps } from "../Table/Table.interface"

export type Datum<T = any> = T & {
  _isNew?: boolean
}

export interface EditTableProps<T extends Dictionary<any>> extends Omit<TableProps, "columns"> {
  columns: Column<T>[]
  data?: T[]
  newRecord?: Partial<T>
  onAdd?: (row: T, data: T[]) => Promise<any>
  onUpdate?: (row: T, data: T[]) => Promise<any>
  onRemove?: (row: T, data: T[]) => Promise<any>
  canAdd?: { value: boolean; message?: string }
  message?: string
  addnewtext?: string
}

export const ActionKey = "_action"

export type Column<T extends Record<string, any>> = Omit<GridColDef<Datum<T>>, "renderEditCell"> & {
  field: keyof T | ""
  ignoreValidation?: ((row: T) => boolean) | boolean
  renderEditCell?: (
    params: Omit<GridRenderEditCellParams<any, T, any>, "api"> & {
      api: MutableRefObject<GridApiPro>["current"]
      row: T
    }
  ) => React.ReactNode
}
