import { gql } from "graphql-request"

export const ActionplanById = gql`
  query ActionplanById($id: Int!) {
    actionplanById(id: $id) {
      id
      title
      sustainabilityType
      benefits
      coBenefits
      terms
      sector
      scope
      actionChapter
      cost
      InventoryInputs {
        id
        name
      }
      Collaborators {
        adId
        id
        name
        email
        status
        Access {
          id
          organizationName
          Role {
            id
            type
          }
        }
      }
      leadId
      actionItems {
        description
        status
      }
    }
  }
`

export const CreateActionPlan = gql`
  mutation CreateActionPlan(
    $title: String!
    $sustainabilityType: String
    $benefits: String
    $coBenefits: [String]
    $terms: String
    $sector: String
    $scope: String
    $actionChapter: String
    $cost: String
    $organizationName: String!
    $actionItems: [ActionItems!]!
    $leadId: String
  ) {
    createActionPlan(
      title: $title
      actionItems: $actionItems
      sustainabilityType: $sustainabilityType
      benefits: $benefits
      coBenefits: $coBenefits
      terms: $terms
      sector: $sector
      scope: $scope
      actionChapter: $actionChapter
      cost: $cost
      organizationName: $organizationName
      leadId: $leadId
    ) {
      id
    }
  }
`

export const UpdateActionPlanById = gql`
  mutation UpdateActionPlanById(
    $id: Int!
    $title: String
    $actionItems: [ActionItems]
    $sustainabilityType: String
    $benefits: String
    $coBenefits: [String]
    $terms: String
    $sector: String
    $scope: String
    $actionChapter: String
    $cost: String
    $leadId: String
  ) {
    updateActionPlanById(
      id: $id
      title: $title
      actionItems: $actionItems
      sustainabilityType: $sustainabilityType
      benefits: $benefits
      coBenefits: $coBenefits
      terms: $terms
      sector: $sector
      scope: $scope
      actionChapter: $actionChapter
      cost: $cost
      leadId: $leadId
    ) {
      id
    }
  }
`

export const ActionplanByOrganization = gql`
  query ActionplanByOrganization($name: String!, $uid: Int!) {
    actionplanByOrganization(name: $name, uid: $uid) {
      id
      title
      scope
      sustainabilityType
      actionItems {
        description
        status
      }
    }
  }
`

export const RemoveActionPlanUser = gql`
  mutation RemoveActionPlanUser($id: Int!, $userId: Int!) {
    removeActionPlanUser(id: $id, userId: $userId) {
      id
    }
  }
`

export const DeleteActionPlanById = gql`
  mutation DeleteActionPlanById($id: Int!) {
    deleteActionPlanById(id: $id) {
      id
    }
  }
`

export const createWastage = gql`
  mutation CreateWastage($data: WastageInput) {
    createWastage(data: $data) {
      id
      material
      resource
      quantityRecovered
      unit
      recycledYield
      wastage
      eolProcess
      dfd
      dfa
    }
  }
`

export const wastageByActionPlanId = gql`
  query WastageByActionPlanId($actionPlanId: Int!) {
    wastageByActionPlanId(id: $actionPlanId) {
      id
      material
      resource
      quantityRecovered
      unit
      recycledYield
      wastage
      eolProcess
      dfd
      dfa
    }
  }
`
