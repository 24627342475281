import { List, ListItemButton } from "@mui/material"
import { FC } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import { generateRoute, URL_SETTING_PROFILE } from "../../router/routes"
import { SettingProps } from "./Setting.interfaces"

export const Setting: FC<SettingProps> = (props) => {
  const { className } = props

  const location = useLocation()

  return (
    <div className={className}>
      <div className="flex w-full flex-wrap">
        <div className="w-60 px-2">
          <div className="scrollbar rounded-lg bg-white shadow-sm">
            <List>
              <Link to={generateRoute(URL_SETTING_PROFILE)} className="stretched-link text-body">
                <ListItemButton
                  selected={generateRoute(URL_SETTING_PROFILE) === location.pathname}
                  className="relative w-full py-3"
                  sx={{
                    "&.Mui-selected": {
                      borderLeft: "5px solid #ea580c",
                    },
                  }}
                >
                  Profile
                </ListItemButton>
              </Link>
            </List>
          </div>
        </div>
        <div className="flex-1 px-2">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
