import { useMsal } from "@azure/msal-react"
import { ChevronRight, Logout, Person } from "@mui/icons-material"
import { Button } from "@mui/material"
import { FC } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { AuditConnectModal } from "../../components/AuditConnectModal"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { setOrganization } from "../../redux/slice"
import { useAppSelector } from "../../redux/store/hooks"
import { generateRoute, URL_DASHBOARD, URL_HOME, URL_ORGANIZATION_NEW, URL_SETTING } from "../../router/routes"
import { Organization, useListOrganizationQuery } from "../../services/Organization"
import { OrganizationListProps } from "./OrganizationList.interfaces"

export const OrganizationList: FC<OrganizationListProps> = (props) => {
  const { className } = props
  const user = useAppSelector((store) => store.auth.user)
  const invite = useAppSelector((store) => store.auth.invite)
  const { instance } = useMsal()
  const dispatch = useDispatch()

  const organizationByUserid = useListOrganizationQuery({ id: user?.id! }, { skip: !user?.id })

  if (organizationByUserid.isLoading) return <Loader />
  if (organizationByUserid.isError) return <Message error={organizationByUserid.error} />

  return (
    <div className={className}>
      {invite && <AuditConnectModal invite={invite} />}

      <div className="relative flex flex-col justify-between p-5 sm:w-6/12 md:p-20">
        <div>
          <Link to={URL_HOME}>
            <img src="/assets/logo.svg" className="mr-4 max-w-[15rem]" alt="logo" />
          </Link>
          <h1 className="mt-10 text-4xl font-semibold">Hello, {user?.name}</h1>
        </div>
        <div className="mb-5 text-muted">
          <p className="my-2 text-xl font-semibold text-black">Welcome to Cloudsyte</p>
          <p>
            Gauge the risk of existing and new suppliers. Achieve continuous monitoring of your supply chain and carbon
            emissions. Identify risk across financial, compliance, operational, governance, geographic and cyber
            factors.
          </p>
        </div>
        <div>
          <Link to={URL_SETTING} className="inline-flex">
            <Button className="flex items-center">
              <Person className="mr-4" />
              <div className="text-xl">Profile</div>
            </Button>
          </Link>
          <Button className="flex items-center" onClick={() => instance.logout()}>
            <Logout className="mr-4" />
            <div className="text-xl">Sign out</div>
          </Button>
          <div className="list-unstyled mt-5 flex items-center">
            <li className="mr-3">@ {new Date().getFullYear()} Cloudsyte, Inc</li>
            <Button className="border-left rounded-0 border-2 py-0">
              <a href="https://www.cloudsyte.com/privacy-policy">Terms</a>
            </Button>
            <Button className="border-left rounded-0 border-2 py-0">
              <a href="https://www.cloudsyte.com/privacy">Privacy</a>
            </Button>
          </div>
        </div>
      </div>
      <div className="relative p-5 sm:w-1/2 md:p-20">
        <h3 className="text-xl font-bold">Organizations</h3>
        <div className="mb-5 text-muted">Create an account for your organization in a few easy steps.</div>
        <div className="my-5">
          {organizationByUserid.data?.map((organization) => (
            <Link
              key={organization.id}
              onClick={() => dispatch(setOrganization(organization.name))}
              to={generateRoute(URL_DASHBOARD, { orgName: organization.name })}
            >
              <OrganizatoinCard organization={organization} />
            </Link>
          ))}
        </div>

        <Link to={generateRoute(URL_ORGANIZATION_NEW)}>
          <Button variant="contained" fullWidth>
            New organization
          </Button>
        </Link>
      </div>
    </div>
  )
}

export const OrganizatoinCard = (props: { organization: Organization } & React.HTMLAttributes<HTMLDivElement>) => {
  const { organization, ...rest } = props

  return (
    <div
      {...rest}
      className="my-3 flex justify-between rounded-lg border p-3 transition-shadow duration-200 ease-linear hover:shadow-md"
    >
      <div className="flex w-full flex-wrap items-center justify-between">
        <div className="flex-grow">
          <h5 className="font-bold">{organization.businessName}</h5>
          {organization.industry && <div className="text-muted">Industry : {organization.industry}</div>}
          {!!organization.domains.length && (
            <div className="text-muted">Domain : {organization.domains.join(", ")}</div>
          )}
        </div>
        <div className="w-auto">
          <ChevronRight className="flex items-center" />
        </div>
      </div>
    </div>
  )
}
