import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, IconButton, TextField } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { CheckoutContainer } from "../../components/CheckoutContainer"
import { FormField } from "../../components/FormField"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { PRICEID } from "../../helpers/contants"
import {
  generateRoute,
  URL_HOME,
  URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT,
  URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT,
} from "../../router/routes"
import { useCreateSubscriptionMutation, useSubscriptionQuery } from "../../services/Subscription"
import { SelectSubscriptionProps } from "./SelectSubscription.interfaces"

const planPrice = 200
const seatPricing = {
  0: 59,
  11: 49,
  51: 39,
}

export const SelectSubscription: FC<SelectSubscriptionProps> = () => {
  const [doPayment, setDoPayment] = useState(false)
  const query = new URLSearchParams(window.location.search)
  const status = query.get("redirect_status")

  const retrieveSubscription = useSubscriptionQuery()
  const [createSubscription] = useCreateSubscriptionMutation()

  const { control, handleSubmit, watch, formState, setValue } = useForm<{ seats: number }>({
    defaultValues: { seats: 1 },
    mode: "all",
  })

  const actionPlanSubscription = useMemo(
    () =>
      retrieveSubscription.data?.items.data.find(
        (subscriptionItem) => subscriptionItem.price.id === PRICEID.actionPlan[process.env.NODE_ENV]
      ),
    [retrieveSubscription.data]
  )

  useEffect(() => {
    if (actionPlanSubscription?.quantity) setValue("seats", actionPlanSubscription.quantity)
  }, [actionPlanSubscription?.quantity, setValue])

  const onSubmit = async () => {
    setDoPayment(true)
  }

  if (retrieveSubscription.isLoading) return <Loader />
  if (retrieveSubscription.isError) return <Message error={retrieveSubscription.error} />

  const seats = watch("seats")
  const price = Object.entries(seatPricing).find(
    (_, index, entries) => Number(entries[index + 1]?.[0]) > seats || index === entries.length - 1
  )?.[1]

  if (doPayment || status)
    return (
      <CheckoutContainer
        clientSecretFn={async () => {
          const paymentintent = await createSubscription({
            product: [
              ...(actionPlanSubscription
                ? []
                : [
                    {
                      id: PRICEID.actionPlanSeat[process.env.NODE_ENV],
                      quantity: 1,
                    },
                  ]),
              {
                id: PRICEID.actionPlan[process.env.NODE_ENV],
                quantity: Number(seats),
              },
            ],
          }).unwrap()

          if (paymentintent?.clientSecret && paymentintent?.status !== "active") return paymentintent?.clientSecret
        }}
        errorComponent={() => (
          <Message
            title="Payment Canceled"
            actionButton={
              <Link to={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}>
                <Button variant="contained">Retry</Button>
              </Link>
            }
          />
        )}
        redirect_url={generateRoute(URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT)}
        back_url={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}
      />
    )

  return (
    <div className="flex w-full flex-grow">
      <div className="flex w-full flex-wrap">
        <div className="relative flex flex-col justify-center bg-white px-2 sm:w-1/2">
          <Link
            to={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}
            className="absolute left-0 top-0 mt-5"
            style={{ marginLeft: "5%" }}
          >
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div className="mx-auto w-3/4">
            <div className="mb-4">
              <h4 className="text-4xl font-bold">Subscription</h4>
              <span className="text-muted">Select the number of seats for your subscription.</span>
              <div className="my-5">
                <div className="mb-2 flex items-center">
                  <div className="text-2xl">
                    ${planPrice}.00 <span className="font-light">/ month</span>
                  </div>
                  <div className="mx-3 text-2xl">+</div>
                  <div className="text-2xl">
                    ${price}.00 <span className="font-light">/ seat</span>
                  </div>
                  <div className="mx-3 text-2xl">*</div>
                  <div className="self-end text-2xl">{seats}</div>
                  <div className="mx-3 text-2xl">=</div>
                  <div className="text-2xl">
                    {planPrice + (seats > 1 ? seats : 1) * (price ?? 0)}.00$ <span className="font-light">/ month</span>
                  </div>
                </div>
                {actionPlanSubscription?.id && (
                  <small className="block font-bold">* We prorate subscription changes</small>
                )}
                <small className="block">* 1 to 10 users for $59.00 each</small>
                <small className="block">* 10 to 50 users for $49.00 each</small>
                <small className="block">* 50 users and above for $39.00 each</small>
              </div>
            </div>
            <div>
              <form className="flex flex-wrap space-y-3" onSubmit={handleSubmit(onSubmit)}>
                <FormField
                  label="Number of Users"
                  className="w-full"
                  name="seats"
                  control={control}
                  rules={{
                    required: { message: "value is required", value: true },
                    validate: (value) => Number(value) >= 1 || "Atleast 1 seat is required",
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    type="number"
                    fullWidth
                    inputProps={{
                      label: "Number of Users",
                      min: 1,
                      type: "number",
                      size: "lg",
                    }}
                  />
                </FormField>

                <div className="w-full">
                  <Button
                    disabled={!!Object.values(formState.errors).length}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    className="mt-4"
                  >
                    Proceed to Checkout
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center px-2 sm:w-1/2">
          <Link to={URL_HOME}>
            <img
              className="mw-15rem absolute left-0 top-0 ml-5 mt-5"
              src="/assets/logo.svg"
              alt="logo"
              style={{ height: "50px" }}
            />
          </Link>
          <div style={{ maxWidth: "30rem" }}>
            <img className="h-full w-full" src="/assets/login.svg" alt="login" />
          </div>
        </div>
      </div>
    </div>
  )
}
