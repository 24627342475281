import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, IconButton } from "@mui/material"
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { FC, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { generateRoute, URL_DASHBOARD, URL_HOME } from "../../router/routes"
import { useCustomerQuery } from "../../services/Customer"
import { CheckoutContainerProps, CheckoutViewProps } from "./CheckoutContainer.interfaces"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "")

export const CheckoutView: FC<CheckoutViewProps> = (props) => {
  const { redirect_url = window.location, back_url = generateRoute(URL_DASHBOARD), price } = props

  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!stripe || !elements) return
    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: `${process.env.REACT_APP_REDIRECT_URI}${redirect_url}` },
    })

    if (error) {
      toast.error(error?.message ?? "An unexpected error occured.")
      setIsLoading(false)
    }
  }

  if (!stripe || !elements) return <Loader />

  return (
    <div className="flex w-full flex-grow">
      <div className="flex w-full flex-wrap">
        <div className="relative flex flex-col justify-center bg-white px-2 sm:w-1/2">
          <Link to={back_url} className="absolute left-0 top-0 mt-5" style={{ marginLeft: "5%" }}>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div className="w-75 mx-auto">
            <div className="mb-4">
              <h4 className="text-4xl font-bold">Checkout</h4>
              <span className="text-muted">Credit card details</span>
            </div>
            <div>
              <form id="payment-form" onSubmit={onSubmit}>
                {isLoading ? <Loader /> : (!stripe || !elements) && <Message />}
                <div hidden={isLoading || !stripe || !elements}>
                  <PaymentElement onReady={() => setIsLoading(false)} id="payment-element" />
                  <Button
                    variant="contained"
                    disabled={isLoading || !stripe || !elements}
                    className="mt-4"
                    type="submit"
                    id="submit"
                  >
                    Pay now {price}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="relative flex justify-center px-2 sm:w-1/2">
          <Link to={URL_HOME}>
            <img
              className="mw-15rem absolute left-0 top-0 ml-5 mt-5"
              src="/assets/logo.svg"
              alt="logo"
              style={{ height: "50px" }}
            />
          </Link>
          <div style={{ maxWidth: "30rem" }}>
            <img className="h-full w-full" src="/assets/login.svg" alt="login" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const CheckoutContainer: FC<CheckoutContainerProps> = (props) => {
  const {
    clientSecretFn,
    price,
    errorComponent = () => (
      <Message
        title="Payment Canceled"
        actionButton={
          <Link to={generateRoute(URL_DASHBOARD)}>
            <Button variant="contained">Retry</Button>
          </Link>
        }
      />
    ),
    successComponent = () => (
      <Message
        title="Payment Succeeded"
        description="Thank you for joining Cloudsyte."
        actionButton={
          <Link to={generateRoute(URL_DASHBOARD)}>
            <Button variant="contained">Go Back</Button>
          </Link>
        }
      />
    ),
    ...viewProps
  } = props

  const retrieveCustomer = useCustomerQuery()

  const query = new URLSearchParams(window.location.search)
  const status = query.get("redirect_status")

  const [clientSecret, setclientSecret] = useState<string | undefined | null>(null)

  useEffect(() => {
    if (clientSecret === null && !status && !!retrieveCustomer.data) {
      clientSecretFn?.().then((secret) => setclientSecret(secret))
    }
  }, [clientSecret, retrieveCustomer.data, status])

  if (retrieveCustomer.isLoading) return <Loader />

  if (retrieveCustomer.isError || !retrieveCustomer.data) return <Message error={retrieveCustomer.error} />

  if (status === "succeeded" || clientSecret === undefined) return successComponent()
  if (status) return errorComponent()

  if (clientSecret === null) return <Loader />

  return (
    <Elements options={{ clientSecret }} stripe={stripePromise}>
      <CheckoutView {...viewProps} price={price} />
    </Elements>
  )
}
