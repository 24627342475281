import { ChevronRight, RemoveCircleOutline } from "@mui/icons-material"
import { Autocomplete, Button, IconButton, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { FC } from "react"
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FormField } from "../../components/FormField"
import { generateRoute, RoutePram, URL_ACTIONPLAN_DASHBOARD, URL_ACTIONPLAN_INVENTORYINPUTS } from "../../router/routes"
import { Emission, useListEmissionQuery } from "../../services/Emission"
import { useCreateInventoryMutation } from "../../services/Inventory"
import { useInventoryInputByActionplanIdQuery } from "../../services/InventoryInput"
import { Loader } from "../Loader"
import { Message } from "../Message"
import {
  ActionPlanInventoryDetailProps,
  ActionPlanInventoryFormProps,
  FormState,
} from "./ActionPlanInventoryDetail.interfaces"

export const ActionPlanInventoryDetail: FC<ActionPlanInventoryDetailProps> = (props) => {
  const { className } = props
  const navigate = useNavigate()
  const { id } = useParams<RoutePram>()

  const inventoryInput = useInventoryInputByActionplanIdQuery({ actionplanId: Number(id) })
  const [createInventory] = useCreateInventoryMutation()

  const getEmissions = useListEmissionQuery()

  if (getEmissions.isLoading || inventoryInput.isLoading) return <Loader />
  if (getEmissions.isError || inventoryInput.isError)
    return <Message error={inventoryInput.error ?? getEmissions.error} />

  const onSubmit = async (values: FormState) => {
    const promises = values.data.map((data) => createInventory(data).unwrap())

    await toast
      .promise(Promise.all(promises), {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Successfully updated",
      })
      .then(() => navigate(generateRoute(URL_ACTIONPLAN_DASHBOARD, { id: id, tab: "" })))
  }

  return (
    <div className={className}>
      {inventoryInput.data?.length ? (
        <ActionPlanInventoryForm className="-my-5" inputs={inventoryInput.data} onSubmit={onSubmit} />
      ) : (
        <Message
          title="No Action Inventory Input Created Yet"
          actionButton={
            <Link to={generateRoute(URL_ACTIONPLAN_INVENTORYINPUTS, { id: id })}>
              <Button variant="contained">Create Inventory Inputs</Button>
            </Link>
          }
        />
      )}
    </div>
  )
}

export const ActionPlanInventoryForm: FC<ActionPlanInventoryFormProps> = (props) => {
  const { className, onSubmit, inputs } = props

  const form = useForm<FormState>({
    defaultValues: {
      data: inputs.reduce(
        (acc, input) => [
          ...acc,
          {
            inventoryInputId: input.id,
            usage: 0,
            date: new Date().toString(),
          } as FormState["data"][0],
        ],
        [] as FormState["data"]
      ),
    },
  })

  return (
    <div className={className}>
      {inputs.map((input, index) => (
        <div className="my-5 flex flex-wrap items-end space-x-2">
          <FormField
            className="w-4/12 px-2"
            label={`${input?.Emission?.source ?? input?.name} Usage / ${input?.Emission?.unit ?? input?.unit}`}
            control={form.control}
            name={`data.${index}.usage` as any}
            transform={(value) => Number(value)}
            rules={{ required: { message: "value is required", value: true } }}
          >
            <TextField size="small" type="number" variant="outlined" fullWidth />
          </FormField>
          <FormField
            className="w-4/12 px-2"
            label="Date"
            control={form.control}
            name={`data.${index}.date` as any}
            rules={{ required: { message: "value is required", value: true } }}
          >
            {(props) => (
              <DatePicker
                renderInput={({ ...params }) => <TextField size="small" fullWidth {...params} />}
                {...props}
              />
            )}
          </FormField>
          <Input key={index} index={index} form={form} />
        </div>
      ))}
      <div className="text-right">
        <Button onClick={form.handleSubmit(onSubmit)} variant="contained" endIcon={<ChevronRight />}>
          Create
        </Button>
      </div>
    </div>
  )
}

const Input: FC<{ form: UseFormReturn<FormState>; index: number }> = (props) => {
  const { form, index: parentIndex } = props
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `data.${parentIndex}.subInventory`,
  } as any)
  const getEmissions = useListEmissionQuery()

  return (
    <>
      {fields.length === 0 && (
        <Button
          className="ml-2"
          variant="contained"
          onClick={() => append({ name: "", inventoryInputId: undefined, usage: "", date: new Date().toString() })}
        >
          Compare
        </Button>
      )}
      <div className="ml-5 w-full items-center pl-5">
        {fields.map((field, index) => (
          <div key={index} className="relative my-1 flex flex-wrap items-end">
            <div
              className={`absolute top-px left-0 border-l-2 border-dotted border-neutral-500 ${
                index !== fields.length - 1 ? "-bottom-2" : "bottom-[2.9rem]"
              }`}
            />
            <div className="-left-1 bottom-10 w-5 self-center border-b-2 border-dotted border-neutral-500" />

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Name"
              control={form.control}
              name={`data.${parentIndex}.subInventory.${index}.name`}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <TextField size="small" variant="outlined" fullWidth />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              rules={{ required: { message: "value is required", value: true } }}
              label="Substance"
              name={`data.${parentIndex}.subInventory.${index}.emissionId`}
              control={form.control as any}
              transform={(_, gadsl: Emission) => gadsl.id}
            >
              <Autocomplete
                options={getEmissions.data ?? []}
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) => option.source === value.source}
                getOptionLabel={(item) => (typeof item === "string" ? item : item.source)}
                renderInput={(props) => <TextField {...props} variant="outlined" />}
                // renderOption={(props, option: Emission & { isNew?: boolean }) => (
                //   <li {...props}>{option.isNew ? `Add ${option.source}` : option.source}</li>
                // )}
                // filterOptions={(options, params) => {
                //   const { inputValue: value } = params

                //   const data = substanceoptions(options, params)
                //   const isExisting = options.some((option) => value === option.source)

                //   if (value !== "" && !isExisting)
                //     data.push({ substance: value, isNew: true, cas: "", classification: "" })

                //   return data
                // }}
              />
            </FormField>

            <FormField
              keepErrorSpace
              className="mr-4"
              label="Usage"
              control={form.control}
              name={`data.${parentIndex}.subInventory.${index}.usage`}
              transform={(value) => Number(value)}
              rules={{ required: { message: "value is required", value: true } }}
            >
              <TextField size="small" type="number" variant="outlined" fullWidth />
            </FormField>

            <IconButton className="mr-4 mb-4" onClick={() => remove(index)}>
              <RemoveCircleOutline />
            </IconButton>

            {index === fields.length - 1 && (
              <Button
                className="mb-4"
                variant="contained"
                onClick={() =>
                  append({ name: "", inventoryInputId: undefined, usage: "", date: new Date().toString() })
                }
              >
                Add
              </Button>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
