import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalStore } from "./Global.interface"

const initialState: GlobalStore = {
  organization: undefined,
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setOrganization: (state, { payload }: PayloadAction<GlobalStore["organization"]>): GlobalStore => {
      return { ...state, organization: payload }
    },
  },
})

export const { setOrganization } = globalSlice.actions
export default globalSlice.reducer
