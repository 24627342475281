import { Button } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import { ActionPanel } from "../../components/ActionPanel"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { PRICEID } from "../../helpers/contants"
import { generateRoute, URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT } from "../../router/routes"
import { useCustomerQuery } from "../../services/Customer"
import { useSubscriptionQuery } from "../../services/Subscription"
import { BillingManagementProps } from "./BillingManagement.interfaces"

export const BillingManagement: FC<BillingManagementProps> = () => {
  const retrieveSubscription = useSubscriptionQuery()
  const retrieveCustomer = useCustomerQuery()

  if (retrieveCustomer.isLoading || retrieveSubscription.isLoading) return <Loader />
  if (retrieveCustomer.isError || retrieveSubscription.isError || !retrieveSubscription.data?.items) {
    if (!retrieveSubscription.data?.items)
      return (
        <Message
          error={retrieveCustomer.isError || retrieveSubscription.error}
          title="You don't have any subscription"
          actionButton={
            <Link to={generateRoute(URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT)}>
              <Button variant="contained">Buy Subscription</Button>
            </Link>
          }
        />
      )
  }

  if (retrieveCustomer.isLoading || retrieveSubscription.isLoading) return <Loader />
  if (retrieveCustomer.isError || retrieveSubscription.isError || !retrieveSubscription.data?.items) {
    if (!retrieveSubscription.data?.items)
      return (
        <Message
          error={retrieveCustomer.isError || retrieveSubscription.error}
          title="You don't have any subscription"
          actionButton={
            <Link to={generateRoute(URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT)}>
              <Button variant="contained">Buy Subscription</Button>
            </Link>
          }
        />
      )

    return <Message error={retrieveCustomer.isError ?? retrieveSubscription.error} />
  }

  return (
    <div>
      <ActionPanel />

      <div className="overflow-hidden rounded-lg bg-white p-3 shadow-sm">
        <div>
          <div className="mb-2 text-lg font-bold">Subscription</div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Current Subscription</div>
            <div>
              {retrieveSubscription.data?.items.data.some(
                (item) => item.price.id === PRICEID.actionPlanSeat[process.env.NODE_ENV]
              ) &&
                retrieveSubscription.data?.items.data.some(
                  (item) => item.price.id === PRICEID.actionPlan[process.env.NODE_ENV]
                ) && <div>Sustainability Action Plan</div>}
            </div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Seats</div>
            <div>
              {
                retrieveSubscription.data?.items.data.find(
                  (item) => item.price.id === PRICEID.actionPlan[process.env.NODE_ENV]
                )?.quantity
              }
            </div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Status</div>
            <div className="mb-2">{retrieveSubscription.data?.status}</div>
          </div>

          <div className="text-right">
            <Link className="mr-6" to={generateRoute(URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT)}>
              <Button variant="contained">Change Seats</Button>
            </Link>
            <Button variant="contained" color="error">
              Cancel Subscription
            </Button>
          </div>
        </div>

        <hr />

        <div>
          <div className="h4 mb-2">User</div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Name </div>
            <div>{retrieveCustomer.data?.name}</div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Email </div>
            <div>{retrieveCustomer.data?.email}</div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Phone </div>
            <div>{retrieveCustomer.data?.phone}</div>
          </div>
          <div className="mb-2 flex justify-between">
            <div className="font-bold">Address </div>
            <div className="text-right">
              <div>{[retrieveCustomer.data?.address?.line1, retrieveCustomer.data?.address?.line2].join(", ")}</div>
              <div>
                {[
                  retrieveCustomer.data?.address?.city,
                  retrieveCustomer.data?.address?.state,
                  retrieveCustomer.data?.address?.country,
                  retrieveCustomer.data?.address?.postal_code,
                ].join(", ")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
