import { ArrowBack } from "@mui/icons-material"
import { Card, Chip, IconButton } from "@mui/material"
import { lowerCase, startCase } from "lodash"
import { FC } from "react"
import { Link } from "react-router-dom"
import { Column } from "../../components/EditTable/EditTable.interfaces"
import { NewCompliance } from "../../components/NewCompliance"
import { Table } from "../../components/Table"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { useAppSelector } from "../../redux/store/hooks"
import { generateRoute, URL_DASHBOARD, URL_QUESTIONNAIRE_DETAIL } from "../../router/routes"
import { Audit, useListAuditQuery } from "../../services/Audit"
import { QuestionnaireListProps } from "./QuestionnaireList.interfaces"

export const QuestionnaireList: FC<QuestionnaireListProps> = (props) => {
  const role = useAppSelector((store) => store.auth.role)
  const auditByOrganization = useListAuditQuery()

  if (auditByOrganization.isLoading) return <Loader />
  if (auditByOrganization.isError) return <Message error={auditByOrganization.error} />

  const columns: Column<Audit & { id: number }>[] = [
    {
      flex: 3,
      field: "name",
      headerName: "Name",
      renderCell: ({ value, row }) => (
        <Link
          className="font-bold text-primary"
          to={generateRoute(URL_QUESTIONNAIRE_DETAIL, { id: row.id.toString() })}
        >
          {value || "Open Detail"}
        </Link>
      ),
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: ({ value }) => (
        <Chip
          size="small"
          label={startCase(lowerCase(value))}
          className="rounded"
          color={value === "completed" ? "success" : "primary"}
        />
      ),
    },
    {
      flex: 1,
      field: "type",
      headerName: "Type",
      renderCell: ({ value }) => <div>{value.charAt(0).toUpperCase() + value.slice(1)}</div>,
    },
    {
      flex: 1,
      field: "year",
      headerName: "Year",
    },
  ]

  return (
    <div className="w-full flex-grow p-4">
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Link className="mr-2" to={generateRoute(URL_DASHBOARD)}>
            <IconButton className="rounded-full bg-primary text-white">
              <ArrowBack />
            </IconButton>
          </Link>
          <div className="text-xl font-semibold">Sustainability Rating</div>
        </div>

        <div className="flex items-center space-x-2">
          {role?.type === "admin" && <NewCompliance />}
          {/* {role?.type === "admin" && <InviteSupplier />} */}
          {/* {role?.type === "admin" && <SupplierList />} */}
        </div>
      </div>
      <div>
        {auditByOrganization.data?.length ? (
          <>
            <div className="mb-3 font-bold text-body">All Compliance</div>
            <Card>
              <Table<Audit> columns={columns} data={auditByOrganization.data} />
            </Card>
          </>
        ) : (
          <Message
            title="No assessment rating created yet"
            description="Click New Rating above to start your journey today"
          />
        )}
      </div>
    </div>
  )
}
