import { FC } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getOrganizationNameFromRoute } from "../utils/getOrganizationNameFromRoute"
import { URL_HOME } from "./routes"

export interface Props {
  children?: JSX.Element
}

export const OrganizationOutlet: FC<Props> = (props) => {
  const { children } = props

  const selectedOrganization = getOrganizationNameFromRoute()
  if (!selectedOrganization) return <Navigate to={URL_HOME} />

  if (children) return { ...children, props: { ...children.props, children: <Outlet /> } }

  return <Outlet />
}
