import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { IconButton } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import toast from "react-hot-toast"
import { fileService } from "../../services/axiosBaseQuery"
import { Require } from "../../types/types"
import { Document, FileUploaderProps } from "./FileUploader.interfaces"

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const { onChange, defaultValue, disabled, placeholder = "Choose a file or drag it here", ...DropzoneOptions } = props

  const [files, setFiles] = useState<Require<Partial<Document>, "name">[]>(defaultValue ?? [])
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5242880,
    multiple: true,
    accept: [
      ".doc",
      ".docx",
      "image/*",
      ".csv",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.ms-word",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
    ],
    onDrop: (acceptedFiles: Document[], rejected) => {
      rejected.forEach((file) => {
        toast.error(`${file.file.name} ${file.errors[0].message.replace(/5242880 bytes/gi, "5 Mb")}`)
      })

      const newValue = [...acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))]

      setFiles(newValue)
      onChange(newValue)
    },
    disabled,
    ...DropzoneOptions,
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file) => {
        if (file.preview) URL.revokeObjectURL(file.preview)
      })
  }, [])

  // note: effect because defaultValue loades with delay because of map
  useEffect(() => {
    if (defaultValue) setFiles(defaultValue)
  }, [defaultValue])

  const onRemove = (index: number) => {
    setFiles((files) => files.filter((_, i) => index !== i))
  }

  const Download = (file: Require<Partial<Document>, "name">) => {
    if (file.url) fileService.get("", { params: { fileName: file.url } })
  }

  return (
    <div>
      <div {...getRootProps()} className={`dropzone ${disabled && "pointer-events-none"}`}>
        <input {...getInputProps()} disabled={disabled} />
        <div>{placeholder}</div>
      </div>
      {!!files.length && (
        <aside className="mt-4 flex flex-wrap">
          {files.map((file, index) => (
            <div
              style={{ width: 120, height: 120 }}
              className="rounded-3 relative mr-3 mb-3 items-center justify-center"
              key={file.name}
            >
              {!disabled && (
                <IconButton
                  className="rounded-circle lh-1 absolute right-0 top-0 flex h-auto w-auto"
                  style={{
                    transform: "translate(50%,-50%)",
                    zIndex: 100,
                    fontSize: 25,
                  }}
                  onClick={() => onRemove(index)}
                >
                  <HighlightOffIcon className="lh-1 w-auto bg-white p-0" />
                </IconButton>
              )}
              <button
                onClick={() => Download(file)}
                className="text-break flex overflow-hidden border border-transparent bg-transparent p-1 text-center"
                disabled={!file.url}
              >
                {file.type?.includes("image") ? (
                  <img
                    alt={file.name}
                    src={file.preview}
                    className="img-fluid rounded-3"
                    style={{
                      height: 120 - 10,
                      width: 120 - 10,
                      objectFit: "cover",
                    }}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      if (file.preview) URL.revokeObjectURL(file.preview)
                    }}
                  />
                ) : file.name?.length && file.name?.length > 35 ? (
                  <div className="p-2">
                    {file.name.split(".")[0]?.slice(0, 35)}...{file.name.split(".")[1]}
                  </div>
                ) : (
                  <div className="p-2">{file.name}</div>
                )}
              </button>
            </div>
          ))}
        </aside>
      )}
    </div>
  )
}
