import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { ResourceEnum } from "../Permission"
import { CreateAnswer } from "./Answer.documents"

export const answerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAnswer: builder.mutation<
      any,
      { attachmentUrls: string[]; auditId: number; questionId: number; optionId: number }
    >({
      query: (variables) => ({ document: CreateAnswer, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Answer, operation: PermissionEnum.Create } },
      invalidatesTags: ["Answer"],
    }),
  }),
})

export const { useCreateAnswerMutation } = answerApi
