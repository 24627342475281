import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { ResourceEnum } from "../Permission"
import { getTemplateById, templateAll } from "./Template.documents"
import { Template } from "./Template.interfaces"

export const templateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    template: builder.query<Template, { id: number }>({
      query: (variables) => ({
        document: getTemplateById,
        variables,
      }),
      extraOptions: { headers: { resource: ResourceEnum.Template, operation: PermissionEnum.Read } },
    }),
    listtemplate: builder.query<Omit<Template, "Sections">[], void>({
      query: () => ({ document: templateAll }),
      extraOptions: { headers: { resource: ResourceEnum.Template, operation: PermissionEnum.Read } },
    }),
  }),
})

export const { useLazyTemplateQuery, useTemplateQuery, useListtemplateQuery } = templateApi
