import { List, ListItemButton } from "@mui/material"
import { FC } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import { PageTitleHeader } from "../../layouts/PageTitleHeader"
import { useAppSelector } from "../../redux/store/hooks"
import {
  generateRoute,
  URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT,
  URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS,
  URL_ORGANIZATION_SETTING_USERS,
} from "../../router/routes"
import { OrganizationSettingProps } from "./OrganizationSetting.interfaces"

export const OrganizationSetting: FC<OrganizationSettingProps> = (props) => {
  const { className } = props
  const location = useLocation()
  const role = useAppSelector((store) => store.auth.role)

  return (
    <div className={className}>
      <div className="flex flex-wrap">
        <div className="w-60 px-2">
          <PageTitleHeader />
          <div className={`scrollbar rounded-lg border bg-white`}>
            <List>
              <ListItemButton
                selected={generateRoute(URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS) === location.pathname}
                className="relative py-3"
                divider
                sx={{
                  "&.Mui-selected": {
                    borderLeft: "5px solid #ea580c",
                  },
                }}
              >
                <Link
                  to={generateRoute(URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS)}
                  className="stretched-link text-body"
                >
                  Roles And Permissions
                </Link>
              </ListItemButton>
              <ListItemButton
                selected={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT) === location.pathname}
                className="relative py-3"
                divider
                sx={{
                  "&.Mui-selected": {
                    borderLeft: "5px solid #ea580c",
                  },
                }}
              >
                <Link
                  to={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}
                  className="stretched-link text-body"
                >
                  Billing Management
                </Link>
              </ListItemButton>
              <ListItemButton
                selected={generateRoute(URL_ORGANIZATION_SETTING_USERS) === location.pathname}
                className="relative py-3"
                sx={{
                  "&.Mui-selected": {
                    borderLeft: "5px solid #ea580c",
                  },
                }}
              >
                <Link to={generateRoute(URL_ORGANIZATION_SETTING_USERS)} className="stretched-link text-body">
                  User Management
                </Link>
              </ListItemButton>
            </List>
          </div>
        </div>
        <div className="flex-1 px-2">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
