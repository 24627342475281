import { ArrowBack } from "@mui/icons-material"
import { Button, Card, Chip, IconButton, MenuItem } from "@mui/material"
import { FC } from "react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { Dropdown } from "../../components/Dropdown"
import { Column } from "../../components/EditTable/EditTable.interfaces"
import { Table } from "../../components/Table"
import { TableProps } from "../../components/Table/Table.interface"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { PRICEID, SCOPES, STATUSOPTIONS, SUSTAINABILITYTYPE } from "../../helpers/contants"
import { useAppSelector } from "../../redux/store/hooks"
import {
  generateRoute,
  URL_ACTIONPLAN_DASHBOARD,
  URL_ACTIONPLAN_NEW,
  URL_DASHBOARD,
  URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT,
} from "../../router/routes"
import { ActionPlan, useDeleteActionPlanByIdMutation, useListActionplanQuery } from "../../services/ActionPlan"
import { useSubscriptionQuery } from "../../services/Subscription"
import { ActionPlanProps } from "./ActionPlanList.interfaces"

export const ActionPlanList: FC<ActionPlanProps> = (props) => {
  const { className } = props
  const user = useAppSelector((store) => store.auth.user)
  const role = useAppSelector((store) => store.auth.role)

  const actionplanByOrganization = useListActionplanQuery({ uid: user?.id! }, { skip: !user?.id })
  const retrieveSubscription = useSubscriptionQuery()
  const [deleteActionPlanById] = useDeleteActionPlanByIdMutation()

  if (retrieveSubscription.isLoading || actionplanByOrganization.isLoading) return <Loader />
  if (retrieveSubscription.isError || actionplanByOrganization.isError) {
    if (retrieveSubscription.isError || !retrieveSubscription.data?.items)
      return (
        <Message
          title="You don't have any subscription"
          actionButton={
            <Link to={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}>
              <Button variant="contained">Manage Subscription</Button>
            </Link>
          }
        />
      )
    return <Message error={retrieveSubscription.isError ?? actionplanByOrganization.error} />
  }

  if (
    !retrieveSubscription.data?.items.data.some(
      (item) => item.price.id === PRICEID.actionPlanSeat[process.env.NODE_ENV]
    )
  )
    return (
      <Message
        title="You don't have a subscription"
        description="Please purchase a subscription to this module."
        actionButton={
          <Link to={generateRoute(URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT)}>
            <Button variant="contained">Purchase Subscription</Button>
          </Link>
        }
      />
    )

  const columns: Column<ActionPlan & { id: number }>[] = [
    {
      flex: 1,
      field: "title",
      headerName: "Title",
      renderCell: ({ value, row }) => (
        <Link
          className="font-bold text-primary"
          to={generateRoute(URL_ACTIONPLAN_DASHBOARD, { id: row.id.toString(), tab: "" })}
        >
          {value || "Open Detail"}
        </Link>
      ),
    },
    {
      flex: 1,
      field: "status" as keyof ActionPlan,
      headerName: "Status",
      valueGetter: ({ row }) => row.actionItems[0].status,
      renderCell: ({ value }) => (
        <Chip
          color={value === "Completed" ? "success" : undefined}
          size="small"
          label={STATUSOPTIONS.find((item) => item.value === value)?.text ?? ""}
          className="rounded"
        />
      ),
    },
    {
      flex: 1,
      field: "scope",
      headerName: "Scope",
      renderCell: ({ value }) => SCOPES.find((item) => item.value === value)?.text ?? "",
    },
    {
      flex: 1,
      field: "sustainabilityType",
      headerName: "Sustainability Type",
      renderCell: ({ value }) => <div>{SUSTAINABILITYTYPE.find((item) => item.value === value)?.text ?? ""}</div>,
    },
    {
      flex: 1,
      field: "actionItems",
      headerName: "actionItems",
      renderCell: ({ value }) => value?.length,
    },
  ]

  const actionButtons: TableProps<ActionPlan & { id: number }>["actionButtons"] = {
    width: 55,
    renderCell: ({ row }) => (
      <Dropdown>
        <MenuItem
          onClick={() =>
            toast.promise(deleteActionPlanById({ id: row.id }).unwrap(), {
              error: (error) => error?.message ?? "Something went wrong",
              loading: "Please Wait",
              success: "Successfully removed",
            })
          }
        >
          Delete
        </MenuItem>
      </Dropdown>
    ),
  }

  return (
    <div className={className}>
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center">
          <Link className="mr-2" to={generateRoute(URL_DASHBOARD)}>
            <IconButton className="rounded-full bg-primary text-white">
              <ArrowBack />
            </IconButton>
          </Link>
          <div className="text-xl font-semibold">Action Plan Detail</div>
        </div>

        {role?.type === "admin" && (
          <Link to={generateRoute(URL_ACTIONPLAN_NEW)}>
            <Button variant="contained">Create Action Plan</Button>
          </Link>
        )}
      </div>
      <div>
        {actionplanByOrganization.data?.length ? (
          <>
            <div className="mb-3 font-bold text-body">All Action Plan</div>
            <Card>
              <Table<ActionPlan> actionButtons={actionButtons} columns={columns} data={actionplanByOrganization.data} />
            </Card>
          </>
        ) : (
          <Message
            title="No Action Plan Created Yet"
            description='Click "Create Action Plan" above to start your journey today.'
          />
        )}
      </div>
    </div>
  )
}
