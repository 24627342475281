import { AddCircleOutline, Cancel, Delete, Edit, Save } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import Button from "@mui/material/Button"
import { GridRenderCellParams, GridRowId, GridValidRowModel, useGridApiContext } from "@mui/x-data-grid-pro"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { omit } from "lodash"
import { nanoid } from "nanoid"
import { MutableRefObject, useEffect, useState } from "react"
import { Table } from "../Table"
import { Column, Datum, EditTableProps } from "./EditTable.interfaces"

export const EditTable = <T extends Record<string, any>>(props: EditTableProps<T>): JSX.Element => {
  const {
    data,
    columns,
    onAdd: AfterAdd,
    onUpdate: AfterUpdate,
    onRemove: AfterRemove,

    canAdd = { value: true },
    addnewtext = "Add Another Row",
    newRecord,
    ...rest
  } = props

  const [rows, setRows] = useState<T[]>(data ?? [])
  const [editId, setEditId] = useState<GridRowId | undefined>()

  useEffect(() => {
    if (data) setRows(data)
  }, [data])

  const onEdit = (cell: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => {
    if (editId) api.current.stopRowEditMode({ id: editId, ignoreModifications: true })

    setEditId(cell.id)
    api.current.startRowEditMode({ id: cell.id })
  }

  const onSave = (cell: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => {
    setEditId(undefined)
    api.current.stopRowEditMode({ id: cell.id })

    setTimeout(() => {
      const value = api.current.getRow(cell.id) as Datum<T>
      const data = rows.map((item) => (item.id !== cell.id ? item : (omit(value, "_isNew") as T)))

      if (value._isNew) {
        AfterAdd?.(omit(value, "_isNew") as T, data)
          .catch(() => {
            setEditId(cell.id)
            api.current.startRowEditMode({ id: cell.id })
          })
          .then(() => setRows(data))
      } else {
        AfterUpdate?.(omit(value, "_isNew") as T, data).catch(() => {
          setEditId(cell.id)
          api.current.startRowEditMode({ id: cell.id })
        })
      }
    })
  }

  const onDelete = (cell: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => {
    const data = rows.filter((item) => item.id !== cell.id)
    if (cell.row._isNew) {
      setRows(data)
    } else {
      AfterRemove?.(cell.row, data)
    }
  }

  const onCancel = (cell: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => {
    setEditId(undefined)

    if (cell.row._isNew) setRows(rows.filter((item) => item.id !== cell.id))
    else api.current.stopRowEditMode({ id: cell.id, ignoreModifications: true })
  }

  const onAdd = () => {
    const id = nanoid()
    setRows((oldRows) => {
      // const newval = Object.keys(data?.[0] ?? {}).reduce((acc, key) => ({ ...acc, [key]: undefined })) as unknown as T
      const newval = newRecord ?? {}
      return [...oldRows, { ...newval, id, _isNew: true } as unknown as T]
    })
  }

  const cols: Column<T>[] = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (x) => (
        <ActionCell editID={editId} onCancel={onCancel} onSave={onSave} onDelete={onDelete} onEdit={onEdit} {...x} />
      ),
    },
  ]

  return (
    <>
      <Table
        onRowEditStart={(params, event) => (event.defaultMuiPrevented = true)}
        onRowEditStop={(params, event) => (event.defaultMuiPrevented = true)}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        data={rows}
        {...rest}
        columns={cols}
      />
      {(!editId || (!canAdd.value && !canAdd.message)) && (
        <Button className="w-full py-3" onClick={onAdd} disabled={!canAdd.value}>
          {!canAdd.value ? (
            canAdd.message
          ) : (
            <>
              <AddCircleOutline className="mr-2" /> {addnewtext}
            </>
          )}
        </Button>
      )}
    </>
  )
}

const ActionCell = <T extends GridValidRowModel = Record<string, any>>({
  editID,
  onEdit,
  onCancel,
  onDelete,
  onSave,
  ...rest
}: GridRenderCellParams<any, T> & {
  editID?: GridRowId
  onEdit: (row: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => void
  onCancel: (row: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => void
  onDelete: (row: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => void
  onSave: (row: GridRenderCellParams<any, T>, api: MutableRefObject<GridApiPro>) => void
}) => {
  const api = useGridApiContext()

  const isInEditMode = rest.id === editID

  if (rest.row?._isNew && !isInEditMode) onEdit(rest, api)

  if (isInEditMode) {
    return (
      <div className="flex">
        <IconButton onClick={() => onSave(rest, api)}>
          <Save />
        </IconButton>
        <IconButton className="textPrimary" onClick={() => onCancel(rest, api)} color="inherit">
          <Cancel />
        </IconButton>
      </div>
    )
  }

  return (
    <div className="flex">
      <IconButton onClick={() => onEdit(rest, api)}>
        <Edit />
      </IconButton>
      <IconButton className="textPrimary" onClick={() => onDelete(rest, api)} color="inherit">
        <Delete />
      </IconButton>
    </div>
  )
}
