import { Close, Description, Search } from "@mui/icons-material"
import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
} from "@mui/material"
import { groupBy, startCase, toLower } from "lodash"
import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { ICONS } from "../../helpers/contants"
import { generateRoute, URL_QUESTIONNAIRE_NEW } from "../../router/routes"
import { Template, useListtemplateQuery } from "../../services/Template"

import { AssessmentCardProps, NewComplianceProps } from "./NewCompliance.interface"

export const NewCompliance: FC<NewComplianceProps> = (props) => {
  const { className } = props

  const templates = useListtemplateQuery()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState<"general" | string>("general")

  const filtered = useMemo(
    () => (search ? templates.data?.filter((assessment) => new RegExp(search, "gi").test(assessment.name)) : []),
    [search]
  )

  if (templates.isLoading) return <Loader />
  if (templates.isError) return <Message error={templates.error} />

  return (
    <div className={className}>
      <Button variant="contained" onClick={() => setOpen(true)}>
        New Rating
      </Button>

      <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="flex items-center justify-between font-bold">
          <div>Choose Assessment</div>
          <div className="flex items-center space-x-2">
            <TextField
              className="self-center"
              variant="standard"
              placeholder="Search"
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="flex max-h-[90vh] min-h-[40vh] flex-wrap">
          {search ? (
            filtered?.length ? (
              filtered?.map((item) => (
                <div className="w-4/12 p-1">
                  <AssessmentCard
                    onClick={() => navigate(generateRoute(URL_QUESTIONNAIRE_NEW, { compliance: String(item.id) }))}
                    template={item}
                  />
                </div>
              ))
            ) : (
              <Message title="" description="No assessment found" />
            )
          ) : (
            <div className="-m-2 flex w-full">
              <div className="w-3/12 p-2">
                <List component="nav" className="p-0">
                  {Object.keys(groupBy(templates.data ?? {}, "industry")).map((name) => (
                    <ListItemButton key={name} selected={name === category} onClick={() => setCategory(name)}>
                      {startCase(toLower(name))}
                    </ListItemButton>
                  ))}
                </List>
              </div>
              <div className="-m-1 flex w-9/12 flex-wrap items-start p-2">
                {(groupBy(templates.data ?? {}, "industry")[category] as Template[] | undefined)?.map((item, index) => (
                  <div className="w-4/12 p-1" key={index}>
                    <AssessmentCard
                      onClick={() => navigate(generateRoute(URL_QUESTIONNAIRE_NEW, { compliance: String(item.id) }))}
                      template={item}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const AssessmentCard = (props: AssessmentCardProps) => {
  const { onClick, template, className, selected } = props

  return (
    <Card onClick={() => onClick(template)} className={`${className} ${selected && "border-primary"}`}>
      <div className="flex justify-between">
        <div>{ICONS[template.icon!] ?? <Description />}</div>
        <div>{template.tag && <Chip className="text-sm" size="small" label={template.tag} />}</div>
      </div>
      <div>{template.name}</div>
      <div className="text-sm text-muted">{template.description}</div>
    </Card>
  )
}
