import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import {
  addProduct,
  deleteProductById,
  getGADSLs,
  OrganizationProductById,
  OrganizationProductByOrg,
  updateProduct,
} from "../Products"
import { api } from "../rtk"
import { GADSL, Product } from "./Products.interfaces"

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listProduct: builder.query<Product[], { name: string }>({
      query: (variables) => ({ document: OrganizationProductByOrg, variables }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Read } },
      providesTags: ["Products"],
    }),
    GADSL: builder.query<GADSL[], { substance?: string; cas?: string }>({
      query: (variables) => ({ document: getGADSLs, variables }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Read } },
      providesTags: ["Products"],
    }),
    product: builder.query<Product, { id: number }>({
      query: ({ id }) => ({ document: OrganizationProductById, variables: { organizationproductById: id } }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Read } },
      providesTags: ["Products"],
    }),
    addProduct: builder.mutation<
      { id: number },
      Omit<Product, "id" | "reason" | "classification" | "SubProduct"> & {
        SubProduct?: Omit<Product, "id" | "reason" | "classification" | "SubProduct">[]
      }
    >({
      query: (variables) => ({
        document: addProduct,
        variables: {
          data: JSON.stringify({
            ...variables,
            organizationName: getOrganizationNameFromRoute()!,
            SubProduct: variables.SubProduct?.map((product) => ({
              ...product,
              organizationName: getOrganizationNameFromRoute()!,
            })),
          }),
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Create } },
      invalidatesTags: ["Products"],
    }),
    updateProduct: builder.mutation<{ id: number }, Product>({
      query: (variables) => ({
        document: updateProduct,
        variables: {
          data: JSON.stringify({
            ...variables,
            organizationName: getOrganizationNameFromRoute()!,
            SubProduct: variables.SubProduct?.map((product) => ({
              ...product,
              organizationName: getOrganizationNameFromRoute()!,
            })),
          }),
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Update } },
      invalidatesTags: ["Products"],
    }),
    deleteProductById: builder.mutation<{ id: number }, { id: number }>({
      query: (variables) => ({ document: deleteProductById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.OrganizationProduct, operation: PermissionEnum.Delete } },
      invalidatesTags: ["Products"],
    }),
  }),
})

export const {
  useGADSLQuery,
  useListProductQuery,
  useProductQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductByIdMutation,
} = productsApi
