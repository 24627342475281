import { Button } from "@mui/material"
import { FC } from "react"
import { Link, Navigate, Outlet, RouteObject, useParams } from "react-router-dom"
import { MagicPage } from "../components/MagicPage"
import { ProductDashboard } from "../components/ProductDashboard"
import { Message } from "../containers/Message"
import { ProductNew } from "../containers/ProductNew"
import { ActionPlanDashboard } from "../pages/ActionPlanDashboard"
import { ActionPlanForm } from "../pages/ActionPlanForm"
import { ActionPlanList } from "../pages/ActionPlanList"
import { BillingManagement } from "../pages/BillingManagement"
import { Login } from "../pages/Login"
import { CreateOrganization } from "../pages/Organization/CreateOrganization"
import { OrganizationList } from "../pages/OrganizationList"
import { OrganizationSetting } from "../pages/OrganizationSetting"
import { ProductList } from "../pages/ProductList"
import { ProfileSetting } from "../pages/ProfileSetting"
import { QuestionnaireDetail } from "../pages/QuestionnaireDetail"
import { QuestionnaireList } from "../pages/QuestionnaireList"
import { QuestionnaireNew } from "../pages/QuestionnaireNew"
import { ResourcesList } from "../pages/ResourcesList"
import { Rolesandpermissions } from "../pages/Rolesandpermissions"
import { SelectSubscription } from "../pages/SelectSubscription"
import { Setting } from "../pages/Setting"
import { UserManagement } from "../pages/UserManagement"
import { ResourceEnum } from "../services/Permission"
import { getOrganizationNameFromRoute } from "../utils/getOrganizationNameFromRoute"
import { OrganizationOutlet } from "./OrganizationOutlet"

export interface PathRoute extends Omit<RouteObject, "children" | "element"> {
  name?: string
  description?: string
  element?: JSX.Element
  children?: PathRoute[]
  render?: boolean
  extrainfo?: Record<string, any> & { Resource: ResourceEnum[] }
}

export interface RoutePram extends Record<string, string | undefined> {
  orgName?: string
  id?: string
}

export function generateRoute(url: string, prams: RoutePram = {}) {
  prams.orgName = prams.orgName ?? getOrganizationNameFromRoute()!

  return Object.entries(prams).reduce((acc, [key, value]) => acc.replace(`:${key}`, value ?? ""), url)
}

export const GenerateRoute: FC<{ children: (pram: Readonly<Partial<RoutePram>>) => JSX.Element }> = (props) => {
  const { children } = props
  const pram = useParams<RoutePram>()

  return <>{children(pram)}</>
}

// Basic Routes
export const URL_HOME = "/"
export const URL_INVITE = "/invite"
export const URL_LOGIN = "/login"
export const URL_SIGNUP = "/signup"

// Organization Routes
export const URL_DASHBOARD = `/:orgName`

// Questionnaire Routes
export const URL_QUESTIONNAIRE = `${URL_DASHBOARD}/questionnaire`
export const URL_QUESTIONNAIRE_NEW = `${URL_QUESTIONNAIRE}/new/:compliance`
export const URL_QUESTIONNAIRE_DETAIL = `${URL_QUESTIONNAIRE}/:id`

// ActionPlan Routes
export const URL_ACTIONPLAN_LIST = `${URL_DASHBOARD}/action-plan`
export const URL_ACTIONPLAN_NEW = `${URL_ACTIONPLAN_LIST}/new`
export const URL_ACTIONPLAN_DASHBOARD = `${URL_ACTIONPLAN_LIST}/:id`
export const URL_ACTIONPLAN_INVENTORY = `${URL_ACTIONPLAN_DASHBOARD}/inventory`
export const URL_ACTIONPLAN_INVENTORYINPUTS = `${URL_ACTIONPLAN_DASHBOARD}/inputs`

// Product Compliance Routes
export const URL_PRODUCT_LIST = `${URL_DASHBOARD}/product-compliance`
export const URL_PRODUCT_DASHBOARD = `${URL_PRODUCT_LIST}/:id`
export const URL_PRODUCT_NEW = `${URL_PRODUCT_LIST}/new`

export const URL_ORGANIZATION_SETTING = `${URL_DASHBOARD}/setting`
export const URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS = `${URL_ORGANIZATION_SETTING}/rolesandpermissions`
export const URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT = `${URL_ORGANIZATION_SETTING}/billingmanagement`
export const URL_ORGANIZATION_SETTING_USERS = `${URL_ORGANIZATION_SETTING}/users`

export const URL_ACTIONPLAN_SUBSCRIPTION = `${URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT}/actionplan`
export const URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT = `${URL_ACTIONPLAN_SUBSCRIPTION}/select-product`

//
// Public Routes
//

export const URL_ORGANIZATION_NEW = "/organization/new"
export const URL_SETTING = "/setting"
export const URL_SETTING_PROFILE = `${URL_SETTING}/Profile`

export const AUTHENTICATED_ROUTES: PathRoute[] = [
  {
    path: URL_SETTING,
    element: <Setting className="flex flex-grow flex-col p-4" />,
    children: [
      {
        name: "User Settings",
        element: <GenerateRoute>{() => <Navigate to={generateRoute(URL_SETTING_PROFILE)} replace />}</GenerateRoute>,
        index: true,
      },
      {
        name: "Setting Form",
        element: <ProfileSetting />,
        path: URL_SETTING_PROFILE,
      },
    ],
  },
  {
    name: "Select Organization",
    path: URL_HOME,
    element: <OrganizationList className="flex flex-grow p-4" />,
  },
  {
    path: URL_DASHBOARD,
    element: <OrganizationOutlet />,
    children: [
      {
        name: "Select Resource",
        element: <ResourcesList className="flex flex-grow flex-col p-4" />,
        index: true,
      },
    ],
  },
  {
    element: (
      <OrganizationOutlet>
        <OrganizationSetting className="flex flex-grow flex-col p-4" />
      </OrganizationOutlet>
    ),
    path: URL_ORGANIZATION_SETTING,
    children: [
      {
        name: "Settings",
        index: true,
        element: (
          <GenerateRoute>
            {() => <Navigate to={generateRoute(URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS)} replace />}
          </GenerateRoute>
        ),
      },
      {
        name: "Roles And Permissions",
        description: "Create role types to be used for users",
        element: <Rolesandpermissions />,
        path: URL_ORGANIZATION_SETTING_ROLESANDPERMISSIONS,
      },
      {
        name: "Billing Management",
        description: "Organization billing Information",
        element: <BillingManagement />,
        path: URL_ORGANIZATION_SETTING_BILLINGMANAGEMENT,
      },
      {
        name: "User Management",
        description: "Organization billing Information",
        element: <UserManagement />,
        path: URL_ORGANIZATION_SETTING_USERS,
      },
    ],
  },
  {
    path: URL_ACTIONPLAN_SUBSCRIPTION,
    element: <OrganizationOutlet />,
    children: [
      {
        name: "Select Subscription",
        element: <SelectSubscription />,
        path: URL_ORGANIZATION_SUBSCRIPTION_SELECT_PRODUCT,
      },
    ],
  },
  {
    name: "Create Organization",
    path: URL_ORGANIZATION_NEW,
    element: <CreateOrganization />,
  },
  {
    name: "Sustainability Rating",
    path: URL_QUESTIONNAIRE,
    description:
      "In this Assessment Questionnaire, you will be asked questions covering various themes in environmental, supply chain, social, and governance. The objective is to assess the sustainability protocol put in place by your company. Questions in each theme will prompt you to provide documentation to support your declarations.",
    element: <OrganizationOutlet />,
    render: true,
    extrainfo: { Resource: [ResourceEnum.Audit] },
    children: [
      {
        name: "Assessment Questionnaire List",
        index: true,
        element: <QuestionnaireList />,
      },
      {
        name: "Assessment Questionnaire New",
        element: <QuestionnaireNew />,
        path: URL_QUESTIONNAIRE_NEW,
      },
      {
        name: "Assessment Questionnaire",
        element: <QuestionnaireDetail />,
        path: URL_QUESTIONNAIRE_DETAIL,
      },
    ],
  },
  {
    name: "Sustainability Action Plan",
    path: URL_ACTIONPLAN_LIST,
    element: <OrganizationOutlet />,
    description:
      "Plan sustainability projects and start monitoring your carbon emissions in a matter of minutes. With this custom template, you can measure and collaborate with all key stakeholders on all things Environmental, Social, and Governance (ESG).",
    render: true,
    extrainfo: { Resource: [ResourceEnum.ActionPlan, ResourceEnum.Inventory] },
    children: [
      {
        index: true,
        element: <ActionPlanList className="flex flex-grow flex-col p-4" />,
      },
      {
        name: "New Action Plan",
        element: <ActionPlanForm className="flex flex-grow flex-col p-4" />,
        path: URL_ACTIONPLAN_NEW,
      },
      {
        element: <Outlet />,
        path: URL_ACTIONPLAN_DASHBOARD,
        children: [
          {
            name: "Action Plan Dashboard",
            element: <ActionPlanDashboard className="flex flex-grow flex-col p-4" />,
            index: true,
          },
          {
            name: "Action Plan",
            element: <ActionPlanDashboard className="flex flex-grow flex-col p-4" />,
            path: `${URL_ACTIONPLAN_DASHBOARD}/:tab`,
          },
        ],
      },
    ],
  },
  {
    name: "Product Compliance",
    path: URL_PRODUCT_LIST,
    element: <OrganizationOutlet />,
    description:
      "Plan sustainability projects and start monitoring your carbon emissions in a matter of minutes. With this custom template, you can measure and collaborate with all key stakeholders on all things Environmental, Social, and Governance (ESG).",
    render: true,
    extrainfo: { Resource: [ResourceEnum.OrganizationProduct, ResourceEnum.Inventory] },
    children: [
      {
        index: true,
        element: <ProductList className="flex flex-grow flex-col p-4" />,
      },
      {
        name: "Add Product",
        path: URL_PRODUCT_NEW,
        element: <ProductNew className="flex flex-grow flex-col p-4" />,
      },
      {
        element: <Outlet />,
        path: URL_PRODUCT_DASHBOARD,
        children: [
          {
            name: "Action Plan Dashboard",
            element: <ProductDashboard className="flex flex-grow flex-col p-4" />,
            index: true,
          },
          {
            name: "Action Plan",
            element: <ProductDashboard className="flex flex-grow flex-col p-4" />,
            path: `${URL_PRODUCT_DASHBOARD}/:tab`,
          },
        ],
      },

      // {
      //   name: "Product Detail",
      //   path: URL_PRODUCT_DASHBOARD,
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <GenerateRoute>
      //           {(pram) => <Navigate to={generateRoute(`${URL_PRODUCT_DASHBOARD}/detail`, { id: pram.id?.toString() })} />}
      //         </GenerateRoute>
      //       ),
      //     },
      //     {
      //       path: `${URL_PRODUCT_DASHBOARD}/:tab`,
      //       element: <Product />,
      //     },
      //   ],
      //   element: <Outlet />,
      // },
      // {
      //   name: "Edit Product",
      //   path: URL_PRODUCT_EDIT,
      //   element: <EditProductForm />,
      // },
    ],
  },
]

export const UNAUTHENTICATED_ROUTES: PathRoute[] = [
  {
    name: "Login",
    path: URL_LOGIN,
    element: <Login />,
  },
]

export const OPEN_ROUTES: PathRoute[] = [
  {
    name: "Not Found",
    path: "*",
    element: (
      <Message
        title="Page Not Found"
        description={
          <div>
            <div>Oops! The page you are looking for cannot be found.</div>
            <div>
              Try rechecking the URL to see if it's the right one or continue to {process.env.REACT_APP_REDIRECT_URI}
            </div>
          </div>
        }
        actionButton={
          <Link to="/">
            <Button variant="contained">Back Home</Button>
          </Link>
        }
      />
    ),
  },
  {
    path: URL_INVITE,
    element: <MagicPage />,
  },
]
