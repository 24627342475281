import { gql } from "graphql-request"

export const RoleByOrganization = gql`
  query RoleByOrganization($name: String!) {
    roleByOrganization(name: $name) {
      id
      type
      Permissions {
        id
        type
        Resource {
          id
          name
        }
      }
    }
  }
`
export const CreateRole = gql`
  mutation CreateRole($organizationName: String!, $type: String!, $permissionIds: [Int!]) {
    createRole(organizationName: $organizationName, type: $type, permissionIds: $permissionIds) {
      idF
    }
  }
`

export const DeleteRoleById = gql`
  mutation DeleteRoleById($id: Int!) {
    deleteRoleById(id: $id) {
      id
    }
  }
`

export const UpdateRoleById = gql`
  mutation UpdateRoleById($id: Int!, $type: String, $permissionIds: [Int!]) {
    updateRoleById(id: $id, type: $type, permissionIds: $permissionIds) {
      id
    }
  }
`
