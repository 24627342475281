import { useMsal } from "@azure/msal-react"
import { Button, TextField } from "@mui/material"
import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { FormField } from "../../components/FormField"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { useAppSelector } from "../../redux/store/hooks"
import { useUpdateUserMutation } from "../../services/User"
import { FormState } from "./ProfileSetting.interfaces"

export const ProfileSetting: FC = (props) => {
  const { accounts } = useMsal()
  const user = useAppSelector((store) => store.auth.user)

  const [updateUser] = useUpdateUserMutation()

  const { control, handleSubmit, reset } = useForm<FormState>({ defaultValues: user })

  useEffect(() => {
    reset(user)
  }, [user, reset])

  if (!accounts[0]?.localAccountId) return <Message />
  if (!user) return <Loader />

  const onSubmit = (value: FormState) => {
    toast.promise(updateUser({ name: value.name, id: user?.id }).unwrap(), {
      error: (error) => error?.message ?? "Something went wrong",
      loading: "Updating",
      success: "Sucessfully Updating",
    })
  }

  return (
    <div className="flex-grow rounded-lg bg-white p-4 shadow-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-bold">Account Details</h3>
        <div className="-mx-2 mt-4 mb-2 flex flex-wrap">
          <div className="my-2 w-6/12 px-2">
            <FormField
              label="Name"
              rules={{ required: { message: "value is required", value: true } }}
              name="name"
              control={control}
            >
              <TextField size="small" variant="outlined" className="w-full" />
            </FormField>
          </div>
          <div className="my-2 w-6/12 px-2">
            <label className="mb-1 w-full">
              <span className="mb-1 block">Email</span>
              <TextField size="small" disabled variant="outlined" className="w-full" value={user?.email} />
            </label>
          </div>
        </div>
        <div className="text-right">
          <Button type="submit" variant="contained" className="mr-4">
            Update
          </Button>
        </div>
      </form>
    </div>
  )
}
