import { KeyboardArrowLeft } from "@mui/icons-material"
import { Button } from "@mui/material"
import { FC } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { ActionPanel } from "../../components/ActionPanel"
import { ProductForm } from "../../components/ProductForm"
import { FormState } from "../../components/ProductForm/ProductForm.interface"
import { generateRoute, URL_PRODUCT_LIST } from "../../router/routes"
import { useAddProductMutation } from "../../services/Products"

import { ProductNewProps } from "./ProductNew.interface"

export const ProductNew: FC<ProductNewProps> = (props) => {
  const { className } = props

  const navigate = useNavigate()
  const [addProduct] = useAddProductMutation()

  const onSubmit = async (value: FormState) => {
    toast
      .promise(addProduct(value).unwrap(), {
        error: (error) => error?.message ?? "Something went wrong",
        loading: "Please Wait",
        success: "Product Added Successfully",
      })
      .then(() => navigate(generateRoute(URL_PRODUCT_LIST)))
  }

  return (
    <div className={className}>
      <ActionPanel
        leftElement={
          <Link to={generateRoute(URL_PRODUCT_LIST)}>
            <Button>
              <KeyboardArrowLeft className="mr-1" />
              Cancel
            </Button>
          </Link>
        }
      />
      <div className={className}>
        <h5 className="font-bold">Enter Product Info</h5>
        <ProductForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}
