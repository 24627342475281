import { gql } from "graphql-request"

export const CreateCustomer = gql`
  mutation CreateCustomer(
    $billingEmail: String!
    $billingAddress: AddressInput!
    $phone: String
    $organizationName: String!
  ) {
    createCustomer(
      billingEmail: $billingEmail
      billingAddress: $billingAddress
      phone: $phone
      organizationName: $organizationName
    ) {
      id
    }
  }
`
export const RetrieveCustomer = gql`
  query RetrieveCustomer($organizationName: String!) {
    retrieveCustomer(organizationName: $organizationName) {
      address {
        city
        country
        line1
        line2
        postal_code
        state
      }
      balance
      created
      currency
      default_source
      delinquent
      description
      email
      id
      invoice_prefix
      livemode
      metadata {
        order_id
        email
      }
      name
      next_invoice_sequence
      object
      phone
      preferred_locales
      shipping {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        name
        phone
      }
      sources {
        has_more
        object
        url
      }
      subscriptions {
        has_more
        object
        url
      }
      tax_exempt
      tax_ids {
        has_more
        object
        url
      }
    }
  }
`
