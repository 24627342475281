import { useMsal } from "@azure/msal-react"
import { CorporateFare, Logout, Menu, Person } from "@mui/icons-material"
import { Button, Collapse, MenuItem } from "@mui/material"
import React, { useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { Avatar } from "../../components/Avatar"
import { Dropdown } from "../../components/Dropdown"
import { useAppSelector } from "../../redux/store/hooks"
import {
  AUTHENTICATED_ROUTES,
  generateRoute,
  URL_DASHBOARD,
  URL_ORGANIZATION_SETTING,
  URL_SETTING,
} from "../../router/routes"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { HeaderProps } from "./Header.interfaces"

export const Header: React.FC<HeaderProps> = () => {
  const { instance } = useMsal()
  const [expandNavbar, setExpandNavbar] = useState(false)

  return (
    <header className="relative mx-4 flex items-center justify-between border-b-2 py-2">
      <Link to={generateRoute(URL_DASHBOARD)}>
        <img src="/assets/logo.svg" className="mr-4 h-auto w-28" alt="logo" />
      </Link>
      <div className="flex flex-1 items-center justify-end md:justify-between">
        <div>
          {getOrganizationNameFromRoute() && (
            <>
              <Collapse
                onClickCapture={() => setExpandNavbar(!expandNavbar)}
                in={expandNavbar}
                classes={{ wrapperInner: "md:flex md:space-x-2" }}
                className="absolute top-full left-0 right-0 z-50 bg-white py-2 shadow-sm md:relative md:m-0 md:hidden md:w-[unset] md:bg-transparent md:shadow-none"
                unmountOnExit
              >
                <NavList />
              </Collapse>
              <span className="hidden space-x-2 md:inline">
                <NavList />
              </span>
            </>
          )}
        </div>

        <Dropdown trigger={<Avatar className="-m-2 rounded-none p-2" />}>
          <Link to={URL_SETTING} className="flex items-center text-body">
            <MenuItem className="w-full">
              <Person className="mr-2" />
              <div>Profile</div>
            </MenuItem>
          </Link>
          {getOrganizationNameFromRoute() && (
            <Link to={generateRoute(URL_ORGANIZATION_SETTING)} className="flex items-center text-body">
              <MenuItem className="flex w-full items-center">
                <CorporateFare className="mr-2" />
                <div>Organization Setting</div>
              </MenuItem>
            </Link>
          )}
          <MenuItem className="flex items-center" onClick={() => instance.logout()}>
            <Logout className="mr-2" />
            <div>Sign out</div>
          </MenuItem>
        </Dropdown>

        <Button onClick={() => setExpandNavbar(!expandNavbar)} className="ml-2 md:hidden">
          <Menu />
        </Button>
      </div>
    </header>
  )
}

const NavList = () => {
  const role = useAppSelector((store) => store.auth.role)
  const location = useLocation()

  return (
    <div className="flex flex-col items-center space-x-2 border-b-2 md:flex-row md:border-b-0">
      {AUTHENTICATED_ROUTES.filter((route) => route.render).map((route, index) => (
        <div key={route.name ?? index} className="w-full md:w-auto">
          {route.path ? (
            <NavLink to={generateRoute(route.path)}>
              {({ isActive }) => (
                <Button
                  fullWidth
                  size="small"
                  variant={isActive ? "contained" : undefined}
                  className={`rounded-none px-2 md:w-auto md:rounded-full ${!isActive && "text-body"}`}
                >
                  {route.name}
                </Button>
              )}
            </NavLink>
          ) : (
            <Button className="rounded-full">
              {route.name}
              <Dropdown trigger={<Button>{route.name}</Button>}>
                {route.children
                  ?.filter((route) => route.render && route.path)
                  ?.map((link) => (
                    <Link
                      key={link.path}
                      className={`dropdown-item ${location.pathname === generateRoute(link.path!) && "active"}`}
                      to={generateRoute(link.path!)}
                    >
                      <MenuItem key={link.name}>{link.name}</MenuItem>
                    </Link>
                  ))}
              </Dropdown>
            </Button>
          )}
        </div>
      ))}
    </div>
  )
}
