import { RemoveCircleOutline } from "@mui/icons-material"
import { Autocomplete, Button, createFilterOptions, IconButton, TextField } from "@mui/material"
import { FC } from "react"
import { useFieldArray, useForm, WatchObserver } from "react-hook-form"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { GADSL, useGADSLQuery } from "../../services/Products"
import { FormField } from "../FormField/FormField"
import { BOMInputProps, FormState, ProductFormProps } from "./ProductForm.interface"

export const ProductForm: FC<ProductFormProps> = (props) => {
  const { className, onSubmit, defaultValue } = props

  const form = useForm<FormState>({
    defaultValues: defaultValue ?? { SKU: "", name: "", substance: "", CAS: "", threshold: "" },
  })
  const { fields, append, remove } = useFieldArray({ control: form.control, name: "SubProduct" } as any)
  const gadsl = useGADSLQuery({})

  if (gadsl.isLoading) return <Loader />
  if (gadsl.isError) return <Message error={gadsl.error} />

  const transform = (value: FormState) => {
    onSubmit?.(value)
  }

  return (
    <form className={className} onSubmit={form.handleSubmit(transform)}>
      <div className="flex items-end p-2">
        <div className="mr-2 flex items-center space-x-2">
          <Input field="" form={form} />
        </div>
      </div>

      <div className="relative space-y-2 p-2">
        {fields.length ? (
          fields.map((field, index) => (
            <>
              <Input
                key={field.id}
                index={index}
                field={`SubProduct.${index}`}
                onRemove={() => remove(index)}
                canRemove
                form={form}
              />
              {index === fields.length - 1 && (
                <div className="relative ml-5 flex items-center pt-5">
                  <div className="absolute top-0 h-9 border-l-2 border-dotted border-neutral-500" />
                  <div className="w-5 border-b-2 border-dotted border-neutral-500" />
                  <Button
                    className="ml-2"
                    variant="contained"
                    onClick={() => append({ SKU: "", name: "", substance: "", CAS: "", threshold: "" })}
                  >
                    Add BOM
                  </Button>
                </div>
              )}
            </>
          ))
        ) : (
          <div className="relative ml-5 flex items-center pt-5">
            <div className="absolute top-0 h-9 border-l-2 border-dotted border-neutral-500" />
            <div className="w-5 border-b-2 border-dotted border-neutral-500" />
            <Button
              className="ml-2"
              variant="contained"
              onClick={() => append({ SKU: "", name: "", substance: "", CAS: "", threshold: "" })}
            >
              Add BOM
            </Button>
          </div>
        )}
      </div>
      <div className="text-right">
        <Button type="submit" variant="contained">
          {defaultValue ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  )
}

const substanceoptions = createFilterOptions<GADSL & { isNew?: boolean }>({ trim: true })

const Input: FC<BOMInputProps> = (props) => {
  const { onRemove, index, field, className, canRemove, form } = props

  const substance = form.watch(`${field ? `${field}.` : ""}substance` as unknown as WatchObserver<FormState>)
  const cas = form.watch(`${field ? `${field}.` : ""}CAS` as unknown as WatchObserver<FormState>)
  const gadsl = useGADSLQuery({})

  return (
    <div className={`relative flex w-full items-end space-x-2 ${className} ${index !== undefined && "ml-5 pl-5"}`}>
      {index !== undefined && (
        <div className="absolute top-px -bottom-2 left-0 border-l-2 border-dotted border-neutral-500" />
      )}
      {index !== undefined && (
        <div className="absolute -left-1 bottom-10 w-5 border-b-2 border-dotted border-neutral-500" />
      )}

      <FormField
        label="Part Number"
        name={`${field ? `${field}.` : ""}SKU`}
        control={form.control as any}
        keepErrorSpace
      >
        <TextField size="small" variant="outlined" />
      </FormField>

      <FormField
        label="Description"
        name={`${field ? `${field}.` : ""}name`}
        control={form.control as any}
        keepErrorSpace
      >
        <TextField size="small" variant="outlined" />
      </FormField>

      <FormField
        rules={{ validate: (value) => !!value || !!cas || "value is required" }}
        label="Substance"
        name={`${field ? `${field}.` : ""}substance`}
        control={form.control as any}
        keepErrorSpace
        transform={(_, gadsl: GADSL) => gadsl.substance}
        onChange={(_, gadsl: GADSL | undefined) => {
          if (gadsl?.cas) form.setValue(`${field ? `${field}.` : ""}CAS` as any, String(gadsl.cas))
          if (gadsl?.reportingThreshold)
            form.setValue(`${field ? `${field}.` : ""}threshold` as any, String(gadsl.reportingThreshold))
        }}
      >
        <Autocomplete
          options={gadsl.data ?? []}
          freeSolo
          autoSelect
          handleHomeEndKeys
          getOptionLabel={(item) => (typeof item === "string" ? item : item.substance)}
          renderOption={(props, option: GADSL & { isNew?: boolean }) => (
            <li {...props}>{option.isNew ? `Add ${option.substance}` : option.substance}</li>
          )}
          renderInput={(props) => <TextField {...props} variant="outlined" />}
          filterOptions={(options, params) => {
            const { inputValue: value } = params

            const data = substanceoptions(options, params)
            const isExisting = options.some((option) => value === option.substance)

            if (value !== "" && !isExisting) data.push({ substance: value, isNew: true, cas: "", classification: "" })

            return data
          }}
        />
      </FormField>

      <FormField
        rules={{ validate: (value) => !!value || !!substance || "value is required" }}
        label="CAS Number"
        name={`${field ? `${field}.` : ""}CAS`}
        control={form.control as any}
        keepErrorSpace
        onChange={(value) => {
          const found = gadsl.data?.find((item) => item.cas === value)
          if (found?.reportingThreshold)
            form.setValue(`${field ? `${field}.` : ""}threshold` as any, String(found.reportingThreshold))
        }}
      >
        <TextField size="small" variant="outlined" />
      </FormField>

      <FormField
        label="Threshold"
        name={`${field ? `${field}.` : ""}threshold`}
        control={form.control as any}
        keepErrorSpace
      >
        <TextField size="small" variant="outlined" />
      </FormField>

      {canRemove && (
        <IconButton onClick={onRemove} className="mb-4">
          <RemoveCircleOutline />
        </IconButton>
      )}
    </div>
  )
}
