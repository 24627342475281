import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Button, Card } from "@mui/material"
import { FC, useEffect } from "react"
import { FieldError, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { FormQuestion } from "../../pages/QuestionnaireDetail/QuestionnaireDetail.interfaces"
import { ActionPanel } from "../ActionPanel"
import { FormField } from "../FormField"
import { Question } from "../Question/Question"
import { FormAnswer } from "../Question/Question.interface"
import { SectionQuestionsProps } from "./SectionQuestions.interface"

export const SectionQuestions: FC<SectionQuestionsProps> = (props) => {
  const { className, section, onNext, onPrev, isFirst, isLast, disabled, onAnswer, value } = props

  const form = useForm<{ answers: Record<FormQuestion["id"], FormAnswer> }>({
    defaultValues: {
      answers: value
        ? value.reduce(
            (acc, answer) => ({ ...acc, [String(answer.Question.id)]: answer }),
            {} as Record<FormQuestion["id"], FormAnswer>
          )
        : section.Questions.reduce(
            (acc, item) => ({ ...acc, [String(item.id)]: { optionId: -1, attachmentUrls: [] } }),
            {} as Record<FormQuestion["id"], FormAnswer>
          ),
    },
  })

  useEffect(() => {
    const firsterror = (Object.values(form.formState.errors?.answers ?? {}).at(0) as FieldError | undefined)?.message
    if (firsterror) toast.error(firsterror === "Please select an option" ? "Please answer all questions" : firsterror)
  }, [form.formState.submitCount])

  return (
    <div>
      <Card className={`${className} space-y-10 p-4`}>
        {section.Questions.map((question, index) => (
          <FormField
            key={index}
            control={form.control}
            name={`answers.${question.id}` as any}
            hideError
            noLabelTag
            onChange={(answer) => onAnswer?.(question, answer)}
            rules={{
              validate: (value: any) => value?.optionId >= 0 || "Please select an option",
            }}
          >
            <Question disabled={!disabled} question={question} key={question.id} index={index + 1} />
          </FormField>
        ))}
      </Card>

      <div className="mt-4 px-4">
        <ActionPanel
          className="min-h-auto mb-0"
          leftElement={
            !isFirst && (
              <Button startIcon={<ChevronLeft />} variant="contained" onClick={() => onPrev(form.getValues())}>
                Previous
              </Button>
            )
          }
          rightElement={
            <>
              {disabled && <div className="small mr-2 self-center text-muted">automatically saves on submit</div>}
              {(disabled || (!disabled && !isLast)) && (
                <Button
                  endIcon={<ChevronRight />}
                  variant="contained"
                  onClick={form.handleSubmit(({ answers }) => onNext(answers))}
                >
                  {isLast ? "Submit" : "Next"}
                </Button>
              )}
            </>
          }
        />
      </div>
    </div>
  )
}
