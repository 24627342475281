import { MenuItem, Select } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { ActionPanel } from "../../components/ActionPanel"
import { EditTable } from "../../components/EditTable"
import { Column, Datum } from "../../components/EditTable/EditTable.interfaces"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { Permission, usePermissionAllQuery } from "../../services/Permission"
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useListRoleQuery,
  useUpdateRoleMutation,
} from "../../services/Role"
import { FormState, RolesandpermissionsProps } from "./Rolesandpermissions.interfaces"

export const Rolesandpermissions: FC<RolesandpermissionsProps> = () => {
  const [data, setData] = useState<FormState[]>([])

  const roleByOrganization = useListRoleQuery()

  useEffect(() => {
    if (roleByOrganization.data) setData(roleByOrganization.data)
  }, [roleByOrganization.data])

  const permissionAll = usePermissionAllQuery()

  const [createRole] = useCreateRoleMutation()
  const [deleteRole] = useDeleteRoleMutation()
  const [updateRole] = useUpdateRoleMutation()

  const columns: Column<FormState>[] = [
    {
      headerName: "Role Name",
      field: "type",
    },
    {
      headerName: "Permission",
      field: "permissionIds",
      renderEditCell: ({ value, row }) => {
        const permissions = row.Permissions ?? []

        return (
          <Select
            size="small"
            onChange={(option) => {
              const options = option as unknown as { text: string; value: string | number }[] | undefined
              const selected = permissionAll.data?.filter(({ id }) => options?.some((option) => option.value === id))

              const newValue: FormState[] = data.map((item) => {
                return item.id !== row.id ? { ...item, permissionIds: selected } : item
              })

              setData(newValue)
            }}
            fullWidth
            value={(value?.length ? (value as Permission[]) : permissions).map(({ id }) => id)}
            multiple
          >
            {(
              permissionAll.data?.map((permiss) => {
                const { type, id, Resource } = permiss

                return {
                  label: `${Resource.name} ${type}` ?? "",
                  value: id,
                  selected: (value?.length ? (value as Permission[]) : permissions).some(
                    (permission) => permission.id === id
                  ),
                }
              }) ?? []
            ).map(({ value, selected, label }) => (
              <MenuItem key={value} value={value} selected={selected}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )
      },
      renderCell: ({ value, row }) => {
        const permission = row.Permissions ?? []

        return (
          <div className="text-truncate">
            {(value?.length ? (value as Permission[]) : permission)
              .map(({ Resource, type }) => `${Resource.name} ${type}`)
              .join(", ") ?? "No Access"}
          </div>
        )
      },
    },
  ]

  const onAdd = async ({ id, ...data }: Datum<FormState>) => {
    await createRole({ type: data.type, permissionIds: data.permissionIds?.map(({ id }) => id) }).unwrap()
  }

  const onRemove = async ({ id }: Datum<FormState>) => {
    await deleteRole({ id }).unwrap()
  }

  const onUpdate = async ({ id, Access, Organizations, Permissions, Users, ...data }: Datum<FormState>) => {
    await updateRole({
      ...data,
      id: id,
      permissionIds: data.permissionIds?.map((permission) => permission.id),
    }).unwrap()
  }

  if (permissionAll.isLoading || roleByOrganization.isLoading) return <Loader />
  if (permissionAll.isError || roleByOrganization.isError)
    return <Message error={permissionAll.isError ?? roleByOrganization.error} />

  return (
    <div>
      <ActionPanel />

      <div className="rounded-lg bg-white shadow-sm">
        <EditTable<FormState>
          columns={columns}
          data={data.map((role) => ({
            ...role,
            canDelete: role.type !== "admin" && role.type !== "collaborator",
            canEdit: role.type !== "admin",
          }))}
          addnewtext="Add New Role"
          // onChange={(data) => setData(data)}
          onAdd={onAdd}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  )
}
