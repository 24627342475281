import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { ResourceEnum } from "../Permission"
import { GetEmissions } from "./Emission.documents"
import { Emission } from "./Emission.interfaces"

export const emissionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listEmission: builder.query<Emission[], void>({
      query: () => ({ document: GetEmissions }),
      extraOptions: { headers: { resource: ResourceEnum.Emission, operation: PermissionEnum.Read } },
    }),
  }),
})

export const { useListEmissionQuery, useLazyListEmissionQuery } = emissionApi
