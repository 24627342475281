import { ArrowBack, ChevronRight, Settings } from "@mui/icons-material"
import { Button, Card } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { useDidUpdate } from "../../hooks/useDidUpdate"
import { useAppSelector } from "../../redux/store/hooks"
import { AUTHENTICATED_ROUTES, generateRoute, URL_HOME, URL_ORGANIZATION_SETTING } from "../../router/routes"
import { useOrganizationQuery } from "../../services/Organization"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourcesListProps } from "./ResourcesList.interfaces"

export const ResourcesList: FC<ResourcesListProps> = (props) => {
  const { className } = props
  const role = useAppSelector((store) => store.auth.role)

  const orgByName = useOrganizationQuery()

  useDidUpdate(() => {
    // TODO: remove call on empty route
    if (getOrganizationNameFromRoute()!) orgByName.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganizationNameFromRoute()!])

  if (orgByName.isLoading) return <Loader />
  if (orgByName.isError) return <Message error={orgByName.error} />

  return (
    <div className={className}>
      <div className="mb-5 flex items-center justify-between">
        <Link to={URL_HOME}>
          <Button startIcon={<ArrowBack />}>Back</Button>
        </Link>

        {role?.type === "admin" && (
          <Link to={generateRoute(URL_ORGANIZATION_SETTING)}>
            <Button variant="contained">
              <Settings className="mr-1" />
              Settings
            </Button>
          </Link>
        )}
      </div>

      <h3 className="font-bold">{orgByName.data?.businessName}</h3>
      <div className="mb-4 text-muted">Select a service</div>
      <div className="-mx-2 flex flex-wrap">
        {AUTHENTICATED_ROUTES.map(
          (route) =>
            route.render && (
              <Link
                key={generateRoute(route.path ?? "")}
                to={generateRoute(route.path ?? "")}
                className="p-2 sm:w-6/12 md:w-4/12 xl:w-3/12"
              >
                <Card className="card-hover flex h-full flex-wrap justify-between transition-shadow duration-200 ease-linear  hover:shadow-md">
                  <div className="flex-1">
                    <h5 className="font-bold text-primary">{route.name}</h5>
                    <div>{route.description}</div>
                  </div>
                  <ChevronRight className="self-center" />
                </Card>
              </Link>
            )
        )}
      </div>
    </div>
  )
}
