import { Route } from "react-router-dom"
import { PathRoute } from "./routes"

export const RecursiveRouteMapper = (routes: PathRoute[]) => {
  return routes.map((route, index) => (
    <Route
      caseSensitive={route.caseSensitive}
      index={route.index}
      key={route.path ?? index}
      path={route.path}
      element={route.element}
    >
      {route.children ? RecursiveRouteMapper(route.children) : null}
    </Route>
  ))
}
