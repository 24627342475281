import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { PermissionAll } from "./Permission.documents"
import { Permission, ResourceEnum } from "./Permission.interfaces"

export const permissionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    permissionAll: builder.query<Permission[], void>({
      query: () => ({ document: PermissionAll, variables: { name: getOrganizationNameFromRoute()! } }),
      extraOptions: { headers: { resource: ResourceEnum.Role, operation: PermissionEnum.Read } },
    }),
  }),
})

export const { useLazyPermissionAllQuery, usePermissionAllQuery } = permissionApi
