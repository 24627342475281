import { useMsal } from "@azure/msal-react"
import { FC, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { passwordResetRequest } from "../../authConfig"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { setInvite } from "../../redux/slice"
import { useAppSelector } from "../../redux/store/hooks"
import { URL_HOME, URL_INVITE } from "../../router/routes"
import { useValidateInvitationMutation } from "../../services/Organization"

import { MagicPageProps } from "./MagicPage.interface"

export const MagicPage: FC<MagicPageProps> = (props) => {
  const { className } = props

  const user = useAppSelector((store) => store.auth.user)

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { instance } = useMsal()

  const token = searchParams.get("token")

  const [validateInvitation, validateInvitationResp] = useValidateInvitationMutation()

  useEffect(() => {
    if (location.pathname === URL_INVITE) {
      if (token)
        validateInvitation({ token })
          .unwrap()
          .then(({ validateInvitation: { adId, invite } }) => {
            if (invite) dispatch(setInvite(invite))

            if (!user) setTimeout(async () => instance.loginRedirect(passwordResetRequest), 2000)
            else navigate(URL_HOME, { replace: true })
          })
    }
  }, [searchParams, instance, validateInvitation])

  if (!token) return <Message title="Found no token" />

  if (validateInvitationResp.isError) return <Message error={validateInvitationResp.error} />

  return <Loader />
}
