import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { graphClient } from "../../../services/graphBaseQuery"
import { Invite } from "../../../services/Question"
import { Role } from "../../../services/Role"
import { User } from "../../../services/User"
import { AuthStore } from "./Auth.interface"

const initialState: AuthStore = {
  user: undefined,
  role: undefined,
  invite: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      localStorage.clear()
      sessionStorage.clear()
      return initialState
    },
    setUser: (state, { payload }: PayloadAction<User>): AuthStore => {
      graphClient.setHeader("adid", payload.adId)
      return { ...state, user: payload }
    },
    setRole: (state, { payload }: PayloadAction<Role>): AuthStore => {
      return { ...state, role: payload }
    },
    setInvite: (state, { payload }: PayloadAction<Invite | undefined>): AuthStore => {
      return { ...state, invite: payload }
    },
  },
})

export const { reset, setUser, setRole, setInvite } = authSlice.actions
export default authSlice.reducer
