export const PRICEID = {
  actionPlan: {
    test: "price_1KzunKLZpLrpnF38JPFGomzx",
    development: "price_1KzunKLZpLrpnF38JPFGomzx",
    production: "price_1LWsNzLZpLrpnF38lIlTtbJW",
  },
  actionPlanSeat: {
    test: "price_1KzumvLZpLrpnF38alruUlZF",
    development: "price_1KzumvLZpLrpnF38alruUlZF",
    production: "price_1LWsO4LZpLrpnF38M5duyRs2",
  },
}

export const EOL = [
  {
    text: "Recycling - breaking down the product into its raw materials and using them to make new products.",
    value: "Recycling",
  },
  { text: "Reuse - using the product as-is or modifying it to meet new requirements or purposes.", value: "Reuse" },
  { text: "Refurbishment - repairing and restoring a product to a like-new condition.", value: "Refurbishment" },
  {
    text: "Disposal - getting rid of the product in a safe and responsible manner, such as through landfilling, incineration, or other waste management techniques.",
    value: "Disposal",
  },
  { text: "Upcycling - converting the product into a new item of higher value.", value: "Upcycling" },
]

export const STATUSOPTIONS = [
  { text: "Not Started", value: "notStarted" },
  { text: "Started", value: "started" },
  { text: "Completed", value: "completed" },
]

export const SUSTAINABILITYTYPE = [
  { text: "Assessment", value: "assessment" },
  { text: "Education and Outreach", value: "educationAndOutreach" },
  { text: "Program", value: "program" },
  { text: "Incentive", value: "incentive" },
  { text: "Policy and Ordinance", value: "policyAndOrdinance" },
  { text: "Building and Infrastructure", value: "buildingAndInfrastructure" },
  { text: "Plan Development", value: "planDevelopment" },
  { text: "Process Improvement", value: "processImprovement" },
  { text: "Other", value: "other" },
]

export const PROGRAMLEAD = [
  { text: "Purchasing", value: "purchasing" },
  { text: "Sustainability", value: "sustainability" },
  { text: "Council", value: "council" },
  { text: "Municipal Services", value: "municipalServices" },
  { text: "Utility Services", value: "utilityServices" },
  { text: "Fire Services", value: "fireServices" },
  { text: "Community Improvement or Housing", value: "communityImprovementOrHousing" },
  { text: "Fire Services", value: "fireServices" },
  { text: "Transportation", value: "transportation" },
  { text: "Sanitation", value: "sanitation" },
  { text: "Police", value: "police" },
  { text: "Recreation", value: "recreation" },
  { text: "Development Services", value: "developmentServices" },
  { text: "City Managers Office", value: "cityManagersOffice" },
  { text: "Human Resources", value: "humanResources" },
  { text: "Financial Services", value: "financialServices" },
]

export const COBENEFITS = [
  { text: "GHG Reduction", value: "ghgReduction" },
  { text: "Economic", value: "economic" },
  { text: "Health and Wellbeing", value: "healthAndWellbeing" },
  { text: "Resiliency", value: "resiliency" },
  { text: "Equity", value: "equity" },
  { text: "Biodiversity", value: "biodiversity" },
  { text: "Environmented Quality", value: "environmentedQuality" },
]

export const TERMS = [
  { text: "Short", value: "short" },
  { text: "Long", value: "long" },
  { text: "Medium", value: "medium" },
]

export const sectors = [
  { text: "Residential Energy", value: "residentialEnergy" },
  { text: "Industrial Energy", value: "industrialEnergy" },
  { text: "Commercial Energy", value: "commercialEnergy" },
  { text: "Process and Fugitive Emissions", value: "processAndFugitiveEmissions" },
  { text: "Transportation and Mobile Soures", value: "transportationAndMobileSoures" },
  { text: "Solid Waste", value: "solidWaste" },
  { text: "Water", value: "water" },
]

export const SCOPES = [
  { text: "Scope 1", value: "scope1" },
  { text: "Scope 2", value: "scope2" },
  { text: "Scope 3", value: "scope3" },
]

export const ACTIONCHAPTER = [
  { text: "Resource Use", value: "resourceUse" },
  { text: "Waste", value: "waste" },
  { text: "Natural Environment", value: "naturalEnvironment" },
  { text: "Built Environment", value: "builtEnvironment" },
  { text: "Transportation", value: "transportation" },
  { text: "Climate Resilience", value: "climateResilience" },
  { text: "Government", value: "government" },
]

export const UNITS = [
  { text: "Percent", value: "percent" },
  { text: "Ten Pack", value: "tenPack" },
  { text: "Twelve Pack", value: "twelvePack" },
  { text: "Acre", value: "acre" },
  { text: "Annual Salary(rounded)", value: "annualSalaryRounded" },
  { text: "Annual Salary(truncated)", value: "annualSalaryTruncated" },
  { text: "Bar Absolute (Pressure)", value: "barAbsolutePressure" },
  { text: "Bag", value: "bag" },
  { text: "Bar Gauge (Pressure)", value: "barGaugePressure" },
  { text: "Bucket", value: "bucket" },
  { text: "Barrels", value: "barrels" },
  { text: "Bottles", value: "bottles" },
  { text: "Bushel", value: "bushel" },
  { text: "Box", value: "box" },
  { text: "Case", value: "case" },
  { text: "Cubic Centimeter", value: "cubicCentimeter" },
  { text: "Hundred Feet", value: "hundredFeet" },
  { text: "Cubic Inches", value: "cubicInches" },
  { text: "Centiliters", value: "centiliters" },
  { text: "Centimeters", value: "centimeters" },
  { text: "Can", value: "can" },
  { text: "Container", value: "container" },
  { text: "Carton", value: "carton" },
  { text: "Hundred Square Feet", value: "hundredSquareFeet" },
  { text: "Carat", value: "carat" },
  { text: "Cubic Meter", value: "cubicMeter" },
  { text: "Hundredweight", value: "hundredweight" },
  { text: "Cubic Yard", value: "cubicYard" },
  { text: "Decimeters", value: "decimeters" },
  { text: "Deciliter", value: "deciliter" },
  { text: "drums", value: "drums" },
  { text: "Penny Weight", value: "pennyWeight" },
  { text: "Days", value: "days" },
  { text: "Dozen", value: "dozen" },
  { text: "Each", value: "each" },
  { text: "Cubic Feet", value: "cubicFeet" },
  { text: "Feet/Inches/16th", value: "feetInches16Th" },
  { text: "Fluid Ounce", value: "fluidOunce" },
  { text: "Feet", value: "feet" },
  { text: "100 Gallons", value: "100Gallons" },
  { text: "Gallons", value: "gallons" },
  { text: "Giga Joule", value: "gigaJoule" },
  { text: "Grams", value: "grams" },
  { text: "Potent Gallons", value: "potentGallons" },
  { text: "Gross", value: "gross" },
  { text: "Half Hour Calculations", value: "halfHourCalculations" },
  { text: "Average Hourly Rate", value: "averageHourlyRate" },
  { text: "Head Count", value: "headCount" },
  { text: "Hours X Hours Worked", value: "hoursXHoursWorked" },
  { text: "Hectoliter", value: "hectoliter" },
  { text: "Hours per period (zero $)", value: "hoursPerPeriodZero" },
  { text: "Hour", value: "hour" },
  { text: "Hectare", value: "hectare" },
  { text: "Hours x Hrs worked (zero $)", value: "hoursXHrsWorkedZero" },
  { text: "Cubic Inches", value: "cubicInches" },
  { text: "Imperial Gallons", value: "imperialGallons" },
  { text: "Inches", value: "inches" },
  { text: "Joints", value: "joints" },
  { text: "Kilograms", value: "kilograms" },
  { text: "Kiloliter .", value: "kiloliter" },
  { text: "Kilometers", value: "kilometers" },
  { text: "Karat", value: "karat" },
  { text: "Kilowatt Hour", value: "kilowattHour" },
  { text: "Pounds per square inch", value: "poundsPerSquareInch" },
  { text: "Pounds", value: "pounds" },
  { text: "Cubic Liters", value: "cubicLiters" },
  { text: "Linear Feet", value: "linearFeet" },
  { text: "Log", value: "log" },
  { text: "Potent Liters", value: "potentLiters" },
  { text: "Lump Sum", value: "lumpSum" },
  { text: "Liters", value: "liters" },
  { text: "Cubic Meters", value: "cubicMeters" },
  { text: "Thousand Feet", value: "thousandFeet" },
  { text: "Milligrams", value: "milligrams" },
  { text: "Man Hour", value: "manHour" },
  { text: "Miles", value: "miles" },
  { text: "Micrograms", value: "micrograms" },
  { text: "Milliliter", value: "milliliter" },
  { text: "Millimeters .", value: "millimeters" },
  { text: "Minutes", value: "minutes" },
  { text: "Months", value: "months" },
  { text: "Thousand Pieces", value: "thousandPieces" },
  { text: "Thousand Square Feet", value: "thousandSquareFeet" },
  { text: "Meters", value: "meters" },
  { text: "Thousand Pounds", value: "thousandPounds" },
  { text: "Potent Ounces", value: "potentOunces" },
  { text: "Troy Ounces", value: "troyOunces" },
  { text: "Ounces", value: "ounces" },
  { text: "Pieces", value: "pieces" },
  { text: "Pad", value: "pad" },
  { text: "Package", value: "package" },
  { text: "Pallet", value: "pallet" },
  { text: "Pair", value: "pair" },
  { text: "Pint", value: "pint" },
  { text: "Quart", value: "quart" },
  { text: "Rental Deposit", value: "rentalDeposit" },
  { text: "Roll", value: "roll" },
  { text: "Ream", value: "ream" },
  { text: "$1000s of Salary", value: "1000SOfSalary" },
  { text: "Square Centimeters", value: "squareCentimeters" },
  { text: "Seconds", value: "seconds" },
  { text: "Square Feet", value: "squareFeet" },
  { text: "Square Inches", value: "squareInches" },
  { text: "Skein", value: "skein" },
  { text: "Sleeve", value: "sleeve" },
  { text: "Square Meters", value: "squareMeters" },
  { text: "Spaces", value: "spaces" },
  { text: "Eaches", value: "eaches" },
  { text: "Six Pack", value: "sixPack" },
  { text: "Square Yard", value: "squareYard" },
  { text: "Tank", value: "tank" },
  { text: "Long Ton (English)", value: "longTonEnglish" },
  { text: "Metric Ton", value: "metricTon" },
  { text: "Ton (U.S.)", value: "tonUS" },
  { text: "Short Ton (U.S.)", value: "shortTonUS" },
  { text: "Units", value: "units" },
  { text: "Weeks", value: "weeks" },
  { text: "Weld Units", value: "weldUnits" },
  { text: "Excess Life", value: "excessLife" },
  { text: "Yards", value: "yards" },
  { text: "Year", value: "year" },
  { text: "Set (3-PW)", value: "set3Pw" },
]

export const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

export const RADIAN = Math.PI / 180

export const ICONS: Record<string, string | JSX.Element> = {}
