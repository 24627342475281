import { ExpandMore } from "@mui/icons-material"
import { Card, List, ListItemButton, ListSubheader } from "@mui/material"
import { FC } from "react"
import { QuestionnaireSectionsProps } from "./QuestionnaireSections.interface"

export const QuestionnaireSections: FC<QuestionnaireSectionsProps> = (props) => {
  const { className, sections, selected, onClick, flatsections, activeIndex, status } = props

  return (
    <Card className={`sticky top-10 whitespace-nowrap ${className}`}>
      <List>
        <ListSubheader className="mb-2 leading-normal">Sections</ListSubheader>
        {sections.map((section) => (
          <div key={section.id}>
            <div className="flex">
              <ListItemButton
                selected={section.id === selected.id}
                disabled={status !== "completed" && (activeIndex ?? selected.index) < section.index}
                className="justify-between"
                onClick={() => onClick?.(section)}
              >
                {section.name} {!!section.SubSection.length && <ExpandMore />}
              </ListItemButton>
            </div>

            {!!section.SubSection.length && (
              <List component="div" disablePadding className="pl-4">
                {section.SubSection.map((subsection) => {
                  return (
                    <ListItemButton
                      disabled={status !== "completed" && (activeIndex ?? selected.index) < subsection.index}
                      key={subsection.id}
                      selected={subsection.id === selected.id}
                      onClick={() => onClick?.(subsection)}
                    >
                      {subsection.name}
                    </ListItemButton>
                  )
                })}
              </List>
            )}
          </div>
        ))}
      </List>
    </Card>
  )
}
