import { AddressParam } from "@stripe/stripe-js"
import Stripe from "stripe"
import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import { CreateCustomer, RetrieveCustomer } from "./Customer.documents"

export const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    Customer: builder.query<Stripe.Customer, void>({
      query: () => ({
        document: RetrieveCustomer,
        variables: {
          organizationName: getOrganizationNameFromRoute()!,
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Payment, operation: PermissionEnum.Create } },
      providesTags: ["Subscription"],
    }),
    createCustomer: builder.mutation<
      Stripe.Customer,
      { billingEmail: string; billingAddress: AddressParam; phone?: string }
    >({
      query: (variables) => ({
        document: CreateCustomer,
        variables: {
          ...variables,
          organizationName: getOrganizationNameFromRoute()!,
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Payment, operation: PermissionEnum.Create } },
      invalidatesTags: ["Subscription"],
    }),
  }),
})

export const { useCustomerQuery, useLazyCustomerQuery, useCreateCustomerMutation } = customerApi
