import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import { CreateRole, DeleteRoleById, RoleByOrganization, UpdateRoleById } from "./Role.documents"
import { Role } from "./Role.interfaces"

export const roleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listRole: builder.query<Role[], void>({
      query: () => ({ document: RoleByOrganization, variables: { name: getOrganizationNameFromRoute()! } }),
      extraOptions: { headers: { resource: ResourceEnum.Role, operation: PermissionEnum.Read } },
      providesTags: ["Role"],
    }),
    createRole: builder.mutation<any, { type?: string; permissionIds?: number[] }>({
      query: (variables) => ({
        document: CreateRole,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Role, operation: PermissionEnum.Create } },
      invalidatesTags: ["Role"],
    }),
    deleteRole: builder.mutation<any, { id: number }>({
      query: (variables) => ({ document: DeleteRoleById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Role, operation: PermissionEnum.Delete } },
      invalidatesTags: ["Role"],
    }),
    updateRole: builder.mutation<any, { id: number; permissionIds?: number[]; type?: string }>({
      query: (variables) => ({
        document: UpdateRoleById,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Role, operation: PermissionEnum.Update } },
      invalidatesTags: ["Role"],
    }),
  }),
})

export const {
  useLazyListRoleQuery,
  useListRoleQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
} = roleApi
