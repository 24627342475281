import { ChevronRight, KeyboardArrowLeft } from "@mui/icons-material"
import { Button } from "@mui/material"
import { GraphQLError } from "graphql"
import { isEmpty } from "lodash"
import { FC, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { ActionPanel } from "../../components/ActionPanel"
import { Datum } from "../../components/EditTable/EditTable.interfaces"
import { ActionPlanCollaboratorForm } from "../../containers/ActionPlanCollaborator"
import { FormState as FormStateCollaborator } from "../../containers/ActionPlanCollaborator/ActionPlanCollaborator.interfaces"
import { ActionPlanDetailForm } from "../../containers/ActionPlanDetail"
import { FormState as FormStateDetail } from "../../containers/ActionPlanDetail/ActionPlanDetail.interfaces"
import { ActionPlanInventoryInputsForm } from "../../containers/ActionPlanInventoryInputs"
import { useConfirmLeaving } from "../../hooks/useConfirmLeaving"
import { generateRoute, URL_ACTIONPLAN_DASHBOARD, URL_ACTIONPLAN_LIST } from "../../router/routes"
import { useCreateActionPlanMutation } from "../../services/ActionPlan"
import { InventoryInput, useCreateInventoryInputsMutation } from "../../services/InventoryInput"
import { useInviteManyUserMutation } from "../../services/User"
import { Optional } from "../../types/types"

import { ActionPlanFormProps } from "./ActionPlanForm.interface"

export type IActionPlanFormState = {
  Detail?: FormStateDetail
  "Inventory Inputs"?: Datum<InventoryInput>[]
  Collaborators?: Optional<Datum<FormStateCollaborator>, "adId" | "Access" | "ActionPlans" | "Campaigns">[]
}

enum STEP {
  "Detail",
  "Inventory Inputs",
  "Collaborators",
}

export const ActionPlanForm: FC<ActionPlanFormProps> = (props) => {
  const { className } = props
  const navigate = useNavigate()

  const [data, setData] = useState<IActionPlanFormState>({
    Detail: undefined,
    "Inventory Inputs": [],
    Collaborators: [],
  })
  const [formRef, setFormRef] = useState<UseFormReturn<FormStateDetail> | null>(null)
  const [step, setStep] = useState<keyof typeof STEP>("Detail")

  const [createActionPlan] = useCreateActionPlanMutation()
  const [createManyInventoryInput] = useCreateInventoryInputsMutation()
  const [inviteManyUser] = useInviteManyUserMutation()

  useConfirmLeaving()

  const CreateAction = async (data: Required<IActionPlanFormState>) => {
    const { "Inventory Inputs": inventoryInputs, Collaborators: users, Detail: detail } = data
    const actionPlan = await createActionPlan({
      ...detail,
      benefits: detail.benefits?.replace(/,/g, " "),
      cost: detail.cost?.replace(/,/g, ""),
    }).unwrap()

    if (actionPlan.errors) throw actionPlan.errors ?? new GraphQLError("Failed to create ActionPlan")

    if (users.length) {
      await inviteManyUser({
        data: users?.map(({ name, email, roleId }) => ({
          name,
          email,
          roleId,
          actionPlanId: actionPlan.id,
        })),
      })
        .unwrap()
        .catch((e: any) => toast.error(e.message ?? "Error while creating User"))
    }

    await createManyInventoryInput({
      data: inventoryInputs?.map(({ id, ...inventory }) => ({
        ...inventory,
        actionPlanId: actionPlan.id,
      })),
    })
      .unwrap()
      .catch((e: any) => toast.error(e.message ?? "Error while creating Inventory"))

    return actionPlan
  }

  const onSubmit = async () => {
    if (data.Detail && data["Inventory Inputs"] && data.Collaborators) {
      toast
        .promise(CreateAction(data as Required<IActionPlanFormState>), {
          error: (error) => error?.message ?? "Something went wrong",
          loading: "Please Wait",
          success: "Successfully Created",
        })
        .then(
          (actionPlan) => actionPlan && navigate(generateRoute(URL_ACTIONPLAN_DASHBOARD, { id: String(actionPlan.id) }))
        )
    }
  }

  const onNext = (data?: FormStateDetail) => {
    if (data) setData((prev) => ({ ...prev, Detail: data as FormStateDetail }))

    if (step !== "Collaborators") return setStep(STEP[STEP[step] + 1] as keyof typeof STEP)

    onSubmit()
  }

  const onBack = () => {
    setStep(STEP[STEP[step] - 1] as keyof typeof STEP)
  }

  return (
    <div className={className}>
      <div>
        <ActionPanel
          leftElement={
            step === "Detail" ? (
              <Link to={generateRoute(URL_ACTIONPLAN_LIST)}>
                <Button onClick={onBack}>
                  <KeyboardArrowLeft className="mr-1" />
                  Cancel
                </Button>
              </Link>
            ) : (
              <Button onClick={onBack}>
                <KeyboardArrowLeft className="mr-1" />
                Back
              </Button>
            )
          }
          rightElement={
            <Button
              variant="contained"
              onClick={formRef?.handleSubmit(onNext)}
              disabled={step !== "Detail" && step !== "Collaborators" && isEmpty(data[step])}
            >
              Next
              <ChevronRight className="ml-2" />
            </Button>
          }
        />
      </div>

      <div>
        {step === "Detail" && (
          <ActionPlanDetailForm
            editMode
            formInstance={(ref) => setFormRef(ref)}
            defaultValue={data.Detail}
            onSubmit={(data) => setData((prev) => ({ ...prev, Detail: data as FormStateDetail }))}
          />
        )}
        {step === "Inventory Inputs" && (
          <ActionPlanInventoryInputsForm
            defaultValue={data["Inventory Inputs"]}
            onAdd={async (_, data) => setData((prev) => ({ ...prev, "Inventory Inputs": data }))}
            onRemove={async (_, data) => setData((prev) => ({ ...prev, "Inventory Inputs": data }))}
            onUpdate={async (_, data) => setData((prev) => ({ ...prev, "Inventory Inputs": data }))}
          />
        )}
        {step === "Collaborators" && (
          <ActionPlanCollaboratorForm
            defaultValue={data.Collaborators}
            onAdd={async (_, data) => setData((prev) => ({ ...prev, Collaborators: data }))}
            onRemove={async (_, data) => setData((prev) => ({ ...prev, Collaborators: data }))}
            onUpdate={async (_, data) => setData((prev) => ({ ...prev, Collaborators: data }))}
          />
        )}
      </div>
    </div>
  )
}
