import { ExpandMore } from "@mui/icons-material"
import { Button } from "@mui/material"
import { FC } from "react"
import { useAppSelector } from "../../redux/store/hooks"
import { AvatarProps } from "./Avatar.interfaces"

export const Avatar: FC<AvatarProps> = (props) => {
  const { ...buttonprops } = props

  const user = useAppSelector((store) => store.auth.user)
  const role = useAppSelector((store) => store.auth.role)

  return (
    <Button
      {...buttonprops}
      className={`flex items-center ${buttonprops.className}`}
      endIcon={<ExpandMore className="-ml-2" />}
    >
      <div className="mr-2 flex flex-col items-end font-bold">
        <span className="text-sm text-secondary">{user?.name}</span>
        <span className="text-xs text-primary">{role?.type ?? ""}</span>
      </div>
      <img src="https://mdbootstrap.com/img/new/standard/city/041.webp" className="h-9 w-9 rounded-full" alt="avatar" />
    </Button>
  )
}
