import { gql } from "graphql-request"

export const createOrganization = gql`
  mutation CreateOrganization(
    $name: String!
    $industry: String!
    $domains: [String!]
    $businessName: String!
    $uid: Int!
  ) {
    createOrganization(name: $name, industry: $industry, businessName: $businessName, domains: $domains, uid: $uid) {
      id
    }
  }
`

export const MultiStats = gql`
  query MultiStats($actionPlanId: Int!, $emissionIds: [Int!]) {
    multiStats(actionPlanId: $actionPlanId, emissionIds: $emissionIds) {
      id
      data {
        emission
        date
      }
    }
  }
`

export const OrgByName = gql`
  query OrgByName($name: String!) {
    orgByName(name: $name) {
      id
      name
      industry
      domains
      businessName
      Template {
        id
      }
    }
  }
`

export const GetOrganizatoinPayment = gql`
  query OrgByName($name: String!) {
    orgByName(name: $name) {
      stripeCustomerId
      actionPlanSubscriptionId
    }
  }
`

export const OrganizationByUserid = gql`
  query OrganizationByUserid($id: Int!) {
    organizationByUserid(id: $id) {
      id
      name
      industry
      domains
      businessName
    }
  }
`

export const ValidateInvitation = gql`
  mutation ValidateInvitation($token: String) {
    validateInvitation(token: $token) {
      adId
      inviteId
    }
  }
`

export const InvitesByOrganization = gql`
  query InvitesByOrganization($organization: String) {
    invitesByOrganization(organization: $organization) {
      id
      name
      phone
      email
      allowedInvites
      isValid
    }
  }
`

export const UserOrganizations = gql`
  query UserOrganizations($email: String) {
    userOrganizations(email: $email) {
      name
    }
  }
`
