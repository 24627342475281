import React, { FC } from "react"
import { ActionPanelProps } from "./ActionPanel.interfaces"

export const ActionPanel: FC<ActionPanelProps> = (props) => {
  const { leftElement, rightElement, className } = props

  return (
    <div className={`mb-4 flex min-h-[31.25px] justify-between ${className}`}>
      <div className="flex">{leftElement}</div>
      <div className="flex">{rightElement}</div>
    </div>
  )
}
