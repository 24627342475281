import { gql } from "graphql-request"

export const InventoryInputByActionplanId = gql`
  query InventoryInputByActionplanId($actionplanId: Int!) {
    inventoryInputByActionplanId(id: $actionplanId) {
      id
      name
      unit
      coefficient
      Emission {
        id
        source
        unit
      }
    }
  }
`

export const CreateInventoryInput = gql`
  mutation CreateInventoryInput(
    $name: String
    $unit: String
    $actionPlanId: Int!
    $emissionId: Int
    $coefficient: Float
  ) {
    createInventoryInput(
      name: $name
      unit: $unit
      actionPlanId: $actionPlanId
      emissionId: $emissionId
      coefficient: $coefficient
    ) {
      id
    }
  }
`

export const CreateManyInventoryInput = gql`
  mutation CreateManyInventoryInput($data: [ManyInventoryInputCreate!]!) {
    createManyInventoryInput(data: $data) {
      id
    }
  }
`

export const DeleteInventoryInputById = gql`
  mutation DeleteInventoryInputById($id: Int!) {
    deleteInventoryInputById(id: $id) {
      id
    }
  }
`
export const UpdateInventoryInputById = gql`
  mutation UpdateInventoryInputById(
    $id: Int!
    $name: String
    $emissionId: Int
    $unit: String
    $coefficient: Float
    $actionPlanId: Int
  ) {
    updateInventoryInputById(
      id: $id
      name: $name
      unit: $unit
      emissionId: $emissionId
      coefficient: $coefficient
      actionPlanId: $actionPlanId
    ) {
      id
    }
  }
`
