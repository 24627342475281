import { Answer } from "../Answer"
import { Organization } from "../Organization"

export interface Audit {
  id: number
  name: string
  type?: string
  year: number
  score: number
  Answers: Answer[]
  Organization: Organization
  status: keyof typeof AuditStatus

  organizationName?: string
}

export enum AuditStatus {
  completed,
  inprogress,
  notStarted,
}
