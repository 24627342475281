import { api } from "../../services/rtk"
import { PermissionEnum } from "../../types/PermissionEnum"
import { getOrganizationNameFromRoute } from "../../utils/getOrganizationNameFromRoute"
import { ResourceEnum } from "../Permission"
import { AuditAnswers, AuditById, AuditByOrganization, BulkInvite, CreateAudit, UpdateAudit } from "./Audit.documents"
import { Audit } from "./Audit.interfaces"

export const auditApi = api.injectEndpoints({
  endpoints: (builder) => ({
    auditAnswers: builder.query<{ Answers: Audit["Answers"] }, { id: number }>({
      query: (variables) => ({ document: AuditAnswers, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Answer, operation: PermissionEnum.Read } },
      providesTags: ["Answer"],
    }),
    audit: builder.query<Omit<Audit, "Answers">, { id: number }>({
      query: (variables) => ({ document: AuditById, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Audit, operation: PermissionEnum.Read } },
      providesTags: ["Audit"],
    }),
    listAudit: builder.query<Audit[], void>({
      query: () => ({ document: AuditByOrganization, variables: { name: getOrganizationNameFromRoute()! } }),
      extraOptions: { headers: { resource: ResourceEnum.Audit, operation: PermissionEnum.Read } },
      providesTags: ["Audit"],
    }),
    createAudit: builder.mutation<Audit, { name: string; year: number; templateId: number }>({
      query: (variables) => ({
        document: CreateAudit,
        variables: { ...variables, organizationName: getOrganizationNameFromRoute()! },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Audit, operation: PermissionEnum.Create } },
      invalidatesTags: ["Audit"],
    }),
    updateAudit: builder.mutation<any, { id: number; status: string }>({
      query: (variables) => ({ document: UpdateAudit, variables }),
      extraOptions: { headers: { resource: ResourceEnum.Audit, operation: PermissionEnum.Update } },
      invalidatesTags: ["Audit"],
    }),
    bulkInvite: builder.mutation<any, { email: string; name: string; sponsorName?: string; templateId: number }[]>({
      query: (variables) => ({
        document: BulkInvite,
        variables: {
          data: [...variables.map((item) => ({ ...item, sponsorName: getOrganizationNameFromRoute()! }))],
        },
      }),
      extraOptions: { headers: { resource: ResourceEnum.Audit, operation: PermissionEnum.Update } },
      invalidatesTags: ["Audit"],
    }),
  }),
})

export const {
  useAuditAnswersQuery,
  useLazyAuditAnswersQuery,
  useAuditQuery,
  useLazyAuditQuery,
  useListAuditQuery,
  useLazyListAuditQuery,
  useBulkInviteMutation,
  useCreateAuditMutation,
  useUpdateAuditMutation,
} = auditApi
