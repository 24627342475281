import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button } from "@mui/material"
import { groupBy } from "lodash"
import { FC, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FormAnswer } from "../../components/Question/Question.interface"
import { QuestionnaireProgress } from "../../components/QuestionnaireProgress"
import { QuestionnaireSections } from "../../components/QuestionnaireSections"
import { FormSection } from "../../components/QuestionnaireSections/QuestionnaireSections.interface"
import { SectionQuestions } from "../../components/SectionQuestions"
import { Loader } from "../../containers/Loader"
import { Message } from "../../containers/Message"
import { generateRoute, RoutePram, URL_QUESTIONNAIRE } from "../../router/routes"
import { Answer, useCreateAnswerMutation } from "../../services/Answer"
import { useAuditAnswersQuery, useAuditQuery, useUpdateAuditMutation } from "../../services/Audit"
import { fileService } from "../../services/axiosBaseQuery"
import { useOrganizationQuery } from "../../services/Organization"
import { Question } from "../../services/Question"
import { useTemplateQuery } from "../../services/Template"
import { FormQuestion, QuestionnaireDetailProps } from "./QuestionnaireDetail.interfaces"

const transverse = (value: FormSection[]): FormSection[] => {
  return value.reduce((acc, item) => [...acc, item, ...transverse(item.SubSection ?? [])], [] as FormSection[])
}

export const QuestionnaireDetail: FC<QuestionnaireDetailProps> = () => {
  const navigator = useNavigate()
  const { id } = useParams<RoutePram>()

  const audit = useAuditQuery({ id: Number(id) }, { skip: !id })
  const auditAnswers = useAuditAnswersQuery({ id: Number(id) }, { skip: !id })

  const orgByName = useOrganizationQuery()

  const templateQuery = useTemplateQuery(
    { id: orgByName.data?.Template?.id! },
    { skip: orgByName.isLoading || !!orgByName.isError }
  )

  const [createAnswer] = useCreateAnswerMutation()
  const [updateAudit] = useUpdateAuditMutation()

  const [section, setSection] = useState<FormSection | undefined>(undefined)

  const answers = useMemo(() => {
    if (auditAnswers.data) {
      return groupBy(auditAnswers.data.Answers, (answer) => answer.Question.sectionId) as Record<
        FormSection["id"],
        Answer[]
      >
    }
  }, [auditAnswers.data])

  const sections: FormSection[] | undefined = useMemo(() => {
    let sectionIndex = 0

    if (templateQuery.data) {
      const sections = templateQuery.data?.Sections?.map((section) => {
        const index = sectionIndex++
        return {
          ...section,
          index,
          SubSection: section.SubSection.map((subsection) => {
            const index = sectionIndex++
            return { ...subsection, index }
          }),
        } as FormSection
      })
      setSection(sections![0])
      return sections
    }
  }, [templateQuery.data])

  const flatsections: FormSection[] | undefined = useMemo(() => {
    return sections ? transverse(sections) : undefined
  }, [sections])

  const activeSection = useMemo(
    () =>
      flatsections?.find((section) => section.id === auditAnswers.data?.Answers.at(-1)?.Question.sectionId) ?? section,
    [flatsections, auditAnswers.data?.Answers, section]
  )

  if (templateQuery.isLoading || orgByName.isLoading || audit.isLoading) return <Loader />
  if (!templateQuery.data || !sections?.length || !section || !flatsections || audit.isError)
    return <Message error={templateQuery.error} />

  const onSubmit = async () => {
    await updateAudit({ id: Number(id), status: "completed" }).unwrap()
    toast.success("Questionnaire completed successfully")
    navigator(generateRoute(URL_QUESTIONNAIRE))
  }

  const onAnswer = async (question: FormQuestion, answer: FormAnswer) => {
    SubmitAnswer(question, answer)
  }

  const onNext = async () => {
    if (section.index !== sections[sections.length - 1].index) {
      const nextsection = flatsections?.find((item) => item.index === section.index + 1)
      if (nextsection) {
        window.scrollTo({ top: 0, behavior: "smooth" })
        return setSection(nextsection)
      }
    }

    onSubmit()
  }

  const onPrev = async () => {
    if (section.index === sections[0].index) return

    const prevsection = flatsections?.find((item) => item.index === section.index - 1)
    if (prevsection) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      return setSection(prevsection)
    }
  }

  const SubmitAnswer = async ({ id: questionId }: Question, answer: FormAnswer) => {
    let files: string[] = []
    const newFiles = answer?.attachmentUrls?.filter((file) => file.size) as File[]

    if (!!newFiles?.length) {
      const formData = new FormData()
      newFiles.forEach((file) => formData.append(file.name, file))
      const resp = await fileService
        .post<any, string>("", formData)
        .then((resp: string) => JSON.parse(resp))
        .catch(() => {
          throw new Error("Error Uploading file")
        })
      files = resp.data.files
    }
    await createAnswer({
      attachmentUrls: files,
      auditId: Number(id),
      questionId: questionId,
      optionId: Number(answer?.optionId),
    }).unwrap()
  }

  return (
    <div className="max-w-screen-2xl flex-grow p-4">
      {!!(audit.data?.status === "completed") && (
        <div className="mb-5 mr-2 flex items-center justify-between">
          <Link to={generateRoute(URL_QUESTIONNAIRE)}>
            <Button>
              <ArrowBackIcon />
              <span className="ml-2 inline-block">Back</span>
            </Button>
          </Link>
        </div>
      )}
      <div className="-m-2 flex flex-wrap">
        <div className="min-w-0 space-y-4 p-2">
          <QuestionnaireSections
            key={section.id}
            status={audit.data?.status ?? "inprogress"}
            activeIndex={
              (activeSection?.index ?? 0) +
              ((activeSection &&
                ~~(activeSection?.Questions?.length === answers?.[activeSection?.id as number]?.length)) ??
                0)
            }
            selected={section}
            onClick={setSection}
            flatsections={flatsections}
            sections={sections}
          />

          {audit.data?.status === "inprogress" && (
            <QuestionnaireProgress flatSections={flatsections} activeSections={activeSection ?? section} />
          )}
        </div>
        <div className="flex-1 p-2">
          <div>
            {section && (
              <SectionQuestions
                key={section.id}
                value={answers?.[section.id]}
                onAnswer={onAnswer}
                section={section}
                isFirst={section.index === 0}
                isLast={section.index === sections[sections.length - 1].index}
                onNext={onNext}
                onPrev={onPrev}
                disabled={audit.data?.status !== "completed"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
