import { createTheme, ThemeOptions as MuiThemeOptions } from "@mui/material"
import merge from "deepmerge"
import { DefaultColors } from "tailwindcss/types/generated/colors"
const colors = require("tailwindcss/colors") as DefaultColors

export interface ThemeOptions extends MuiThemeOptions {
  components?: MuiThemeOptions["components"] & {
    MuiSelect?: {
      styleOverrides?: {
        root?: Record<string, any>
      }
    }
  }
}

export const themeColor = merge(colors, {
  primary: {
    main: "#fe802a",
    light: "#FEB380",
    dark: "#B24801",
    contrastText: "#fff",
    "50": "#FFF3EB",
    "100": "#FFE7D6",
    "200": "#FFCBA9",
    "300": "#FEB380",
    "400": "#FE9752",
    "500": "#FE802A",
    "600": "#EA5E01",
    "700": "#B24801",
    "800": "#752F01",
    "900": "#3D1900",
  },
  secondary: {
    main: "#121E35",
    light: "#3A62AB",
    dark: "#04070B",
    "50": "#C2D0EA",
    "100": "#A4B9E0",
    "200": "#6B8DCD",
    "300": "#3A62AB",
    "400": "#274172",
    "500": "#121E35",
    "600": "#0E182A",
    "700": "#0A111E",
    "800": "#080D17",
    "900": "#04070B",
  },
  muted: colors.neutral[500],
})

export const theme: ThemeOptions = createTheme({
  palette: themeColor,
  typography: { fontSize: 14, fontFamily: '"Mulish", "Roboto", "Open Sans", sans-serif' },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: { "& .MuiTypography-root": { fontWeight: 700 } },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "2px solid #e5e7eb",
        },
      },
    },

    // MuiInputBase: {
    //   styleOverrides: {
    //     inputSizeSmall: { paddingTop: ".25rem !important", paddingBottom: ".25rem !important" },
    //     input: { paddingTop: ".7rem !important", paddingBottom: ".7rem !important" },
    //     adornedStart: { paddingLeft: "1rem !important", "& svg": { marginRight: ".25rem" } },
    //     multiline: { paddingTop: "0 !important", paddingBottom: "0 !important" },
    //   },
    // },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { paddingTop: "0 !important", paddingBottom: "0 !important" },
      },
    },
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: {
          borderRadius: "999rem",
          minWidth: "unset",
          textTransform: "inherit",
          boxShadow: "none",
          ":hover": { boxShadow: "none" },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: { background: themeColor?.primary.main },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontWeight: "bold",
          fontSize: "1rem",
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& p": {
            marginBottom: "unset",
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          // boxShadow: "0 1px 3px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 5%)",
          // "&.card-hover:hover": { boxShadow: "0 1px 5px 0 rgb(0 0 0 / 21%)" },
          boxShadow: "none",
          borderRadius: ".5rem",
          border: "2px solid #e5e7eb",
          padding: ".75rem",
          "& .MuiDataGrid-root": {
            marginTop: "-.75rem",
            marginBottom: "-.75rem",
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: { fontSize: "inherit" },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-readOnly,& .MuiInputBase-readOnly": {
            paddingLeft: 0,
            pointerEvents: "none !important",

            "& ~ fieldset": {
              border: 0,
            },
            "& textarea": {
              resize: "none",
            },
            "& ~ svg[data-testid]": {
              display: "none",
            },
          },
        },
        multiline: {
          "& textarea": {
            resize: "vertical",
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: "193px",
          "& .MuiFilledInput-root": {
            backgroundColor: "#f3f6f9",
            border: "1px solid #e0e3e7",
            borderRadius: "0.625rem",

            "&.Mui-focused:after": { opacity: 1 },
            "& fieldset": { border: 0 },
            "&::before,&::after": { content: "none" },
          },
        },
      },
    },
  },
} as ThemeOptions)
